import { Accordion, AccordionDetails, AccordionSummary, Box, styled } from "@mui/material";
import { sizeDecreaseCalc } from "../../../../shared";
import { fadedAnimation, flexStyles } from "../styles";

export const Root = styled(Box)(({ theme }) => ({
  ...flexStyles,
  boxSizing: "border-box",
  width: "100%",
  marginTop: theme.spacing(22.5),
  marginBottom: theme.spacing(20),
  gap: theme.spacing(5),
  position: "relative",
  zIndex: 1,

  [theme.breakpoints.down("md")]: {
    paddingInline: theme.spacing(2),
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(11),
  },

  "&::before": {
    content: "''",
    height: "500px",
    width: "350vw",
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%) translateY(50%) translateZ(0)",
    background: "#0b1e42",
    filter: "blur(150px)",
    zIndex: -1,
  },

  "& h1": {
    ...fadedAnimation(""),
    color: "#FFFFFF",
    fontFamily: "Montserrat, sans-serif",
    fontSize: sizeDecreaseCalc(50, 36),
    fontWeight: 900,
    lineHeight: "120%",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  ...flexStyles,
  backgroundColor: "#1A1B23",
  maxWidth: "1040px",
  width: "80%",
  padding: theme.spacing(4),

  borderRadius: theme.spacing(3),

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: theme.spacing(0),
  transition: "all 0.3s linear",
  background: "transparent",
  color: "#FFFFFF",
  fontFamily: "Montserrat, sans-serif",
  fontSize: theme.spacing(2.5),
  fontWeight: 700,
  lineHeight: "150%",

  "&.MuiAccordion-root:not(:last-child)": {
    borderBottom: "1px solid #2B2C3B",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  "&.MuiAccordion-root:first-of-type": {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },

  "&.MuiAccordion-root:last-child": {
    paddingTop: theme.spacing(3),
  },

  "&.Mui-expanded": {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
  "&::before": {
    display: "none",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  fontFamily: "Inter, sans-serif",
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: "130%",
  color: "#898CA9",
  padding: 0,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },

  "& p": {
    margin: 0,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
  fontSize: theme.spacing(3),
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "-0.075px",
  padding: 0,
  zIndex: 10,

  "& .MuiAccordionSummary-content": {
    alignSelf: "center",
    margin: 0,
    [theme.breakpoints.down("sm")]: {},
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    alignSelf: "center",
    color: "#FFFFFF",
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        width: 24,
        height: 24,
      },
    },
  },
}));
