import { create } from "zustand";

import { IVestingOfferResponse } from "../../../../shared";

interface IUseOffersListStore {
  offer: IVestingOfferResponse | null;
  isDeleteDialogOpen: boolean;
  openDeleteOfferDialog: (offer: IVestingOfferResponse) => void;
  closeDeleteOfferDialog: () => void;
}

export const useOffersListStore = create<IUseOffersListStore>()(set => ({
  offer: null,
  isDeleteDialogOpen: false,
  openDeleteOfferDialog: (offer: IVestingOfferResponse) =>
    set({
      offer,
      isDeleteDialogOpen: true,
    }),
  closeDeleteOfferDialog: () =>
    set({
      offer: null,
      isDeleteDialogOpen: false,
    }),
}));
