import { Box, styled } from "@mui/material";

export const EntityInputIcon = styled("span")(({ theme }) => ({
  borderRadius: 8,
  border: `1.2px solid ${theme.palette.custom.black}`,
  width: 24,
  height: 24,
  backgroundColor: "transparent",

  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
    ...theme.applyStyles("dark", {
      background: "rgba(57,75,89,.5)",
    }),
  },
}));

export const EntityInputCheckedIcon = styled(EntityInputIcon)(({ theme }) => ({
  backgroundColor: "transparent",
  border: `1.2px solid ${theme.palette.custom.main.default}`,
  "&::before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage: 'url("/files/icons/check_mark.svg")',
    backgroundPosition: "center",
    content: '""',
  },
}));

export const StyledValuesBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(1),
  marginRight: theme.spacing(0.5),

  ".lengthBox": {
    width: 23,
    height: 23,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.custom.main.default,
    ...theme.typography.body_2,
    fontWeight: 600,
    color: theme.palette.custom.white,
  },
})) as typeof Box;
