import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid2, Grid2Props } from "@mui/material";

import { StyledCell, StyledRoot, StyledTableBodyRoot } from "./styled";

export { StyledCell };

interface IStyledGridTableProps extends Grid2Props {
  title?: string;
  hasBorder?: boolean;
  isInner?: boolean;
}

export const StyledGridTable: FC<IStyledGridTableProps> = props => {
  const { container: _container, size = 12, children, title, isInner = false, ...restProps } = props;

  return (
    <StyledRoot container isInner={isInner} size={size} {...restProps}>
      {title && (
        <Grid2 size={12} className="GridTable-Title">
          {title}
        </Grid2>
      )}
      <Grid2 size={12} className="GridTable-Content">
        {children}
      </Grid2>
    </StyledRoot>
  );
};

interface IStyledGridTableHeaderProps extends Grid2Props {
  data: Array<string>;
  hasLastColumnIcons?: boolean;
  isMd?: boolean;
}

export const StyledGridTableHeader: FC<IStyledGridTableHeaderProps> = props => {
  const { container: _container, size = 12, data, hasLastColumnIcons = false, isMd, ...restProps } = props;
  const { formatMessage } = useIntl();

  return (
    <Grid2 container size={size} spacing={2} wrap={!isMd ? "nowrap" : "wrap"} {...restProps}>
      {data.map((item, index, array) => {
        const isLastItem = index === array.length - 1;
        return (
          <Grid2
            sx={{ textAlign: index === 0 ? "left" : "right" }}
            size={hasLastColumnIcons && isLastItem ? 1 : 13 / array.length}
            key={item}
          >
            {item ? formatMessage({ id: item }) : item}
          </Grid2>
        );
      })}
    </Grid2>
  );
};

interface IStyledTableBodyProps extends Grid2Props {
  data: Array<string | number | React.JSX.Element>;
  headerData: Array<string>;
  isMd?: boolean;
  hasLastColumnIcons?: boolean;
  isInner?: boolean;
}

export const StyledTableRow: FC<IStyledTableBodyProps> = props => {
  const { container: _container, data, headerData, isMd, isInner, hasLastColumnIcons = false, ...restProps } = props;

  return (
    <StyledTableBodyRoot
      container
      size={12}
      spacing={2}
      isInner={isInner}
      isMoreThanOneItem={data.length > 1}
      wrap={!isMd ? "nowrap" : "wrap"}
      {...restProps}
    >
      {data.map((dataItem, index, array) => {
        const isLastItem = hasLastColumnIcons ? index === array.length - 1 : false;
        return !isMd ? (
          <StyledCell isFirst={index === 0} size={hasLastColumnIcons && isLastItem ? 1 : 13 / array.length} key={index}>
            {dataItem}
          </StyledCell>
        ) : (
          <StyledCell container size={12} key={index} spacing={2}>
            {!isLastItem && (
              <Grid2 size={6}>
                <FormattedMessage id={headerData[index]} tagName="span" />
              </Grid2>
            )}
            <Grid2 sx={{ textAlign: "right" }} size={isLastItem ? 12 : 6}>
              {dataItem}
            </Grid2>
          </StyledCell>
        );
      })}
    </StyledTableBodyRoot>
  );
};
