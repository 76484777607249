import { IAsset, IVestingBox } from "@framework/types";
import { SPLIT_BOX_PRICE_COMMISSION } from "@framework/constants/dist/commissions";

import { VestingBox } from "../../utils";
import { CalculationCurrencyWithValue, CalculationData } from "../../types";
import { CalculationSlides, VendorAllowanceSide } from "./types";
import { TOKEN_PERCENTAGE_FIX_VALUE } from "../../constants";

const calculateBoxesPrice = (
  originalBoxTokenPrice: number,
  sideBoxTokensCount: number,
  vendorAllowanceValue: number,
) => {
  const sideBaseBoxPrice = originalBoxTokenPrice * sideBoxTokensCount;
  const withBenefitsBoxPrice = sideBaseBoxPrice * (1 + vendorAllowanceValue / 100);
  const tokenPrice = withBenefitsBoxPrice / sideBoxTokensCount;

  return {
    boxPrice: withBenefitsBoxPrice,
    tokenPrice,
  };
};

export const splitCalculation = (
  calculationSlides: Required<CalculationSlides>,
  vendorAllowanceValue: Record<VendorAllowanceSide, number>,
  box: IVestingBox,
  price: IAsset,
): {
  leftBoxCalculation: CalculationData;
  rightBoxCalculation: CalculationData;
  isNothingForSplit?: boolean;
} => {
  const vesting = new VestingBox(box, price);

  /** Boxes tokens count calculation **/
  const contentTicker = vesting.getContentTicker();
  const priceTicker = vesting.getPriceTicker() as "USDT" | "USDC";
  const contentAmount = vesting.getContentAmount();
  const priceAmount = vesting.getPriceAmount();
  const unsplitPercentage = calculationSlides.withdrawnPercentage;

  const totalForSplitCount =
    unsplitPercentage === 0
      ? contentAmount
      : unsplitPercentage > 100
        ? 0
        : contentAmount * ((100 - unsplitPercentage) / 100);

  if (totalForSplitCount === 0) {
    return {
      isNothingForSplit: true,
      leftBoxCalculation: {
        tokensCount: `${contentTicker} ${0}`,
        tokenPrice: `${priceTicker} ${0}`,
        boxPrice: `${priceTicker} ${0}`,
        platformFee: `${0}`,
        benefit: "-",
      },
      rightBoxCalculation: {
        tokensCount: `${contentTicker} ${0}`,
        tokenPrice: `${priceTicker} ${0}`,
        boxPrice: `${priceTicker} ${0}`,
        platformFee: `${0}`,
        benefit: "-",
      },
    };
  }

  const leftBoxTokensCount = (totalForSplitCount * calculationSlides.splitPercentage) / 100;
  const rightBoxTokensCount = totalForSplitCount - leftBoxTokensCount;

  /** Boxes prices calculation **/
  const originalBoxTokenPrice = vesting.getContentTokenPrice();
  const originalBoxPrice = priceAmount;
  const commissionValue = SPLIT_BOX_PRICE_COMMISSION / 100;

  const { boxPrice: leftBoxPrice, tokenPrice: leftTokenPrice } = calculateBoxesPrice(
    originalBoxTokenPrice,
    leftBoxTokensCount,
    vendorAllowanceValue.left,
  );
  const { boxPrice: rightBoxPrice, tokenPrice: rightTokenPrice } = calculateBoxesPrice(
    originalBoxTokenPrice,
    rightBoxTokensCount,
    vendorAllowanceValue.right,
  );

  /** Boxes benefit **/
  const leftBoxBenefit = leftBoxPrice - originalBoxPrice - leftBoxPrice * commissionValue;

  const rightBoxBenefit = rightBoxPrice - originalBoxPrice - rightBoxPrice * commissionValue;

  /** Result box price connected with benefit **/
  const leftPlatformFee: number = leftBoxPrice - leftBoxPrice * (1 - commissionValue);
  let resultLeftBoxBenefit: CalculationCurrencyWithValue = `${priceTicker} ${leftBoxBenefit.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`;

  const rightPlatformFee: number = rightBoxPrice - rightBoxPrice * (1 - commissionValue);
  let resultRightBoxBenefit: CalculationCurrencyWithValue = `${priceTicker} ${rightBoxBenefit.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`;

  if (leftBoxBenefit < 0) {
    resultLeftBoxBenefit = "-";
  }

  if (rightBoxBenefit < 0) {
    resultRightBoxBenefit = "-";
  }

  return {
    leftBoxCalculation: {
      tokensCount: `${contentTicker} ${leftBoxTokensCount.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`,
      boxPrice: `${priceTicker} ${leftBoxPrice.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`,
      tokenPrice: `${priceTicker} ${leftTokenPrice.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`,
      platformFee: `${leftPlatformFee.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`,
      benefit: resultLeftBoxBenefit,
    },
    rightBoxCalculation: {
      tokensCount: `${contentTicker} ${rightBoxTokensCount.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`,
      boxPrice: `${priceTicker} ${rightBoxPrice.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`,
      tokenPrice: `${priceTicker} ${rightTokenPrice.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`,
      platformFee: `${rightPlatformFee.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}`,
      benefit: resultRightBoxBenefit,
    },
  };
};
