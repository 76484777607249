import React, { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Area, Bar, Brush, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "@mui/material";

import { IAsset, IVestingBox } from "@framework/types";

import { formatXAxis, VestingBox } from "../../utils";
import { useRenderPlot } from "../../hooks";
import { Root, StyledPlotBox } from "./styled";
import { Direction } from "./types";
import { monthlyPlots } from "./constants";

interface IPlotProps {
  vestingBox: IVestingBox;
  price: IAsset;
  startTimestamp: Date;
  height?: number;
  startDirection?: Direction;
  infoPosition?: "top" | "bottom";
}

// /**
//  * This code removes error about XAxis default props. We can't handle this error and can only remove from console
//  * at this moment
//  * See: https://github.com/recharts/recharts/issues/3615
//  * **/
const error = console.error;
console.error = (...args: any) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

export const Plot: FC<IPlotProps> = props => {
  const { vestingBox, price, startTimestamp, height = 300, startDirection = "row", infoPosition = "top" } = props;
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const vesting = useMemo(() => {
    return new VestingBox(vestingBox, price);
  }, [vestingBox]);

  const tokensCount = vesting.getContentAmount();

  const { data } = useRenderPlot({
    shape: vestingBox?.shape,
    cliff: vestingBox?.cliff,
    duration: vestingBox?.duration,
    period: vestingBox?.period,
    afterCliffBasisPoints: vestingBox?.afterCliffBasisPoints,
    growthRate: vestingBox?.growthRate,
    startTimestamp,
  });

  const isMonthlyPlot = monthlyPlots.includes(vestingBox.shape);

  return (
    <Root direction={startDirection}>
      {infoPosition === "top" && (
        <React.Fragment>
          <FormattedMessage id={`pages.vesting.currentBox.aboutVesting.${vestingBox.shape}`} tagName="p" />
          <FormattedMessage id="pages.vesting.currentBox.aboutVestingDisclaimer" tagName="p" />
        </React.Fragment>
      )}
      <StyledPlotBox direction={startDirection}>
        <ResponsiveContainer width={"100%"} minHeight={height}>
          <ComposedChart data={data} barGap={0} barCategoryGap={0}>
            <XAxis
              axisLine={false}
              dataKey="x"
              label={{ position: "insideBottom", offset: 0 }}
              tickFormatter={formatXAxis}
              tickLine={false}
            />
            <YAxis axisLine={false} domain={[0, 100]} label={{ value: "%", position: "insideLeft" }} tickLine={false} />
            <Tooltip
              formatter={(value, _, item) => {
                const customValue = item.payload.x
                  ? `${formatMessage({ id: "pages.vesting.currentBox.plotYValueName" })}: ${(tokensCount * Number(value)) / 100}`
                  : `${formatMessage({ id: "pages.vesting.currentBox.plotCliffInfo" })}`;
                return [customValue];
              }}
            />
            {!isMonthlyPlot && (
              <>
                <defs>
                  <linearGradient id="lineGradient" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stopColor="#4B01E0" />
                    <stop offset="100%" stopColor="#8E2DE2" />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="y"
                  fill={"url(#lineGradient)"}
                  stroke={"none"}
                  name={formatMessage({ id: "pages.vesting.currentBox.plotYValueName" })}
                  dot={false}
                />
              </>
            )}
            {isMonthlyPlot && (
              <Bar dataKey="y" name={formatMessage({ id: "pages.vesting.currentBox.plotYValueName" })} />
            )}
            <Brush
              fill={theme.palette.custom.main.disabled}
              height={20}
              travellerWidth={20}
              tickFormatter={_value => ""}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </StyledPlotBox>
      {infoPosition === "bottom" && (
        <React.Fragment>
          <FormattedMessage id={`pages.vesting.currentBox.aboutVesting.${vestingBox.shape}`} tagName="p" />
          <FormattedMessage id="pages.vesting.currentBox.aboutVestingDisclaimer" tagName="p" />
        </React.Fragment>
      )}
    </Root>
  );
};
