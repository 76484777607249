import { Box, styled } from "@mui/material";

import { setButtonStyles } from "../../../../../../shared";

const flexStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  ...flexStyles,
  marginTop: theme.spacing(4),

  "& #TextField-Box": {
    "& .MuiFormHelperText-root": {
      ...theme.typography.caption_1,
    },

    "& .MuiBadge-badge": {
      padding: 0,
      right: -3,
    },

    "& .MuiFormControl-root": {
      margin: 0,
    },
  },

  "& form": {
    ...flexStyles,
    flexDirection: "column",

    "& .MuiGrid-container": {
      width: "70%",

      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },

  ".MuiGrid-container": {
    justifyContent: "center",

    "& .MuiButtonBase-root": {
      width: "40%",
      alignSelf: "center",
      ...setButtonStyles(theme),
    },
  },
})) as typeof Box;

export const StyledFieldBox = styled(Box)(({ theme }) => ({
  width: "70%",
  ...flexStyles,
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1.5),

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },

  "& #CloudUploadIcon-Box": {
    height: "100%",
    borderRadius: theme.spacing(1.25),
    border: `1px solid ${theme.palette.custom.card_1.stroke}`,
  },

  "& .MuiBadge-badge": {
    padding: 0,
    right: -5,
  },

  ".MuiInputBase-root": {
    borderRadius: theme.spacing(1.25),
    border: `1px solid ${theme.palette.custom.card_1.stroke}`,

    ".MuiOutlinedInput-input": {
      color: theme.palette.custom.white,
      ...theme.typography.body_1,

      "&:focused": {
        outline: "none",
      },
    },
  },

  "& h5": {
    display: "inline",
    margin: 0,
    "& span": {
      color: theme.palette.custom.white,
      ...theme.typography.subtitle_2,
      position: "relative",
      "& svg": {
        position: "absolute",
      },
    },
  },

  "& p, .MuiTypography-body1": {
    margin: 0,
    color: theme.palette.custom.gray,
    ...theme.typography.caption_1,

    "& span": {
      position: "relative",
      "& svg": {
        position: "absolute",
      },
    },
  },

  "& .MuiBox-root": {
    width: "100%",
  },

  "& .MuiAutocomplete-root": {
    width: "100%",
  },
}));
