import React, { FC, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { useUser } from "@dzambalaorg/provider-user";
import { IVestingBox } from "@framework/types";

import { getBackgroundImg, Vesting } from "../../utils";
import { INFINITY_DISPLAYING, PATHS } from "../../constants";
import { StyledCardMedia } from "../styled";
import { Root, StyledCardActionArea, StyledCardActions } from "./styled";
import { VestingBoxCategoryBadge } from "../../../components/common/badge";
import { useVestingContractInfo } from "../../hooks";
import { VestingBoxesTabs } from "../../types";
import { BoxFooterInfo, BoxInfo } from "./box-info";

interface IBoxCard {
  vestingBox: IVestingBox;
  tab: VestingBoxesTabs;
  offerId?: number;
  tokenId?: string;
  ownerAddress?: string;
}

export const BoxCard: FC<IBoxCard> = props => {
  const { vestingBox, tab, tokenId, offerId, ownerAddress } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const user = useUser<any>();

  const { releasable, withdrawn } = useVestingContractInfo(tokenId || "", vestingBox.template!.contract!.address);

  const vesting = useMemo(() => {
    return new Vesting(vestingBox, vestingBox.template!.price!, releasable, withdrawn);
  }, [releasable, withdrawn]);

  const duration = vesting.getBoxInfo().duration;
  const tokensAmount = vesting.getContentAmount();
  const boxPrice = vesting.getBoxPriceWithTicker();
  const tokenImg = vestingBox ? vestingBox.content!.components[0].template!.imageUrl : "";
  const releasablePercentageForDisplaying = vesting.getReleasablePercentageDisplaying();

  const immediateUnlock =
    !tokenId || !offerId ? vestingBox.afterCliffBasisPoints / 100 : releasablePercentageForDisplaying;

  const tvl = !offerId ? tokensAmount : vesting.getTvl();

  const backgroundImg = vestingBox.backgroundImg || getBackgroundImg(vestingBox.vestingBoxCat);

  const limitDisplaying =
    Number(vestingBox.template!.cap) > 0
      ? `${Number(vestingBox.template!.cap) - Number(vestingBox.template!.amount)}/${vestingBox.template!.cap}`
      : INFINITY_DISPLAYING;

  const offerOwner = ownerAddress && ownerAddress === user.profile?.wallet ? " \u{1F464}" : "";

  return (
    <Root
      component={Link}
      to={`${PATHS.VestingBoxesBase}/${tab}/${offerId || vestingBox.id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledCardActionArea
        sx={{
          transform: isHovered ? "scale(1.04)" : "scale(1.0)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <VestingBoxCategoryBadge category={vestingBox.vestingBoxCat} />
        <StyledCardMedia className="BoxCard-media" component="img" image={backgroundImg} />
      </StyledCardActionArea>
      <StyledCardActions>
        <Typography variant="subtitle_2" component="h5">
          {`${vestingBox.title}${offerOwner}`}
        </Typography>
        <BoxInfo
          duration={duration}
          boxPrice={boxPrice}
          limit={limitDisplaying}
          purchased={vestingBox.template!.amount}
        />
        <BoxFooterInfo tvl={tvl} releasable={immediateUnlock} tokenImg={tokenImg} />
      </StyledCardActions>
    </Root>
  );
};
