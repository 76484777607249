import React, { FC, MouseEvent, useEffect } from "react";
import { useLocation } from "react-router";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDisconnect } from "wagmi";

import { ConnectButton } from "@dzambalaorg/provider-wagmi";
import { useUser } from "@dzambalaorg/provider-user";
import { IUser } from "@framework/types";

import { MenuVariant, PATHS, VestingBoxesTabs } from "../../../shared";
import { Root } from "./styled";
import { useMenuItems } from "./use-menu-items";
import { MenuItem } from "./menu-item";
import { IMenuVariant } from "./types";
import { StyledMenuItem } from "../inner-header/sections/styled";

export { IMenuVariant };

interface IMenu extends IMenuVariant {
  onCloseMenu?: () => void;
}

export const Menu: FC<IMenu> = props => {
  const { variant, onCloseMenu } = props;
  const menuLinks = useMenuItems();
  const user = useUser<IUser>();
  const location = useLocation();
  const { disconnectAsync } = useDisconnect();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const isUserAuthenticated = user.isAuthenticated();

  const logout = async (e: MouseEvent): Promise<void> => {
    e.preventDefault();
    if (onCloseMenu) {
      onCloseMenu();
    }
    await user.logOut(`${PATHS.VestingBoxesBase}/${VestingBoxesTabs.boxes}`);
    await disconnectAsync();
  };

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          const offsetPosition = element.getBoundingClientRect().top + window.scrollY - 50;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }, 30);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <Root>
      {menuLinks.desktop.map(item => {
        return <MenuItem key={item.title} variant={variant} menuItem={item} onCloseMenu={onCloseMenu} />;
      })}
      {isUserAuthenticated &&
        isMd &&
        menuLinks.mobile.map(item => {
          return <MenuItem key={item.title} variant={variant} menuItem={item} onCloseMenu={onCloseMenu} />;
        })}
      {variant === MenuVariant.INNER && (
        <React.Fragment>
          <ConnectButton />
        </React.Fragment>
      )}
      {isUserAuthenticated && isMd && (
        <StyledMenuItem sx={{ py: 0, px: 0, mt: 10 }} onClick={logout}>
          Logout
        </StyledMenuItem>
      )}
    </Root>
  );
};
