import { FC } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { MenuVariant } from "../../../shared";
import { Menu } from "../menu";
import { MobileMenu } from "../menu/mobile-menu";
import { Sections } from "./sections";
import { StyledAppBar, StyledGrow, StyledLink, StyledToolbar } from "./styled";

interface IInnerHeaderProps {
  isInMainPage: boolean;
}

export const InnerHeader: FC<IInnerHeaderProps> = props => {
  const { isInMainPage } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledAppBar position={isInMainPage ? "fixed" : "static"}>
      <StyledToolbar>
        <StyledLink component={RouterLink} to="/">
          <img src="/files/new/lamb_new_logo.svg" alt="logo" width={128} height={46} />
        </StyledLink>
        <StyledGrow />
        {!isMd && <Menu variant={isInMainPage ? MenuVariant.LANDING : MenuVariant.INNER} />}
        {!isInMainPage && !isMd && <Sections />}
        {isMd && <MobileMenu variant={isInMainPage ? MenuVariant.LANDING : MenuVariant.INNER} />}
      </StyledToolbar>
    </StyledAppBar>
  );
};
