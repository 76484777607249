import React, { FC } from "react";
import { Grid2 } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { IOffersOverviewDto } from "@framework/types";
import { useUser } from "@dzambalaorg/provider-user";
import { useApi } from "@dzambalaorg/provider-api";

import { AssetsDashboardTabs, OffersSkeleton } from "../../../../shared";
import { OffersList } from "./offers-list";
import { OffersOverview } from "./offers-overview";

interface IOffersAssetsProps {
  variant: AssetsDashboardTabs;
}

export const OffersAssets: FC<IOffersAssetsProps> = props => {
  const { variant } = props;
  const user = useUser<any>();
  const api = useApi();

  const {
    data: overview,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["vesting/offers/offersOverview"],
    queryFn: (): Promise<IOffersOverviewDto> => {
      return api.fetchJson({
        url: `/vesting/offers/offersOverview`,
        data: {
          account: user.profile.wallet,
        },
      });
    },
  });

  if (isLoading) {
    return (
      <Grid2 display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" mt={4.75}>
        <OffersSkeleton />
      </Grid2>
    );
  }

  return (
    <Grid2>
      {overview && <OffersOverview {...overview} />}
      <OffersList variant={variant} overviewRefetch={refetch} />
    </Grid2>
  );
};
