import { ContentMode } from "../../types";
import { CalculationSlides } from "./types";

export const splitCalculationLayout: Record<ContentMode, Record<string, any>> = {
  [ContentMode.BOX]: {
    direction: "column",
    slidesSize: { xl: 12, lg: 12, md: 12, sm: 12, xs: 12 },
    calculationSize: { xl: 12, lg: 12, md: 12, sm: 12, xs: 12 },
  },
  [ContentMode.ASSET]: {
    direction: "row",
    slidesSize: { xl: 6, lg: 6, md: 12, sm: 12, xs: 12 },
    calculationSize: { xl: 6, lg: 6, md: 12, sm: 12, xs: 12 },
  },
};

export const calculationSlidesInitialValue: Record<ContentMode, CalculationSlides> = {
  [ContentMode.BOX]: {
    withdrawnPercentage: 1,
    splitPercentage: 50,
  },
  [ContentMode.ASSET]: {
    splitPercentage: 50,
  },
};
