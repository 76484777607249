import type { RouteObject } from "react-router-dom";

import { PATHS } from "../../shared";
import { Main } from "./main";

export const infrastructureRoutes: Array<RouteObject> = [
  {
    path: PATHS.Main,
    index: true,
    element: <Main />,
  },
];
