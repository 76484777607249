import React from "react";
import { Box, Grid2, Skeleton } from "@mui/material";

export const AssetsSkeleton = () => {
  return (
    <Box sx={{ p: 2, backgroundColor: "custom.card_2.background", color: "#fff", borderRadius: 2, width: "100%" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Skeleton height={24} width={180} sx={{ backgroundColor: "custom.gray" }} />
        <Skeleton width="20px" sx={{ backgroundColor: "custom.gray" }} />
      </Box>

      <Grid2 mt={3}>
        <Skeleton width={180} sx={{ backgroundColor: "custom.gray" }} />
        <Skeleton variant="rectangular" height={8} sx={{ backgroundColor: "custom.gray", borderRadius: 1 }} />
      </Grid2>

      <Grid2 mt={3} container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <Box display="flex" alignItems="center">
            <Skeleton variant="circular" width={24} height={24} sx={{ backgroundColor: "custom.gray", mr: 1 }} />
            <Skeleton width="80px" sx={{ backgroundColor: "custom.gray" }} />
          </Box>
          <Skeleton width="200px" sx={{ backgroundColor: "custom.gray" }} />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 6 }}>
          <Box display="flex" alignItems="center">
            <Skeleton variant="circular" width={24} height={24} sx={{ backgroundColor: "custom.gray", mr: 1 }} />
            <Skeleton width="80px" sx={{ backgroundColor: "custom.gray" }} />
          </Box>
          <Skeleton width="200px" sx={{ backgroundColor: "custom.gray" }} />
        </Grid2>
      </Grid2>

      <Grid2 mt={3} mb={1} size={12}>
        <Skeleton width="100px" sx={{ backgroundColor: "custom.gray" }} />
      </Grid2>

      <Grid2 container spacing={2}>
        {[...Array(8)].map((_, index) => (
          <Grid2 size={{ xs: 6, sm: 4, md: 3 }} key={index}>
            <Box
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: `1px solid #2B2C3B`,
                borderRadius: "30px",
              }}
            >
              <Skeleton width="100px" sx={{ backgroundColor: "custom.gray" }} />
              <Box display="flex" justifyContent="space-between" gap={3}>
                <Box display="flex" alignItems="center">
                  <Skeleton
                    variant="circular"
                    width={16}
                    height={16}
                    sx={{ backgroundColor: "custom.gray", mr: 0.5 }}
                  />
                  <Skeleton width="40px" sx={{ backgroundColor: "custom.gray" }} />
                </Box>
                <Box display="flex" alignItems="center">
                  <Skeleton
                    variant="circular"
                    width={16}
                    height={16}
                    sx={{ backgroundColor: "custom.gray", mr: 0.5 }}
                  />
                  <Skeleton width="40px" sx={{ backgroundColor: "custom.gray" }} />
                </Box>
              </Box>
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};
