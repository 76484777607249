import { Grid2, styled } from "@mui/material";

import { contentSideFlex } from "../styled";

export const StyledTextFieldBox = styled(Grid2)(({ theme }) => ({
  width: "100%",
  ...contentSideFlex,
  gap: theme.spacing(1),

  "& .MuiTypography-body_1": {
    ...theme.typography.body_1,
    color: theme.palette.custom.white,
  },
}));
