import { Grid2 } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { renderError } from "../../utils";
import { SimpleButton } from "../../../components/buttons/simple";

export const ErrorComponent = () => {
  const { formatMessage } = useIntl();
  const RenderError = renderError(
    { name: "error", message: formatMessage({ id: "errors.tryAgain" }) },
    { w: 280, h: 280 },
  );
  return (
    <Grid2 display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
      <RenderError />
      <SimpleButton component={Link} to={"/vesting/boxes"}>
        {formatMessage({ id: "form.buttons.backToHome" })}
      </SimpleButton>
    </Grid2>
  );
};
