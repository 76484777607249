import React, { FC } from "react";
import { Grid2 } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import LambLogo from "../../../../static/files/new/simple_logo.svg";
import Logo from "../../../../static/files/logo_gemunion.svg";
import WaterfallLogo from "../../../../static/files/watefall.svg";

import { StyledCopyrightWrapper, StyledLink, StyledLogo } from "./styled";

const fullYear = new Date().getFullYear();

interface ILogosProps {
  isMd: boolean;
}

export const Logos: FC<ILogosProps> = props => {
  const { isMd } = props;
  return (
    <Grid2 sx={{ display: "flex", flexDirection: isMd ? "column" : "row", alignItems: "center" }} size={12} gap={1}>
      <Grid2 id={"footer_grid_item_logo"}>
        <StyledLogo sx={{ width: 48, height: 40 }} component={LambLogo} viewBox="0 0 48 40" />
        <StyledCopyrightWrapper>©{fullYear} Lamb OTC Market. All Rights Reserved</StyledCopyrightWrapper>
      </Grid2>

      <Grid2 id={"footer_grid_item_logo_gemunion"}>
        <StyledLink component={RouterLink} target="_blank" to="https://gemunion.io">
          <StyledLogo component={Logo} viewBox="0 0 449.91 95.5" />
        </StyledLink>
        <StyledCopyrightWrapper>Powered by GemUnion - Universal Web3 Constructor Framework</StyledCopyrightWrapper>
      </Grid2>

      <Grid2 id={"footer_grid_item_logo_gemunion"}>
        <StyledLink component={RouterLink} target="_blank" to="https://waterfall.network/">
          <StyledLogo sx={{ width: 200, height: 75 }} component={WaterfallLogo} viewBox="0 0 1080 1080" />
        </StyledLink>
        <StyledCopyrightWrapper>Combining Scalability and Decentralization</StyledCopyrightWrapper>
      </Grid2>
    </Grid2>
  );
};
