export const aboutBlockData = [
  {
    title: "pages.main.about.experienceTitle",
    subtitle: "pages.main.about.experienceSubtitle",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/rocket.png"} alt={"#"} width={60} height={60} />,
  },
  {
    title: "pages.main.about.liquidityTitle",
    subtitle: "pages.main.about.liquiditySubtitle",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/liquidity.png"} alt={"#"} width={60} height={60} />,
  },
  {
    title: "pages.main.about.profitScheduleTitle",
    subtitle: "pages.main.about.profitScheduleSubtitle",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/scheduling.png"} alt={"#"} width={60} height={60} />,
  },
  {
    title: "pages.main.about.pricesTitle",
    subtitle: "pages.main.about.pricesSubtitle",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/pricing.png"} alt={"#"} width={60} height={60} />,
  },
  {
    title: "pages.main.about.protocolsTitle",
    subtitle: "pages.main.about.protocolsSubtitle",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/protocols.png"} alt={"#"} width={60} height={60} />,
  },
  {
    title: "pages.main.about.accessTitle",
    subtitle: "pages.main.about.accessSubtitle",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/access.png"} alt={"#"} width={60} height={60} />,
  },
  {
    title: "pages.main.about.walletConnection",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/wallet.png"} alt={"#"} width={60} height={60} />,
  },
  {
    title: "pages.main.about.analytics",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/analytics.png"} alt={"#"} width={60} height={60} />,
  },
];
