import { Box, styled, SxProps, Theme, Typography } from "@mui/material";

import { setButtonStyles } from "../../../../../shared";

export const pageHeaderStyles = (theme: Theme): SxProps<Theme> => ({
  "& .MuiGrid-item": { margin: 0 },

  "& .MuiTypography-root": {
    ...theme.typography.title_3,
    color: theme.palette.custom.white,
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiTypography-root": {
      ...theme.typography.subtitle_2,
    },
  },
});

export const Root = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(3),
}));

export const StyledImage = styled(Box)(({ theme }) => ({
  display: "block",
  width: "100%",
  height: 300,
  border: "none",
  objectFit: "cover",
  borderRadius: theme.spacing(2.5),
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down("md")]: {
    height: 200,
  },
})) as typeof Box;

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(5),

    "& .MuiGrid-container": {
      width: "fit-content",
      margin: 0,
    },
  },

  "& .MuiGrid-container": {
    width: "fit-content",
  },

  "& .MuiButtonBase-root": {
    ...setButtonStyles(theme),
  },
})) as typeof Box;

export const StyledDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(7.5),

  "& h4": {
    width: "100%",
    margin: 0,
    borderBottom: `1px solid ${theme.palette.custom.stroke}`,
    paddingBottom: theme.spacing(1),
    color: theme.palette.custom.white,
    ...theme.typography.button_1,
  },

  "& .DraftEditor-root": {
    ...theme.typography.body_1,
    color: theme.palette.custom.white,
    marginTop: theme.spacing(1.5),
  },
})) as typeof Typography;
