import { FormattedMessage } from "react-intl";

import { Root } from "./styled";
import { howItWorksBlockData } from "./schemas";
import { StyledCard, StyledCardBox } from "../cards/styled";
import { Box } from "@mui/material";

export const HowItWorks = () => {
  return (
    <Root>
      <FormattedMessage id="pages.main.howItWorks.title" tagName="h1" />

      <StyledCardBox container mt={4} spacing={2}>
        {howItWorksBlockData.map(({ title, subtitle, icon }, index) => (
          <StyledCard key={title} $hasBorder $index={index} size={{ lg: 6, md: 6, xs: 12 }}>
            <Box className="MainPageCards-CardHeader">
              <Box className="CardHeader-icon-bg">{icon}</Box>
              <FormattedMessage id={title} tagName="h4" />
            </Box>
            {subtitle && <FormattedMessage id={subtitle} tagName="p" />}
          </StyledCard>
        ))}
      </StyledCardBox>
    </Root>
  );
};
