import { Box, Grid2, LinearProgress, linearProgressClasses, LinearProgressProps, styled } from "@mui/material";

export const WithdrawalLinearProgress = styled(LinearProgress)<LinearProgressProps>(({ theme }) => ({
  width: "100%",
  height: 4,
  backgroundColor: theme.palette.custom.main.disabled,
  borderRadius: theme.spacing(1),
  marginTop: "8px !important",

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.custom.main.default,
  },
}));

export const StyledAccordionTitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(1),

  "& h2, span": {
    ...theme.typography.button_1,
  },

  span: {
    color: theme.palette.custom.gray,
  },
}));

export const StyledAccordionWithdrawnBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1),

  "& h4, span": {
    margin: 0,
    ...theme.typography.subtitle_3,
  },

  span: {
    color: theme.palette.custom.gray,
  },
}));

export const StyledAccordionLockBox = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),

  "& h4": {
    margin: 0,
    ...theme.typography.subtitle_3,
  },

  "& span, strong": {
    margin: 0,
    ...theme.typography.body_1,
    color: theme.palette.custom.gray,
  },

  strong: {
    color: theme.palette.custom.white,
  },

  ".LockBox-header": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

export const StyledAccordionBoxesList = styled(Grid2)(({ theme }) => ({
  "& h5": {
    margin: 0,
    marginBottom: theme.spacing(1),
    ...theme.typography.body_1,
  },

  ".BoxesList-boxes": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),

    ".boxTag": {
      padding: theme.spacing(1.5, 4.5),
      border: `1px solid ${theme.palette.custom.main.default}`,
      borderRadius: theme.spacing(3.75),
      textDecorationLine: "none",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },

      p: {
        textAlign: "center",
        ...theme.typography.caption_2,
        color: theme.palette.custom.white,
      },

      "& .lockInfo": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(2),
        marginTop: theme.spacing(1),

        ".lockInfo-Item": {
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(0.5),
        },

        span: {
          ...theme.typography.caption_1,
          color: theme.palette.custom.gray,
        },
      },
    },
  },
}));
