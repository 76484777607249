import React, { FC, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import { Grid2Props } from "@mui/material";

import { StyledCalculationBoxRoot, StyledCalculationItem, StyledCalculationItemBox } from "./styled";

interface IWithBorderInfoBoxProps extends Grid2Props {
  data?: Record<string, string | number>;
}

export const WithBorderInfoBox: FC<PropsWithChildren<IWithBorderInfoBoxProps>> = props => {
  const { data, children, ...restBoxProps } = props;

  if (!data || Object.entries(data).length !== 5) {
    return null;
  }

  const leftSideKeys = Object.keys(data).slice(0, 3);
  const leftSideValues = Object.values(data).slice(0, 3);

  const rightSideKeys = Object.keys(data).slice(3, 5);
  const rightSideValues = Object.values(data).slice(3, 5);

  return (
    <StyledCalculationBoxRoot container size={12} spacing={{ lg: 0, md: 0, sm: 2 }} {...restBoxProps}>
      <StyledCalculationItemBox container size={{ xl: 6, lg: 6, md: 6 }}>
        <StyledCalculationItem>
          {leftSideKeys.map(key => (
            <FormattedMessage key={key} id={`pages.vesting.currentBox.calculationItems.${key}`} tagName="p" />
          ))}
        </StyledCalculationItem>
        <StyledCalculationItem>
          {leftSideValues.map(value => (
            <span key={value}>{value}</span>
          ))}
        </StyledCalculationItem>
      </StyledCalculationItemBox>
      <StyledCalculationItemBox container size={{ xl: 6, lg: 6, md: 6 }}>
        <StyledCalculationItem>
          {rightSideKeys.map(key => (
            <FormattedMessage key={key} id={`pages.vesting.currentBox.calculationItems.${key}`} tagName="p" />
          ))}
        </StyledCalculationItem>
        <StyledCalculationItem>
          {rightSideValues.map(value => (
            <span key={value}>{value}</span>
          ))}
        </StyledCalculationItem>
      </StyledCalculationItemBox>
      {children}
    </StyledCalculationBoxRoot>
  );
};
