import { Box, BoxProps, styled } from "@mui/material";

import { contentSideFlex } from "./index";

interface IStyledBlockRootProps {
  mb?: number;
  gap?: number;
}
export const StyledBlockRoot = styled(Box, { shouldForwardProp: prop => prop !== "mb" && prop !== "gap" })<
  IStyledBlockRootProps & BoxProps
>(({ theme, mb = 3, gap = 1 }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(gap),
  marginBottom: theme.spacing(mb),

  "& .BlockRoot-headerBox": {
    width: "100%",
    ...contentSideFlex,
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.custom.stroke}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },

  "& h4": {
    margin: 0,
    color: theme.palette.common.white,
    ...theme.typography.button_1,
  },

  "& h6": {
    width: "100%",
    color: "#000000",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "130%",
    margin: 0,
  },

  "& p": {
    margin: 0,
    color: theme.palette.common.white,
    ...theme.typography.body_1,
  },
}));
