import { Grid2, styled } from "@mui/material";

import { sizeDecreaseCalc } from "../../utils";

export const StyledWrapper = styled(Grid2)(({ theme }) => ({
  paddingTop: theme.spacing(12),
  height: "100vh",

  "& .notFound": {
    "& .MuiAvatar-root": {
      zIndex: 10,
    },
    position: "relative",
    zIndex: 10,
    "::after": {
      content: '"404"',
      position: "absolute",
      top: sizeDecreaseCalc(-120, 40),
      fontSize: sizeDecreaseCalc(400, 160, "px"),
      color: theme.palette.custom.gray,
      opacity: 0.2,
      zIndex: 1,
    },
  },
}));
