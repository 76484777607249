import React, { HTMLAttributes } from "react";
import { AutocompleteRenderOptionState, Box, Checkbox } from "@mui/material";

import { EntityInputCheckedIcon, EntityInputIcon, StyledValuesBox } from "./styled";

export const renderEntityInputItemOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: any,
  state: AutocompleteRenderOptionState,
) => {
  return (
    <li {...props} key={option.id}>
      <Checkbox checkedIcon={<EntityInputCheckedIcon />} icon={<EntityInputIcon />} checked={state.selected} />
      {option.title || option.value}
    </li>
  );
};

export const renderEntityInputTags = (label: string) => (value: ReadonlyArray<any>) => {
  return (
    <StyledValuesBox>
      <Box>{`${label}`}</Box>
      <Box className="lengthBox">{`${value.length}`}</Box>
    </StyledValuesBox>
  );
};
