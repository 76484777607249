import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "@mui/material";
import React, { ReactNode } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { useTestId } from "@dzambalaorg/provider-test-id";

interface IRadioGroupProps extends RadioGroupProps {
  name: string;
  label?: ReactNode;
  defaultValue?: string | number;
  radii?: Array<{
    value: string | number;
    labelId: string;
  }>;
  control?: (props: RadioProps) => React.JSX.Element;
}

export const RadioGroupInput = (props: IRadioGroupProps) => {
  const { name, label, radii = [], defaultValue, control, ...restProps } = props;

  const { testId } = useTestId();

  const testIdProps = testId ? { "data-testid": `${testId}-${name}` } : {};

  const form = useFormContext();
  const error = get(form.formState.errors, name);
  const { formatMessage } = useIntl();

  const localizedHelperText = error ? formatMessage({ id: error.message }, { label: label }) : "";

  const ControlComponent = control || Radio;

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field }) => (
        <FormControl>
          {label && <FormLabel id="radio-buttons-group-label">{label}</FormLabel>}
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue={defaultValue}
            name={name}
            onChange={field.onChange}
            {...testIdProps}
            {...restProps}
          >
            {radii.map(r => (
              <FormControlLabel
                key={r.labelId}
                value={r.value}
                control={<ControlComponent checked={r.value === field.value} />}
                label={formatMessage({ id: `form.labels.${r.labelId}` })}
              />
            ))}
          </RadioGroup>
          {localizedHelperText && (
            <FormLabel error id="radio-buttons-group-error">
              {localizedHelperText}
            </FormLabel>
          )}
        </FormControl>
      )}
    />
  );
};
