import React from "react";
import { Grid2 } from "@mui/material";

import { AssetsSkeleton, CustomAccordion, CustomAccordionVariant } from "../../../../shared";
import { useFetchAssetsInfo } from "./useFetchAssetsInfo";
import { AccordionSummaryTitle, AccordionContent } from "./components";

export const AssetsInfo = () => {
  const { assetsInfo, isLoading } = useFetchAssetsInfo();

  if (isLoading) {
    return (
      <Grid2 display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" mt={4.75}>
        <AssetsSkeleton />
      </Grid2>
    );
  }

  return (
    <Grid2 container spacing={2}>
      {assetsInfo.map((asset, index) => (
        <CustomAccordion
          key={asset.symbol}
          variant={CustomAccordionVariant.OUTLINED}
          title={<AccordionSummaryTitle asset={asset} />}
          description={<AccordionContent asset={asset} />}
          defaultExpanded={index === 0}
        />
      ))}
    </Grid2>
  );
};
