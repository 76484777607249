import React, { FC, PropsWithChildren } from "react";
import { BoxProps, Typography } from "@mui/material";

import { StyledBoxWithTitleRoot } from "./styled";

interface IBoxWithTitleProps extends PropsWithChildren, BoxProps {
  title: string;
  description?: string;
}

export const BoxWithTitle: FC<IBoxWithTitleProps> = props => {
  const { title, description, children, ...restProps } = props;
  return (
    <StyledBoxWithTitleRoot {...restProps}>
      <Typography variant="body1" component="h4">
        {title}
      </Typography>
      {description && (
        <Typography variant="body1" component="p">
          {description}
        </Typography>
      )}
      {children}
    </StyledBoxWithTitleRoot>
  );
};
