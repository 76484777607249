import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Grid2, useTheme } from "@mui/material";

import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { RichTextDisplay } from "@dzambalaorg/mui-rte";
import { IVestingBox } from "@framework/types";
import { ListActionVariant } from "@framework/styled";

import {
  ContentMode,
  getBackgroundImg,
  isOfferGuard,
  IVestingOfferResponse,
  SplitCalculation,
  StyledCurrentRoot,
  useVestingContractInfo,
  Vesting,
} from "../../../../../shared";
import { Breadcrumbs } from "../../../../../components/breadcrumbs";
import { pageHeaderStyles, StyledDescription, StyledHeaderBox, StyledImage } from "./styled";
import { OfferPurchaseButton } from "../../../../../components/buttons/offerPurchase";
import { VestingBoxPurchaseButton } from "../../../../../components/buttons/purchaseVesting";
import { CurrentBoxContent } from "./content";
import { Timeline } from "./timeline";
import { PlotBlock } from "./plot-block";
import { SplitBenefits } from "./split-benefits";

interface ICurrentBox {
  selected: IVestingOfferResponse | IVestingBox;
}

export const CurrentBox = React.memo((props: ICurrentBox) => {
  const { selected } = props;

  const theme = useTheme();

  const isOffer = isOfferGuard(selected);
  const vestingBox = isOfferGuard(selected) ? selected.box : selected;
  const tokenId = isOfferGuard(selected) ? selected.tokenId : null;
  const startDate = isOfferGuard(selected) ? vestingBox.template!.createdAt : null;
  const backgroundImg = vestingBox.backgroundImg || getBackgroundImg(vestingBox.vestingBoxCat);

  const { releasable, withdrawn } = useVestingContractInfo(tokenId || "", vestingBox.template!.contract!.address);

  const vesting = useMemo(() => {
    return new Vesting(vestingBox, vestingBox.template!.price!, releasable, withdrawn);
  }, [vestingBox, releasable, withdrawn]);

  return (
    <StyledCurrentRoot>
      <Breadcrumbs paths={["vesting.boxes", "vesting.currentBox"]} data={[{}, vestingBox]} />

      <StyledImage component="img" src={backgroundImg} />

      <StyledHeaderBox>
        <PageHeader sx={pageHeaderStyles(theme)} message="pages.vesting.currentBox.title" data={vestingBox} />

        <Grid2 container justifyContent="flex-end" alignItems="flex-end">
          <Grid2 size={{ xs: 12 }} display="flex" justifyContent="flex-end">
            {isOffer ? (
              <OfferPurchaseButton offer={selected} variant={ListActionVariant.button} />
            ) : (
              <VestingBoxPurchaseButton vestingBox={vestingBox} />
            )}
          </Grid2>
        </Grid2>
      </StyledHeaderBox>
      <Grid2 container size={12} spacing={4}>
        <Grid2 size={{ lg: 7 }} gap={7}>
          <StyledDescription variant="body2" color="textSecondary" component="div">
            <FormattedMessage id="pages.vesting.currentBox.descriptionTitle" tagName="h4" />

            <CurrentBoxContent selected={vestingBox} withdrawn={withdrawn} vesting={vesting} />

            <RichTextDisplay data={vestingBox.description} />
          </StyledDescription>

          <Timeline vestingBox={vestingBox} startDate={startDate} />

          <PlotBlock vestingBox={vestingBox} />
        </Grid2>
        <Grid2 size={{ lg: 5 }}>
          <SplitBenefits />

          <SplitCalculation
            mode={ContentMode.BOX}
            box={vestingBox}
            releasablePercentage={vesting.getReleasablePercentageAmount()}
            withdrawnPercentage={vesting.getWithdrawnPercentageAmount()}
          />
        </Grid2>
      </Grid2>
    </StyledCurrentRoot>
  );
});
