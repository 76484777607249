import { Box, Grid2, styled, Typography } from "@mui/material";

import { contentSideFlex, setButtonStyles } from "../../../shared";

export const StyledContent = styled(Grid2)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),

  "& .CurrentToken-releaseBtn": {
    ...setButtonStyles(theme),
  },

  [theme.breakpoints.up("lg")]: {
    width: "70%",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

export const StyledContentWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  ...contentSideFlex,
  gap: theme.spacing(10),

  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(5),
  },
}));

export const StyledAccordionTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle_3,
}));
