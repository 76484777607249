import { Box, BoxProps, styled } from "@mui/material";

import { pageHeader } from "../../styles";

interface IStyledCurrentRoot {
  isLoading?: boolean;
}
export const StyledCurrentRoot = styled(Box, { shouldForwardProp: prop => prop !== "isLoading" })<
  BoxProps & IStyledCurrentRoot
>(({ theme, isLoading }) => ({
  minHeight: isLoading ? "100vh" : undefined,
  paddingTop: theme.spacing(2.5),

  "& h2": {
    ...pageHeader(),
    fontSize: theme.spacing(4.5),
  },

  "& .MuiCardContent-root": {
    "& .MuiTypography-body1": {
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(2),
      fontWeight: 600,
      lineHeight: "140%",
    },
    padding: 0,
    paddingLeft: theme.spacing(2),
  },

  "& .MuiCardActions-root": {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(2),
  },

  "& text": {
    fill: theme.palette.text.secondary,
  },

  "& #function-plot": {
    width: "100%",
  },

  "& .MuiButton-outlinedPrimary": {
    color: "rgb(25, 118, 210)",
    border: "1px solid rgb(25, 118, 210)",

    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(25, 118, 210, 0.04)",
    },
  },

  "& .DraftEditor-root": {
    marginTop: theme.spacing(3),
  },
}));
