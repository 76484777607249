import { FC } from "react";
import { BrowserProvider, Contract, encodeBytes32String, getBytes, ZeroAddress } from "ethers";
import { Config, useAccount, UseAccountReturnType } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";

import type { IServerSignature } from "@dzambalaorg/types-blockchain";
import { TokenType } from "@dzambalaorg/types-blockchain";
import { useUser } from "@dzambalaorg/provider-user";
import { useAllowance, useMetamask, useServerSignature, Web3ContextType } from "@dzambalaorg/react-hooks-eth";
import {
  convertDatabaseAssetToChainAsset,
  convertDatabaseAssetToTokenTypeAsset,
  getEthPrice,
} from "@framework/exchange";
import { ListAction, ListActionVariant } from "@framework/styled";
import { IContract, IUser, IVestingBox, VestingType } from "@framework/types";
import VestingBoxPurchaseABI from "@framework/abis/json/ExchangeVestingFacet/purchaseVesting.json";

import { ShopIcon, WalletIcon } from "../../../shared";

interface IMysteryBoxBuyButtonProps {
  vestingBox: IVestingBox;
  className?: string;
  disabled?: boolean;
  variant?: ListActionVariant;
}

export const VestingBoxPurchaseButton: FC<IMysteryBoxBuyButtonProps> = props => {
  const { className, disabled, vestingBox, variant = ListActionVariant.button } = props;

  const { isConnected } = useAccount();
  const user = useUser<IUser>();
  const isUserAuthenticated = user.isAuthenticated();
  const { openConnectModal } = useConnectModal();

  const metaFnWithAllowance = useAllowance(
    async (web3Context: UseAccountReturnType<Config>, sign: IServerSignature, systemContract: IContract) => {
      const rawProvider = await web3Context.connector!.getProvider();
      const provider = new BrowserProvider(rawProvider as any);
      const signer = await provider.getSigner();
      const contract = new Contract(systemContract.address, VestingBoxPurchaseABI, signer);

      const content = convertDatabaseAssetToChainAsset(vestingBox.content!.components);
      const price = convertDatabaseAssetToChainAsset(vestingBox.template!.price!.components);

      const purchaseVestingData = {
        params: {
          externalId: vestingBox.id,
          expiresAt: sign.expiresAt,
          nonce: getBytes(sign.nonce),
          extra: encodeBytes32String("0x"),
          receiver: vestingBox.wallet,
          referrer: ZeroAddress,
        },
        item: {
          tokenType: Object.values(TokenType).indexOf(TokenType.ERC721),
          token: vestingBox.template!.contract!.address,
          tokenId: vestingBox.templateId,
          amount: "1",
        },
        config: {
          functionType: Object.values(VestingType).indexOf(vestingBox.shape.split("_")[0] as VestingType),
          cliff: vestingBox.cliff,
          duration: vestingBox.duration,
          period: vestingBox.period,
          afterCliffBasisPoints: vestingBox.afterCliffBasisPoints,
          growthRate: vestingBox.growthRate,
        },
        signature: sign.signature,
      };

      const ethPiceValue = getEthPrice(vestingBox.template!.price);

      //ESTIMATE GAS FOR WATERFALL
      const estimateGas = await contract.purchaseVesting.estimateGas(
        purchaseVestingData.params,
        purchaseVestingData.item,
        price,
        content,
        purchaseVestingData.config,
        purchaseVestingData.signature,
        { value: ethPiceValue },
      );

      return contract.purchaseVesting(
        purchaseVestingData.params,
        purchaseVestingData.item,
        price,
        content,
        purchaseVestingData.config,
        purchaseVestingData.signature,
        { value: ethPiceValue, gasLimit: (estimateGas * 110n) / 100n }, // add 10% of gas
      ) as Promise<void>;
    },
  );

  const metaFnWithSign = useServerSignature(
    (_values: null, web3Context: UseAccountReturnType<Config>, sign: IServerSignature, systemContract: IContract) => {
      const price = convertDatabaseAssetToTokenTypeAsset(vestingBox.template!.price!.components);

      return metaFnWithAllowance(
        { contract: systemContract.address, assets: price },
        web3Context,
        sign,
        systemContract,
      );
    },
  );

  const metaFn = useMetamask((web3Context: Web3ContextType) => {
    return metaFnWithSign(
      {
        url: "/vesting/sign",
        method: "POST",
        data: {
          referrer: ZeroAddress,
          vestingBoxId: vestingBox.id,
        },
      },
      null,
      web3Context,
    ) as Promise<void>;
  });

  const handleBuy = async () => {
    await metaFn();
  };

  const handleConnect = () => {
    if (openConnectModal) {
      openConnectModal();
    }
  };

  const templateCapDisabled =
    !isNaN(Number(vestingBox.template?.cap)) &&
    ~~Number(vestingBox.template?.cap) > 0 &&
    Number(vestingBox.template?.amount) >= Number(vestingBox.template?.cap);

  return (
    <ListAction
      icon={WalletIcon}
      onClick={isConnected && isUserAuthenticated ? handleBuy : handleConnect}
      message={isConnected && isUserAuthenticated ? "form.buttons.buy" : "components.header.wallet.connect"}
      className={className}
      dataTestId="VestingPurchaseButton"
      disabled={disabled || templateCapDisabled}
      variant={variant}
    />
  );
};
