import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { FormWrapper } from "@dzambalaorg/mui-form";
import { TextInput } from "@framework/components";
import type { IApplyVestingGeneral } from "@framework/types";

import { Root } from "./styled";
import { validationSchema } from "./validation";
import { StepperActiveSteps, useStepperContext } from "../context";
import { InputBox } from "../components";
import { useApplyVestingStore } from "../store";

export const FirstStepForm = () => {
  const { setFirstStepData, handleNext } = useStepperContext();
  const firstStep = useApplyVestingStore(state => state.firstStep);
  const setFirstStep = useApplyVestingStore(state => state.setFirstStep);

  const onFormStateChange = async (form: UseFormReturn<FieldValues>) => {
    const values = form.getValues() as IApplyVestingGeneral;
    setFirstStep(values);
    return Promise.resolve();
  };

  const onSubmit = async (data: IApplyVestingGeneral) => {
    setFirstStepData(data);
    handleNext(StepperActiveSteps.SECOND);
    return Promise.resolve();
  };

  return (
    <Root>
      <FormWrapper
        sx={{ minWidth: "320px", display: "flex", flexDirection: "column", gap: 3, paddingInline: "16px" }}
        initialValues={firstStep}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        onFormStateChange={onFormStateChange}
        submit={"next"}
      >
        <InputBox required labelId="projectName">
          <TextInput showLabel={false} name="projectName" required variant="outlined" />
        </InputBox>
        <InputBox required labelId="projectLink">
          <TextInput showLabel={false} name="projectLink" required variant="outlined" />
        </InputBox>
        <InputBox required labelId="fullTokenName">
          <TextInput showLabel={false} name="fullTokenName" required variant="outlined" />
        </InputBox>
        <InputBox required labelId="tokenTicker">
          <TextInput showLabel={false} name="tokenTicker" required variant="outlined" />
        </InputBox>
      </FormWrapper>
    </Root>
  );
};
