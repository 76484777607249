import { Box, StepConnector, Stepper, styled } from "@mui/material";

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
  },

  "& .MuiStepLabel-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),

    "& .MuiStepLabel-labelContainer": {
      textAlign: "center",

      "& .MuiStepLabel-label": {
        color: theme.palette.custom.white,
        ...theme.typography.body_2,
      },

      "& .MuiStepLabel-label.Mui-active": {
        color: theme.palette.custom.main.default,
      },
    },
  },
}));

export const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    marginTop: 6,
    height: 2,
    border: 0,
    backgroundColor: theme.palette.custom.gray,
  },
  "&.Mui-active .MuiStepConnector-line": {
    backgroundColor: theme.palette.custom.main.default,
  },
}));

export const StyledActiveStepIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: "50%",
  color: theme.palette.custom.white,
  background: theme.palette.custom.main.default,
  ...theme.typography.body_2,
})) as typeof Box;

export const StyledCompletedStepIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: "50%",
  color: theme.palette.custom.white,
  border: `2px solid ${theme.palette.custom.main.default}`,
  ...theme.typography.body_2,

  svg: {
    path: {
      stroke: theme.palette.custom.main.default,
      strokeWidth: 1.88,
    },
  },
})) as typeof Box;

export const StyledDefaultStepIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: "50%",
  border: `2px solid ${theme.palette.custom.gray}`,
  color: theme.palette.custom.white,
  background: "transparent",
  ...theme.typography.body_2,
})) as typeof Box;
