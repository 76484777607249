import { SvgIconProps } from "@mui/material";
import React from "react";

export const BoxIcon = (props: SvgIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.16992 7.43994L11.9999 12.5499L20.7699 7.46994"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 21.61V12.54" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.93014 2.48004L4.59014 5.44004C3.38014 6.11004 2.39014 7.79004 2.39014 9.17004V14.82C2.39014 16.2 3.38014 17.88 4.59014 18.55L9.93014 21.52C11.0701 22.15 12.9401 22.15 14.0801 21.52L19.4201 18.55C20.6301 17.88 21.6201 16.2 21.6201 14.82V9.17004C21.6201 7.79004 20.6301 6.11004 19.4201 5.44004L14.0801 2.47004C12.9301 1.84004 11.0701 1.84004 9.93014 2.48004Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
