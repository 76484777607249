import { SxProps, Theme } from "@mui/material";

import { sizeDecreaseCalc } from "../../../../../shared";

export const boxesHeaderStyles = (theme: Theme): SxProps => ({
  marginBottom: sizeDecreaseCalc(32, 2, "px"),
  marginTop: sizeDecreaseCalc(20, 2, "px"),

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiGrid-item:last-of-type": {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    "& :first-of-type": {
      textTransform: "none",
    },
    "& .MuiGrid-root": {
      gap: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "flex-end",

        "& .MuiButton-root:last-of-type": {
          margin: 0,
        },
      },
    },
  },
});
