import React, { PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import { Grid2, useMediaQuery, useTheme } from "@mui/material";

import { BoxWithTitle, TokenTotalCount, Vesting } from "../../../../shared";
import { StyledContent } from "../styled";
import { TokenVestingInfo } from "./components";

interface IStartingBoxContent {
  imageUrl: string;
  vesting: Vesting;
  contentTokenAddress?: string;
}

export const BoxContent = React.memo((props: PropsWithChildren<IStartingBoxContent>) => {
  const { imageUrl, vesting, contentTokenAddress, children } = props;

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const contentTokenTicker = vesting.getTokensCountWithTicker();

  return (
    <BoxWithTitle mt={!isMd ? 5 : 4} title={formatMessage({ id: "pages.tokens.current.contentTitle" })}>
      <StyledContent container spacing={2} justifyContent="space-between">
        <TokenTotalCount
          imageUrl={imageUrl}
          contentTokenTotalCount={contentTokenTicker}
          contentTokenAddress={contentTokenAddress}
        />
        <TokenVestingInfo
          withdrawnAmount={vesting.getWithdrawnDisplaying()}
          releasableAmount={vesting.getReleasableDisplaying()}
        />
        <Grid2 alignItems="flex-start">{children}</Grid2>
      </StyledContent>
    </BoxWithTitle>
  );
});
