import React, { FC, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IOffersOverviewDto } from "@framework/types";

import { StyledOffersOverviewRoot, StyledOffersOverviewTag, StyledOffersOverviewTagsBox } from "./styled";
import { WithdrawalLinearProgress } from "../assets-info/styled";
import { OffersOverviewCurrencies } from "./components";

interface IOffersOverviewProps extends IOffersOverviewDto {}

export const OffersOverview: FC<IOffersOverviewProps> = props => {
  const { offers, soldOffersCount, totalCount } = props;
  const [activeTag, setActiveTag] = useState(() => offers[0]?.symbol || "All");

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const salesProgress = totalCount ? (soldOffersCount * 100) / totalCount : 0;

  const overviewCurrencies = useMemo(() => {
    if (offers.length <= 1) {
      return null;
    }
    return ["All"].concat(offers.map(o => o.symbol));
  }, [offers]);

  const filteredOffers = useMemo(() => {
    if (activeTag === "All" || !isMd) {
      return offers;
    }
    return offers.filter(oc => oc.symbol === activeTag);
  }, [offers, activeTag, isMd]);

  return (
    <React.Fragment>
      {isMd && overviewCurrencies && (
        <StyledOffersOverviewTagsBox size={12}>
          {overviewCurrencies.map(oc => (
            <StyledOffersOverviewTag key={oc} isActive={oc === activeTag} onClick={() => setActiveTag(oc)}>
              <Typography variant="body_2" color="custom.white">
                {oc}
              </Typography>
            </StyledOffersOverviewTag>
          ))}
        </StyledOffersOverviewTagsBox>
      )}

      <StyledOffersOverviewRoot>
        <FormattedMessage id="pages.tokens.offersOverview.title" tagName="h2" />
        <FormattedMessage id="pages.tokens.offersOverview.description" tagName="span" />

        <Grid2 size={12}>
          <Box className="OffersOverview-ProgressTitle">
            <FormattedMessage
              id="pages.tokens.offersOverview.salesProgress"
              tagName="h2"
              values={{ span: chunks => <span>{chunks}</span>, value: salesProgress }}
            />
          </Box>
          <WithdrawalLinearProgress value={salesProgress} variant="determinate" />
        </Grid2>

        {!!totalCount && <OffersOverviewCurrencies offers={filteredOffers} />}

        {!totalCount && (
          <Box mt={2}>
            <FormattedMessage id="pages.tokens.offersOverview.noOffers" tagName="span" />
          </Box>
        )}
      </StyledOffersOverviewRoot>
    </React.Fragment>
  );
};
