import { FC, useState } from "react";
import { Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material";

import {
  StyledContentBox,
  StyledIconButton,
  StyledMenuBox,
  StyledMenuHeader,
  StyledMenuWrapper,
  StyledSwipeableDrawer,
} from "./styled";
import { IMenuVariant, Menu } from "../../menu";
import { StyledLink } from "../../inner-header/styled";

export const MobileMenu: FC<IMenuVariant> = props => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Box>
      <StyledIconButton onClick={openDrawer}>
        <MenuIcon />
      </StyledIconButton>
      <StyledSwipeableDrawer
        open={open}
        anchor="right"
        onOpen={openDrawer}
        onClose={closeDrawer}
        disableBackdropTransition={iOS}
        disableDiscovery={iOS}
      >
        <StyledMenuWrapper>
          <StyledMenuHeader>
            <StyledIconButton onClick={closeDrawer}>
              <CloseIcon sx={{ fontSize: 24 }} />
            </StyledIconButton>
          </StyledMenuHeader>
          <StyledContentBox>
            <StyledMenuBox>
              <StyledLink component={RouterLink} to="/">
                <img src="/files/new/simple_logo.svg" alt="logo" width={47} height={40} />
              </StyledLink>
              <Menu onCloseMenu={closeDrawer} variant={props.variant} />
            </StyledMenuBox>
          </StyledContentBox>
        </StyledMenuWrapper>
      </StyledSwipeableDrawer>
    </Box>
  );
};
