import { Box, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(3),
  padding: theme.spacing(10, 2, 2, 2),

  color: theme.palette.common.white,
  ...theme.typography.subtitle_2,
}));
