import { FC, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { ErrorBoundary } from "@dzambalaorg/mui-page-layout";

import { InnerHeader } from "../inner-header";
import { Footer } from "../footer";
import { Root, StyledContainer } from "./styled";
import { MenuVariant, PATHS } from "../../../shared";

export const Layout: FC = () => {
  const location = useLocation();

  const isInMainPage = location.pathname === PATHS.Main;
  const menuVariant = isInMainPage ? MenuVariant.LANDING : MenuVariant.INNER;

  useEffect(() => {
    const timerId = setTimeout(() => {
      window.scroll({ top: 0, left: 0, behavior: "auto" });
    });
    return () => {
      clearTimeout(timerId);
    };
  }, [location.pathname]);

  return (
    <Root>
      <InnerHeader isInMainPage={isInMainPage} />
      <StyledContainer $menuVariant={menuVariant}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </StyledContainer>
      {isInMainPage && <Footer />}
    </Root>
  );
};
