import React, { FC } from "react";
import { Grid2, useMediaQuery, useTheme } from "@mui/material";

import { ProgressOverlay } from "@framework/components";
import { StyledPagination } from "@framework/styled";
import { TokenMetadata } from "@framework/types";
import { IPaginationResult } from "@dzambalaorg/types-collection";

import {
  AssetBoxCardSkeleton,
  BoxesAssetVariant,
  DESKTOP_ITEMS_COUNT,
  LoadMoreButton,
  MOBILE_ITEMS_COUNT,
  renderEmpty,
  renderError,
  renderLoader,
  TokenCard,
  useFetchQuery,
} from "../../../../shared";
import { Token } from "../types";

interface IBoxesAssetsProps {
  variant: BoxesAssetVariant;
}

export const BoxesAssets: FC<IBoxesAssetsProps> = props => {
  const { variant } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const { data, isLoading, isError, error, take, skip, onChangePage } = useFetchQuery<IPaginationResult<Token>>(
    "tokens/search",
    {
      metadata: {
        [TokenMetadata.SPLIT_ID]: "SPLIT_ID",
      },
    },
    {
      take: isMd ? MOBILE_ITEMS_COUNT : DESKTOP_ITEMS_COUNT,
      skip: 0,
    },
  );

  return (
    <React.Fragment>
      {/*<AssetsFilters tab={variant} />*/}

      <ProgressOverlay
        isLoading={!data && isLoading}
        isError={!data && isError}
        isEmpty={data?.rows?.length === 0}
        renderEmpty={renderEmpty}
        renderLoader={renderLoader({ skeleton: <AssetBoxCardSkeleton /> })}
        renderError={renderError(error)}
      >
        <Grid2 container spacing={2}>
          {data?.rows.map(token => (
            <Grid2 size={{ lg: 4, md: 6, sm: 6, xs: 12 }} key={token.id}>
              <TokenCard template={token.template} token={token} variant={variant} />
            </Grid2>
          ))}
        </Grid2>
      </ProgressOverlay>

      {!isMd && !!data?.count && data?.count > take && (
        <StyledPagination
          shape="rounded"
          page={skip / take + 1}
          count={Math.ceil(data.count / take)}
          onChange={onChangePage}
        />
      )}
      {isMd && data?.rows.length !== data?.count && <LoadMoreButton loadMoreHandler={() => {}} />}
    </React.Fragment>
  );
};
