export const offersTableHeaderSchema = [
  "pages.tokens.offersTable.title",
  "pages.tokens.offersTable.price",
  "pages.tokens.offersTable.tvl",
  "pages.tokens.offersTable.unlocked",
  "pages.tokens.offersTable.offered",
  "",
];

export const offersOverviewTableHeaderSchema = [
  "pages.tokens.offersOverview.table.currency",
  "pages.tokens.offersOverview.table.totalOffers",
  "pages.tokens.offersOverview.table.remainingToSell",
  "pages.tokens.offersOverview.table.potentialRevenue",
  "pages.tokens.offersOverview.table.soldAmount",
  "pages.tokens.offersOverview.table.revenue",
];
