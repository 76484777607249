import { Grid2, styled, SxProps, Theme, Box, BoxProps } from "@mui/material";

import { buttonStyles, contentSideFlex, setButtonStyles } from "../../../../shared";

export const StyledOffersOverviewRoot = styled(Grid2)(({ theme }) => ({
  gap: theme.spacing(1),
  borderRadius: theme.spacing(2.5),
  background: theme.palette.custom.card_2.background,
  padding: theme.spacing(3.75),
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2.5),
  },

  "& h2, .MuiTypography-body1": {
    margin: 0,
    ...theme.typography.button_1,
  },

  "& span": {
    margin: 0,
    ...theme.typography.caption_1,
    color: theme.palette.custom.gray,
  },

  "& .OffersOverview-ProgressTitle": {
    marginTop: theme.spacing(3),
    "& h2": {
      ...contentSideFlex,
      flexDirection: "row",
      justifyContent: "space-between",
      gap: theme.spacing(0.75),

      "& span": {
        margin: 0,
        ...theme.typography.subtitle_3,
        color: theme.palette.custom.gray,
      },
    },
  },
}));

export const StyledOffersOverviewTagsBox = styled(Grid2)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

interface IStyledOffersOverviewTagProps {
  isActive?: boolean;
}

export const StyledOffersOverviewTag = styled(Box, { shouldForwardProp: prop => prop !== "isActive" })<
  BoxProps & IStyledOffersOverviewTagProps
>(({ theme, isActive }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1),
  background: isActive ? theme.palette.custom.main.default : "transparent",
  border: `1px solid ${!isActive ? theme.palette.custom.card_1.stroke : "none"}`,
  cursor: "pointer",

  ":hover": {
    background: isActive ? theme.palette.custom.main.hover : theme.palette.custom.card_1.background,
  },

  "& .MuiTypography-body_2": {
    margin: 0,
  },
}));

export const StyledDeleteOfferButtonsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItem: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  marginTop: theme.spacing(3),
  paddingRight: theme.spacing(3),

  "& .MuiButton-root": {
    ...buttonStyles,
    textTransform: "none",
  },

  "& .DeleteOffer-AgreeBtn": {
    ...setButtonStyles(theme),
  },

  "& .DeleteOffer-Cancel": {
    ...setButtonStyles(theme, {
      default: "transparent",
      hover: theme.palette.custom.card_1.background,
      disabled: theme.palette.custom.card_1.background,
    }),
    border: `1px solid ${theme.palette.custom.red}`,
  },
}));

export const setOverviewHeaderStyles = (theme: Theme): SxProps<Theme> => ({
  ...theme.typography.subtitle_3,
  color: theme.palette.custom.gray,
  marginBottom: theme.spacing(3),
});
