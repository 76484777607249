import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Grid2, Typography } from "@mui/material";

import { StyledAvatar } from "@framework/styled";

import { SimpleButton } from "../../../components/buttons/simple";
import { StyledWrapper } from "./styled";

export const MessageComponent = () => {
  const { formatMessage } = useIntl();

  return (
    <StyledWrapper display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" gap={4}>
      <Grid2
        className="notFound"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={4}
      >
        <StyledAvatar src="/files/new/not_found_page.png" width={280} height={350} />
        <Typography
          sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", gap: 1 }}
          variant="subtitle_2"
          color="custom.white"
          component="p"
        >
          <FormattedMessage
            id="errors.pageNotFound"
            values={{
              errorText: formatMessage({ id: "errors.aboutPage" }),
              span: chunks => {
                return (
                  <Typography variant="body_1" color="custom.gray">
                    {chunks}
                  </Typography>
                );
              },
            }}
          />
        </Typography>
      </Grid2>
      <SimpleButton component={Link} to={"/vesting/boxes"}>
        {formatMessage({ id: "form.buttons.backToHome" })}
      </SimpleButton>
    </StyledWrapper>
  );
};
