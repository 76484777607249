import { ISecondStepForm } from "../types";

export const secondStepFormEmpty: ISecondStepForm = {
  contactDetails: {
    fullName: "",
    projectRole: "",
    projectEmailAddress: "",
    telegramId: "",
  },
  whoRefereed: "",
  keyQuestionA: "",
  keyQuestionB: "",
  keyQuestionC: "",
  tokenSymbol: "",
  tokenCirculation: "",
  tgeDeadlines: "",
  projectFocusOn: "",
  highPotential: "",
  painPoints: "",
  painSolutions: "",
  productAudience: "",
  projectBenefits: "",
  team: {
    size: "",
    information: "",
    experience: "",
  },
  tokenUtility: "",
  tokenDistribution: "",
  productLaunched: "",
  numberOfUsers: "",
  tvl: "",
  totalVolume: "",
  nftSales: "",
  communitySize: "",
  communityDemography: "",
  communitySupport: "",
  innovations: "",
  streamsRevenue: "",
  isTeamWorking: "",
  partners: "",
  mainCompetitors: "",
  mainInnovations: "",
  isProjectOpen: "",
  extendingVesting: "",
  willingToLowerPrice: "",
  antiPhishingCode: "",
};
