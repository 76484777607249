import { Box, BoxProps, styled } from "@mui/material";

import { MenuVariant, sizeDecreaseCalc } from "../../../shared";

interface IRootProps {
  menuVariant?: MenuVariant;
}

export const Root = styled(Box, { shouldForwardProp: prop => prop !== "menuVariant" })<BoxProps & IRootProps>(
  ({ theme }) => ({
    minWidth: "336px",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: sizeDecreaseCalc(32, 16),
    borderRadius: "100px",

    "& [data-testid='rk-chain-button'], [data-testid='rk-account-button'], [data-testid='rk-connect-button']": {
      background: "transparent !important",
      border: `1px solid ${theme.palette.custom.main.default} !important`,
      color: "#FFFFFF !important",

      "& div.ju367v8a": {
        background: "transparent !important",
      },

      "& div.iekbcc0": {
        border: "none",
        "& div.iekbcc0 > div": {
          color: "#FFFFFF !important",
        },
      },

      "&: hover": {
        border: `1px solid ${theme.palette.custom.main.hover}`,
        boxShadow: `0 0 5px ${theme.palette.custom.main.hover}`,
      },

      "& div.ju367v1p > div > div.iekbcc0": {
        background: "transparent !important",
      },
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-end",
      rowGap: theme.spacing(3),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),

      "& [data-testid='rk-connect-button']": {
        width: "80%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "flex-end",
        background: "transparent",
        borderRadius: theme.spacing(3.75),
        border: `1px solid ${theme.palette.custom.main.default}`,
        color: theme.palette.custom.white,
        padding: theme.spacing(1, 2.5),
        ...theme.typography.caption_2,
      },

      "& .ju367v1s": {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        width: "70%",

        "& [data-testid='rk-chain-button']": {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          background: "transparent",
          borderRadius: theme.spacing(3.75),
          border: `1px solid ${theme.palette.custom.main.default}`,
          color: theme.palette.custom.white,
          padding: theme.spacing(1, 2.5),

          "& .ju367v1p": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(0.75),
            ...theme.typography.caption_2,

            "& div > div.iekbcc0": {
              background: "transparent !important",
              height: "30px !important",

              "& img": {
                width: "30px",
                height: "30px",
              },
            },
          },
        },

        "& [data-testid='rk-account-button']": {
          width: "100%",
          padding: theme.spacing(1, 2.5),
          background: "transparent",
          borderRadius: theme.spacing(3.75),
          border: `1px solid ${theme.palette.custom.main.default}`,

          "& div.iekbcc0": {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: theme.spacing(0.75),
            ...theme.typography.caption_2,
            color: theme.palette.custom.white,

            "& div > div.ju367va": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              "& div": {
                width: "fit-content",
              },
            },

            "& div > div.ju367v8": {
              width: "fit-content",

              "& div": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px !important",
                height: "30px !important",
                fontSize: "24px",
                borderRadius: "50%",
              },
            },
          },
        },
      },
    },
  }),
);
