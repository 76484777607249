import React, { CSSProperties, FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { Grid2, useTheme } from "@mui/material";

import { VestingBoxCategory } from "@framework/types";
import { AutocompleteInput, EntityInput, EthInput, IChip, SearchWithFilters } from "@framework/components";

import {
  FilterIcon,
  getPeriodFromSecondsInDays,
  SearchIcon,
  VestingBoxesTabs,
  setFiltersStyles,
  CloseIcon,
} from "../../../../../../shared";
import { renderEntityInputItemOption, renderEntityInputTags } from "./utils";

interface IBoxesFiltersProps {
  tab?: VestingBoxesTabs;
}

const initialValues = {
  query: "",
  vestingBoxCat: [],
  symbols: [],
  durations: [],
  priceFrom: "0",
  priceTo: "0",
};

export const BoxesFilters: FC<IBoxesFiltersProps> = props => {
  const { tab } = props;

  const { formatMessage } = useIntl();
  const theme = useTheme();

  const {
    searchInputWrapperStyles,
    searchInputStyles,
    searchInputCollapseStyles,
    searchInputChipsStyles,
    entityInputStyles,
    ethInputStyles,
    swipeableDrawerStyles,
  } = useMemo(() => {
    return setFiltersStyles(theme);
  }, []);

  const onPrepareChipValue = (chip: IChip): IChip => {
    if (chip.key !== "durations") {
      return chip;
    } else {
      return { ...chip, title: getPeriodFromSecondsInDays(Number(chip.value)) };
    }
  };

  return (
    <SearchWithFilters
      key={tab}
      initialValues={initialValues}
      startAdornment={<SearchIcon />}
      endAdornmentIcon={<FilterIcon />}
      inputWrapperStyles={searchInputWrapperStyles}
      inputStyles={searchInputStyles}
      collapseStyles={searchInputCollapseStyles}
      chipsStyles={searchInputChipsStyles}
      drawerStyles={swipeableDrawerStyles}
      onPrepareChipValue={onPrepareChipValue}
      chipsKeys={["durations", "symbols", "vestingBoxCat"]}
      drawerHeaderIcon={<CloseIcon />}
    >
      <Grid2 container spacing={1} alignItems="flex-end">
        <Grid2 size={{ xs: 12, md: 4, lg: 4 }} className="BoxesFilters-grid">
          <EntityInput
            sx={entityInputStyles}
            multiple
            name={"durations"}
            controller={"vesting/boxes"}
            fetchPath={"autocompleteDuration"}
            renderOption={renderEntityInputItemOption}
            renderTags={renderEntityInputTags(formatMessage({ id: "form.placeholders.durations" }))}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4, lg: 4 }} className="BoxesFilters-grid">
          <EntityInput
            sx={entityInputStyles}
            multiple
            name={"symbols"}
            controller={"vesting/boxes"}
            fetchPath={"autocompletePriceTicker"}
            renderOption={renderEntityInputItemOption}
            renderTags={renderEntityInputTags(formatMessage({ id: "form.placeholders.symbols" }))}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4, lg: 4 }} className="BoxesFilters-grid">
          <AutocompleteInput
            sx={entityInputStyles}
            multiple
            name="vestingBoxCat"
            options={Object.entries(VestingBoxCategory).map(([key, value]) => ({
              key,
              value: formatMessage({ id: `enums.vestingBoxBadge.${value}` }),
            }))}
            renderOption={renderEntityInputItemOption}
            renderTags={renderEntityInputTags(formatMessage({ id: "form.placeholders.vestingBoxCat" }))}
          />
        </Grid2>
        <Grid2 container spacing={2} size={12} alignItems="flex-end">
          <Grid2 size={{ xs: 12, md: 6, lg: 6 }} className="BoxesFilters-grid">
            <EthInput
              style={ethInputStyles as CSSProperties}
              name={"priceFrom"}
              label={formatMessage({ id: "form.labels.priceFrom" })}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6, lg: 6 }} className="BoxesFilters-grid">
            <EthInput
              style={ethInputStyles as CSSProperties}
              name={"priceTo"}
              label={formatMessage({ id: "form.labels.priceTo" })}
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </SearchWithFilters>
  );
};
