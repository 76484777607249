import React, { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { Grid2, useMediaQuery, useTheme } from "@mui/material";
import { RefetchOptions } from "@tanstack/react-query";

import { StyledEmptyWrapper, StyledPagination } from "@framework/styled";
import { IPaginationResult } from "@dzambalaorg/types-collection";

import {
  AssetsDashboardTabs,
  DESKTOP_ITEMS_COUNT,
  IVestingOfferResponse,
  LoadMoreButton,
  MOBILE_ITEMS_COUNT,
  StyledGridTable,
  StyledGridTableHeader,
  useFetchQuery,
} from "../../../../shared";
import { offersTableHeaderSchema } from "./schemas";
import { OfferAsset } from "./components";
import { setOverviewHeaderStyles } from "./styled";
import { useOffersListStore } from "./store";
import { DeleteOfferModal } from "./components/DeleteOfferModal";

interface IOffersListProps {
  variant: AssetsDashboardTabs;
  overviewRefetch: (options?: RefetchOptions) => any;
}

export const OffersList: FC<IOffersListProps> = (props: IOffersListProps) => {
  const { overviewRefetch } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const { formatMessage } = useIntl();

  const openDeleteOfferDialog = useOffersListStore(state => state.openDeleteOfferDialog);

  const { data, isLoading, take, skip, onChangePage, refetch } = useFetchQuery<
    IPaginationResult<IVestingOfferResponse>
  >("vesting/offers", void 0, {
    take: isMd ? MOBILE_ITEMS_COUNT : DESKTOP_ITEMS_COUNT,
    skip: 0,
  });

  const onDeleteOffer = useCallback((item: IVestingOfferResponse) => () => openDeleteOfferDialog(item), []);

  if (!data?.rows.length) {
    return null;
  }

  return (
    <React.Fragment>
      {!isMd && (
        <StyledGridTable title={formatMessage({ id: "pages.tokens.offersTable.activeOffers" })}>
          <StyledGridTableHeader
            hasLastColumnIcons
            sx={setOverviewHeaderStyles(theme)}
            isMd={isMd}
            data={offersTableHeaderSchema}
          />
          <StyledEmptyWrapper count={data?.rows.length} isLoading={isLoading}>
            {data?.rows.map(offer => <OfferAsset key={offer.id} offer={offer} handleDelete={onDeleteOffer} />)}
          </StyledEmptyWrapper>
          <Grid2 size={12}>
            {!!data?.count && data.count > take && (
              <StyledPagination
                sx={{ marginTop: 0 }}
                shape="rounded"
                page={skip / take + 1}
                count={Math.ceil(data.count / take)}
                onChange={onChangePage}
              />
            )}
          </Grid2>
        </StyledGridTable>
      )}
      {isMd && (
        <StyledGridTable title={formatMessage({ id: "pages.tokens.offersTable.activeOffers" })} hasBorder={false}>
          <StyledEmptyWrapper count={data?.rows.length} isLoading={isLoading}>
            {data?.rows.map(offer => (
              <OfferAsset key={offer.id} offer={offer} isMd={isMd} handleDelete={onDeleteOffer} />
            ))}
            {data?.rows.length !== data?.count && (
              <LoadMoreButton
                loadMoreHandler={() => {
                  return;
                }}
              />
            )}
          </StyledEmptyWrapper>
        </StyledGridTable>
      )}

      <DeleteOfferModal refetch={refetch} overviewRefetch={overviewRefetch} />
    </React.Fragment>
  );
};
