import { SxProps, Theme } from "@mui/material";

export const setButtonStyles = (theme: Theme, colors?: Record<string, string>): SxProps<Theme> => ({
  minWidth: "180px",
  textTransform: "none",
  background: colors?.default || theme.palette.custom.main.default,
  border: "none",
  borderRadius: theme.spacing(3.75),
  padding: theme.spacing(1.5, 2.5),
  color: theme.palette.custom.white,
  ...theme.typography.body_2,

  "&:hover": {
    background: colors?.hover || theme.palette.custom.main.hover,
  },

  "&:disabled": {
    background: colors?.disabled || theme.palette.custom.main.disabled,
    color: theme.palette.custom.gray,
  },
});
