import React from "react";
import { useIntl } from "react-intl";
import { Step, StepLabel } from "@mui/material";

import { CheckMark } from "../../../../../../shared";
import { useStepperContext } from "../context";
import {
  CustomConnector,
  StyledActiveStepIcon,
  StyledCompletedStepIcon,
  StyledDefaultStepIcon,
  StyledStepper,
} from "./styled";

const steps = [
  "pages.vesting.application.step_1",
  "pages.vesting.application.step_2",
  "pages.vesting.application.step_3",
];

export const FormStepper = () => {
  const { formatMessage } = useIntl();
  const { activeStep } = useStepperContext();

  return (
    <StyledStepper
      sx={{ width: "100%", marginTop: 3 }}
      activeStep={activeStep}
      alternativeLabel
      connector={<CustomConnector />}
    >
      {steps.map((id, index, array) => {
        const completed = activeStep === array.length - 1 || index < activeStep;
        const isActive = activeStep === index;

        const icon = completed ? (
          <StyledCompletedStepIcon>
            <CheckMark />
          </StyledCompletedStepIcon>
        ) : isActive ? (
          <StyledActiveStepIcon component="span">{`0${index + 1}`}</StyledActiveStepIcon>
        ) : (
          <StyledDefaultStepIcon component="span">{`0${index + 1}`}</StyledDefaultStepIcon>
        );

        return (
          <Step key={id} completed={completed}>
            <StepLabel icon={icon}>{formatMessage({ id })}</StepLabel>
          </Step>
        );
      })}
    </StyledStepper>
  );
};
