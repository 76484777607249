import React, { PropsWithChildren, ReactNode } from "react";
import { Badge, Grid2, Grid2Props, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { RequiredStarIcon } from "../../../../../../shared";

interface IInputBoxPropsBase extends PropsWithChildren, Grid2Props {
  required?: boolean;
}

type TInputBoxProps =
  | (IInputBoxPropsBase & { labelId: string; label?: ReactNode })
  | (IInputBoxPropsBase & { label: ReactNode; labelId?: string });

export const InputBox = (props: TInputBoxProps) => {
  const { labelId, label, required, children, ...restProps } = props;
  return (
    <Grid2 id="TextField-Box" {...restProps}>
      <Badge sx={{ mb: 1 }} badgeContent={<RequiredStarIcon />} invisible={!required}>
        <Typography variant="caption_1" color="custom.white" component="p">
          {typeof label !== "undefined" ? label : <FormattedMessage id={`form.labels.${labelId}`} />}
        </Typography>
      </Badge>
      {children}
    </Grid2>
  );
};
