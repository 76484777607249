import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";

import { IVestingBox } from "@framework/types";

import { StyledBlockRoot } from "../../../../../../shared";
import { TimelineDates } from "./dates";

interface ITimelineProps {
  vestingBox: IVestingBox;
  startDate?: string | null;
}

export const Timeline: FC<ITimelineProps> = props => {
  const { vestingBox, startDate } = props;

  return (
    <StyledBlockRoot mb={7.5}>
      <Box className="BlockRoot-headerBox">
        <FormattedMessage id="pages.vesting.currentBox.timelineTitle" tagName="h4" />
      </Box>
      <TimelineDates vestingBox={vestingBox} startDate={startDate} />
    </StyledBlockRoot>
  );
};
