import React, { FC, SyntheticEvent, useState } from "react";
import { SxProps, Theme } from "@mui/material";

import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from "./styled";
import { ArrowDownIcon } from "../../assets";

export enum CustomAccordionVariant {
  FILLED = "ASSET",
  OUTLINED = "OUTLINED",
}

interface ICustomAccordionProps {
  title: React.ReactNode;
  description: React.ReactNode;
  variant?: CustomAccordionVariant;
  defaultExpanded?: boolean;
  summaryStyles?: SxProps<Theme>;
}

export const CustomAccordion: FC<ICustomAccordionProps> = props => {
  const { title, description, summaryStyles = {}, defaultExpanded = false } = props;
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const toggleExpanded = () => (_event: SyntheticEvent) => {
    setExpanded(prevState => !prevState);
  };
  return (
    <StyledAccordion disableGutters elevation={0} expanded={expanded} onChange={toggleExpanded()}>
      <StyledAccordionSummary sx={summaryStyles} expandIcon={<ArrowDownIcon />}>
        {title}
      </StyledAccordionSummary>
      <StyledAccordionDetails expanded={expanded}>{description}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
