import { Grid2, Grid2Props, styled } from "@mui/material";

interface IStyledRootProps {
  isInner?: boolean;
  isMoreThanOneItem?: boolean;
}

export const StyledRoot = styled(Grid2, {
  shouldForwardProp: prop => prop !== "isInner",
})<Grid2Props & IStyledRootProps>(({ theme, isInner }) => ({
  gap: theme.spacing(1),
  borderRadius: theme.spacing(2.5),
  background: theme.palette.custom.card_2.background,

  color: theme.palette.custom.white,
  ...theme.typography.body_1,

  "& .GridTable-Title": {
    padding: theme.spacing(3.75, 3.75, 3, 3.75),

    fontSize: theme.spacing(3),
    fontWeight: 600,
    lineHeight: "130%",

    [theme.breakpoints.down("md")]: {
      border: "none",
      borderRadius: 0,
      background: "transparent",
      padding: theme.spacing(2.5, 2.5, 0, 2.5),
    },
  },

  "& .GridTable-Content": {
    padding: !isInner ? theme.spacing(0, 3.75, 3.75, 3.75) : 0,

    [theme.breakpoints.down("md")]: {
      padding: !isInner ? theme.spacing(0, 1.5, 2.5, 1.5) : 0,
    },
  },
}));

export const StyledTableBodyRoot = styled(Grid2, {
  shouldForwardProp: prop => prop !== "isInner" && prop !== "isMoreThanOneItem",
})<Grid2Props & IStyledRootProps>(({ theme, isInner, isMoreThanOneItem }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.25, 0),

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, isInner ? 0 : 1.5),
    gap: theme.spacing(1.5),
    color: theme.palette.custom.white,
    ...theme.typography.body_1,

    ":not(:last-child)": {
      paddingBottom: isMoreThanOneItem ? theme.spacing(3) : theme.spacing(1),
      marginBottom: isMoreThanOneItem ? theme.spacing(2) : 0,
      borderBottom: isMoreThanOneItem ? `1px solid ${theme.palette.custom.stroke}` : "none",
    },
  },
}));

interface IStyledCellProps {
  isFirst?: boolean;
}

export const StyledCell = styled(Grid2, {
  shouldForwardProp: prop => prop !== "isFirst",
})<Grid2Props & IStyledCellProps>(({ theme, isFirst }) => ({
  textAlign: isFirst ? "left" : "right",

  "& svg": {
    color: "#FF0000",
  },

  "& span": {
    color: theme.palette.custom.gray,
    ...theme.typography.subtitle_3,
  },

  "& .MuiButtonBase-root": { padding: 0 },

  "& .CloseOffer-btn": {
    minWidth: "142px",
    background: "transparent",
    border: `1px solid ${theme.palette.custom.red}`,
    color: "#FFFFFF",
    fontSize: theme.spacing(1.5),
    textTransform: "none",
    borderRadius: theme.spacing(3.75),
    padding: theme.spacing(0.75, 1),
  },

  [theme.breakpoints.down("md")]: {
    textAlign: "left",

    "& svg": {
      width: 16,
      height: 16,
      color: "#FFFFFF",
    },
  },
}));
