import { CSSProperties } from "react";

import { IThemeProviderProps } from "@dzambalaorg/provider-theme";

declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Palette {
    custom: CustomPaletteColor;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteOptions {
    custom?: CustomPaletteColor;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface CustomPaletteColor {
    main: MainPaletteColor;
    card_1: CardPaletteColor;
    card_2: CardPaletteColor;
    effects: EffectsPaletteColor;
    background: BackgroundPaletteColor;
    white: string;
    black: string;
    bodyText: string;
    green: string;
    gray: string;
    red: string;
    stroke: string;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface MainPaletteColor {
    default: string;
    hover: string;
    pressed: string;
    disabled: string;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface CardPaletteColor {
    background: string;
    stroke: string;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface EffectsPaletteColor {
    background_shine: string;
    background_blur: string;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface BackgroundPaletteColor {
    default: string;
  }
}

declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyVariants {
    title_1?: CSSProperties;
    title_2?: CSSProperties;
    title_3?: CSSProperties;
    subtitle_1?: CSSProperties;
    subtitle_2?: CSSProperties;
    subtitle_3?: CSSProperties;
    button_1?: CSSProperties;
    body_1?: CSSProperties;
    body_2?: CSSProperties;
    caption_1?: CSSProperties;
    caption_2?: CSSProperties;
    caption_3?: CSSProperties;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyVariantsOptions {
    title_1?: CSSProperties;
    title_2?: CSSProperties;
    title_3?: CSSProperties;
    subtitle_1?: CSSProperties;
    subtitle_2?: CSSProperties;
    subtitle_3?: CSSProperties;
    button_1?: CSSProperties;
    body_1?: CSSProperties;
    body_2?: CSSProperties;
    caption_1?: CSSProperties;
    caption_2?: CSSProperties;
    caption_3?: CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyPropsVariantOverrides {
    title_1?: true;
    title_2?: true;
    title_3?: true;
    subtitle_1?: true;
    subtitle_2?: true;
    subtitle_3?: true;
    button_1?: true;
    body_1?: true;
    body_2?: true;
    caption_1?: true;
    caption_2?: true;
    caption_3?: true;
  }
}

const setFontStyles = (fontSize: number, fontWeight: number, lineHeight: string): CSSProperties => ({
  fontSize,
  fontWeight,
  lineHeight,
});

const customPalette = {
  custom: {
    main: {
      default: "#3F65FF",
      hover: "#3656E0",
      pressed: "#2A43B5",
      disabled: "#2A3C83",
    },
    card_1: {
      background: "rgba(137, 137, 137, 0.1)",
      stroke: "rgba(255, 255, 255, 0.15)",
    },
    card_2: {
      background: "#1A1B23",
      stroke: "#4467FF",
    },
    effects: {
      background_shine: "#0B1E42",
      background_blur: "#05122D",
    },
    background: {
      default: "#000618",
    },
    white: "#ffffff",
    black: "#000000",
    bodyText: "#898CA9",
    green: "#2FE000",
    gray: "#676767",
    red: "#FF1D1D",
    stroke: "#2B2C3B",
  },
};

export const themeProps: IThemeProviderProps = {
  darkPalette: customPalette,
  lightPalette: customPalette,
  options: {
    breakpoints: {
      values: {
        xs: 0,
        sm: 615,
        md: 960,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: "Inter, sans-serif",
      title_1: { ...setFontStyles(60, 900, "120%"), fontFamily: "Montserrat, sans-serif" },
      title_2: { ...setFontStyles(50, 900, "130%"), fontFamily: "Montserrat, sans-serif" },
      title_3: { ...setFontStyles(32, 700, "150%"), fontFamily: "Montserrat, sans-serif" },
      subtitle_1: { ...setFontStyles(26, 400, "100%"), fontFamily: "Inter, sans-serif" },
      subtitle_2: { ...setFontStyles(20, 700, "100%"), fontFamily: "Inter, sans-serif" },
      subtitle_3: { ...setFontStyles(20, 400, "100%"), fontFamily: "Inter, sans-serif" },
      button_1: { ...setFontStyles(24, 700, "100%"), fontFamily: "Inter, sans-serif", textTransform: "none" },
      body_1: { ...setFontStyles(16, 400, "130%"), fontFamily: "Inter, sans-serif" },
      body_2: { ...setFontStyles(16, 700, "100%"), fontFamily: "Inter, sans-serif" },
      caption_1: { ...setFontStyles(14, 400, "100%"), fontFamily: "Inter, sans-serif" },
      caption_2: { ...setFontStyles(14, 700, "100%"), fontFamily: "Inter, sans-serif" },
      caption_3: { ...setFontStyles(12, 400, "100%"), fontFamily: "Inter, sans-serif" },
    },
    components: {
      MuiCardHeader: {
        styleOverrides: {
          content: {
            width: "100%",
          },
          title: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            flex: 1,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "::-webkit-scrollbar": {
            height: 4,
            width: 4,
          },
          "::-webkit-scrollbar-track": {
            background: "rgba(119, 129, 146, 0.3)",
            borderRadius: "100px",
          },
          "::-webkit-scrollbar-thumb": {
            background: "rgba(119, 129, 146, 0.4)",
            borderRadius: "100px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "rgba(119, 129, 146, 0.8)",
          },
        },
      },
    },
  },
};
