export const howItWorksBlockData = [
  {
    title: "pages.main.howItWorks.benefitsTitle",
    subtitle: "pages.main.howItWorks.benefitsSubtitle",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/trusted.png"} alt={"#"} width={60} height={60} />,
  },
  {
    title: "pages.main.howItWorks.reputationTitle",
    subtitle: "pages.main.howItWorks.reputationSubtitle",
    icon: <img className="CardHeader-icon" src={"/files/new/icons/vetting.png"} alt={"#"} width={60} height={60} />,
  },
];
