import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionSummary,
  accordionSummaryClasses,
  styled,
} from "@mui/material";

export const StyledAccordion = styled(Accordion)(() => ({
  width: "100%",
  transition: "all 0.3s linear",
  background: "transparent",

  "&::before": {
    display: "none",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails, { shouldForwardProp: prop => prop !== "expanded" })<
  AccordionDetailsProps & { expanded: boolean }
>(({ theme, expanded }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: theme.spacing(1.5),
  padding: theme.spacing(2.5),
  color: theme.palette.custom.white,
  background: theme.palette.custom.card_2.background,
  borderBottomLeftRadius: expanded ? theme.spacing(2.5) : 0,
  borderBottomRightRadius: expanded ? theme.spacing(2.5) : 0,

  [theme.breakpoints.down("sm")]: {},

  "& p": {
    margin: 0,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2.5),
  color: theme.palette.custom.white,
  background: theme.palette.custom.card_2.background,
  borderRadius: theme.spacing(2.5),
  transition: "border-radius 0.05s ease",
  transitionDelay: "0.28s",

  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: "rotate(180deg)",
  },

  "&.Mui-expanded": {
    transitionDelay: "0s",
    borderTopLeftRadius: theme.spacing(2.5),
    borderTopRightRadius: theme.spacing(2.5),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  "& .MuiAccordionSummary-content": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    margin: 0,
    [theme.breakpoints.down("sm")]: {},
  },
}));
