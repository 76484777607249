import { FC } from "react";
import { BrowserProvider, Contract } from "ethers";

import { useMetamask, Web3ContextType } from "@dzambalaorg/react-hooks-eth";
import { ContractFeatures, type IToken } from "@framework/types";
import { ListAction, ListActionVariant } from "@framework/styled";
import VestingSplitABI from "@framework/abis/json/ERC721Vesting/split.json";

interface ISplitTokenButtonProps {
  token: IToken;
  splitPercentage: number;
  className?: string;
  disabled?: boolean;
  variant?: ListActionVariant;
}

export const SplitTokenButton: FC<ISplitTokenButtonProps> = props => {
  const { className, disabled, variant, token, splitPercentage } = props;

  const metaSplit = useMetamask(async (web3Context: Web3ContextType) => {
    const rawProvider = await web3Context.connector!.getProvider();
    const provider = new BrowserProvider(rawProvider as any);
    const signer = await provider.getSigner();
    const { address } = token.template?.contract || {};

    if (!address) {
      throw new Error("unsupported token type");
    }

    const contract = new Contract(address, VestingSplitABI, signer);
    return contract.split(Number(token.tokenId), splitPercentage) as Promise<void>;
  });

  const handleSplit = () => {
    return metaSplit();
  };

  return (
    <ListAction
      onClick={handleSplit}
      message="pages.tokens.current.splitBtn"
      className={className}
      dataTestId="SplitTokenButton"
      disabled={disabled || token.template?.contract?.contractFeatures.includes(ContractFeatures.SOULBOUND)}
      variant={variant}
    />
  );
};
