import React, { ElementType, FC } from "react";
import { FormattedMessage } from "react-intl";

import { StyledBox } from "./styled";

interface IRulesDescriptionProps {
  data: Record<string, Record<string, ElementType>>;
}

export const RulesDescription: FC<IRulesDescriptionProps> = props => {
  const { data } = props;
  return (
    <StyledBox>
      {Object.entries(data).map(([key, value]) => (
        <FormattedMessage key={key} id={`pages.tokens.current.${key}`} tagName={value.tagName} />
      ))}
    </StyledBox>
  );
};
