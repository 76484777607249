import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IVestingBox } from "@framework/types";

import {
  AMOUNT_FIX_VALUE,
  BoxIcon,
  INFINITY_DISPLAYING,
  StyledContentSide,
  TokenTotalCount,
  Vesting,
} from "../../../../../../shared";
import { Root } from "../styled";
import { ReleaseBox } from "./release-box";

interface ICurrentBoxContent {
  selected: IVestingBox;
  vesting: Vesting;
  withdrawn: number;
}

export const CurrentBoxContent: FC<ICurrentBoxContent> = props => {
  const { selected, withdrawn, vesting } = props;

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const tokensCount = vesting.getContentAmount();
  const tokensCountTicker = vesting.getContentTicker();

  const tokensCountWithTicker = vesting.getTokensCountWithTicker(withdrawn, AMOUNT_FIX_VALUE);
  const tokenPriceWithTicker = vesting.getContentTokenPriceWithTicker(withdrawn, AMOUNT_FIX_VALUE);

  const boxPriceWithTicker = vesting.getBoxPriceWithTicker();
  const releasablePercentage = vesting.getReleasablePercentageAmount().toFixed(AMOUNT_FIX_VALUE);

  const limitDisplaying =
    Number(selected.template!.cap) > 0
      ? `${Number(selected.template!.cap) - Number(selected.template!.amount)}/${selected.template!.cap}`
      : INFINITY_DISPLAYING;

  return (
    <Root>
      <StyledContentSide
        container
        spacing={{ sm: 0, xs: 2 }}
        mt={!isSm ? 3 : 2}
        mb={4}
        size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
      >
        <TokenTotalCount
          imageUrl={selected.content!.components[0].template!.imageUrl}
          contentTokenTotalCount={`${tokensCountWithTicker}`}
          containerSize={!isSm ? 6 : 12}
        />
        <Grid2 size={{ sm: 3, xs: 6 }}>
          <Typography variant={"body_1"} color="custom.gray" component="p">
            {`${formatMessage({ id: "pages.vesting.currentBox.boxPrice" })}`}
          </Typography>
          <Typography variant={"subtitle_2"} color="custom.white" component="p">
            {boxPriceWithTicker}
          </Typography>
        </Grid2>

        <Grid2 size={{ sm: 3, xs: 6 }}>
          <Typography variant={"body_1"} color="custom.gray" component="p">
            {`${formatMessage({ id: "pages.vesting.currentBox.tokenPrice" })}`}
          </Typography>
          <Typography variant={"subtitle_2"} color="custom.white" component="p">
            {tokenPriceWithTicker}
          </Typography>
        </Grid2>

        {isSm && (
          <Grid2 size={{ sm: 3, xs: 6 }}>
            <Typography variant={"body_1"} color="custom.gray" component="p">
              {`${formatMessage({ id: "pages.vesting.boxes.tgeUnlock" })}`}
            </Typography>
            <Typography variant={"subtitle_3"} color="custom.white" component="p">
              {`${releasablePercentage}%`}
            </Typography>
          </Grid2>
        )}

        {isSm && (
          <Grid2 size={{ sm: 3, xs: 6 }}>
            <Typography variant={"body_1"} color="custom.gray" component="p">
              {`${formatMessage({ id: "pages.vesting.currentBox.boxesAvailable" })}`}
            </Typography>
            <Typography className="SubtitleIcon-box" variant={"subtitle_3"} color="custom.white" component="p">
              {limitDisplaying !== INFINITY_DISPLAYING && <BoxIcon />}
              {limitDisplaying}
            </Typography>
          </Grid2>
        )}
      </StyledContentSide>

      <StyledContentSide container size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <Grid2 size={!isSm ? 6 : 12}>
          <ReleaseBox
            tokensCount={tokensCount}
            tokensCountTicker={tokensCountTicker}
            duration={selected.duration}
            shape={selected.shape}
          />
        </Grid2>
        {!isSm && (
          <Grid2 size={3}>
            <Typography variant={"body_1"} color="custom.gray" component="p">
              {`${formatMessage({ id: "pages.vesting.boxes.tgeUnlock" })}`}
            </Typography>
            <Typography variant={"subtitle_3"} color="custom.white" component="p">
              {`${releasablePercentage}%`}
            </Typography>
          </Grid2>
        )}

        {!isSm && (
          <Grid2 size={3}>
            <Typography variant={"body_1"} color="custom.gray" component="p">
              {`${formatMessage({ id: "pages.vesting.currentBox.boxesAvailable" })}`}
            </Typography>
            <Typography className="SubtitleIcon-box" variant={"subtitle_3"} color="custom.white" component="p">
              {limitDisplaying !== INFINITY_DISPLAYING && <BoxIcon />}
              {limitDisplaying}
            </Typography>
          </Grid2>
        )}
      </StyledContentSide>
    </Root>
  );
};
