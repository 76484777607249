import { Grid2, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { StyledTokenVestingInfoBox } from "../styled";

interface ITokenVestingInfoProps {
  releasableAmount: string;
  withdrawnAmount: string;
}

export const TokenVestingInfo = (props: ITokenVestingInfoProps) => {
  const { releasableAmount, withdrawnAmount } = props;
  const { formatMessage } = useIntl();
  return (
    <Grid2 container spacing={{ lg: 8, md: 8, sm: 8, xs: 4 }}>
      <StyledTokenVestingInfoBox display="flex" flexDirection="column" gap={0.5}>
        <Typography variant={"body_1"} component="p">
          {formatMessage({ id: "pages.tokens.current.releasable" })}
        </Typography>
        <Typography variant={"subtitle_2"} component="p">
          {releasableAmount}
        </Typography>
      </StyledTokenVestingInfoBox>
      <StyledTokenVestingInfoBox display="flex" flexDirection="column" gap={0.5}>
        <Typography variant={"body_1"} component="p">
          {formatMessage({ id: "pages.tokens.current.withdrawn" })}
        </Typography>
        <Typography variant={"subtitle_2"} component="p">
          {withdrawnAmount}
        </Typography>
      </StyledTokenVestingInfoBox>
    </Grid2>
  );
};
