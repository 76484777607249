import React from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";

import { StyledBlockRoot } from "../../../../../../shared";

export const SplitBenefits = () => {
  return (
    <StyledBlockRoot>
      <Box className="BlockRoot-headerBox">
        <FormattedMessage id="pages.vesting.currentBox.splitBenefitsTitle" tagName="h4" />
      </Box>
      <FormattedMessage id="pages.vesting.currentBox.splitBenefitsDescription" tagName="p" />
    </StyledBlockRoot>
  );
};
