import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import type { IApplyVestingGeneral } from "@framework/types";

import { firstStepFormEmpty } from "../first-step-form/empty";
import { secondStepFormEmpty } from "../second-step-form/empty";
import { ISecondStepForm } from "../types";

interface IUseApplyVestingStore {
  firstStep: IApplyVestingGeneral;
  secondStep: ISecondStepForm;
  setFirstStep: (step: IApplyVestingGeneral) => void;
  setSecondStep: (step: ISecondStepForm) => void;
}

export const useApplyVestingStore = create<IUseApplyVestingStore>()(
  persist(
    set => ({
      firstStep: firstStepFormEmpty,
      secondStep: secondStepFormEmpty,
      setFirstStep: (step: IApplyVestingGeneral) =>
        set({
          firstStep: step,
        }),
      setSecondStep: (step: ISecondStepForm) =>
        set({
          secondStep: step,
        }),
    }),
    {
      name: "apply-vesting-form-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
