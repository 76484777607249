import { Grid2, styled } from "@mui/material";

import { contentSideFlex, setButtonStyles } from "../../../../shared";

export const gridItemsSizes = { xl: 6, lg: 6, md: 6, sm: 12, xs: 12 };

export const StyledRoot = styled(Grid2)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  marginTop: theme.spacing(4),

  "& h6": {
    width: "100%",
    color: "#000000",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "130%",
    margin: 0,
  },

  "& .OfferManagement-offerBox": {
    ...contentSideFlex,
    alignItems: "center",
    gap: theme.spacing(2),
  },

  "& .OfferManagement-offerBtn": {
    ...setButtonStyles(theme),
  },
}));
