import { Box, styled } from "@mui/material";

import { setButtonStyles } from "../../../../../../shared";

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(4),
  paddingInline: theme.spacing(2),

  "& #TextField-Box": {
    "& .MuiFormHelperText-root": {
      ...theme.typography.caption_1,
    },

    "& .MuiBadge-badge": {
      padding: 0,
      right: -3,
    },

    "& .MuiFormControl-root": {
      margin: 0,
    },
  },

  ".MuiGrid-container": {
    justifyContent: "center",

    "& .MuiButtonBase-root": {
      width: "80%",
      alignSelf: "center",
      ...setButtonStyles(theme),
    },
  },

  ".MuiInputBase-root": {
    borderRadius: theme.spacing(1.25),
    border: `1px solid ${theme.palette.custom.card_1.stroke}`,

    ".MuiOutlinedInput-input": {
      color: theme.palette.custom.white,
      ...theme.typography.body_1,

      "&:focused": {
        outline: "none",
      },
    },
  },
})) as typeof Box;
