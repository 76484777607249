import React, { ChangeEvent } from "react";
import { Grid2 } from "@mui/material";
import { useIntl } from "react-intl";

import { CustomInput } from "../custom-input";
import { VendorAllowanceSide } from "./types";

interface IVendorAllowanceProps {
  onInputChange: (side: VendorAllowanceSide, event: ChangeEvent<HTMLInputElement>) => void;
  vendorAllowanceValue: Record<VendorAllowanceSide, string>;
}

export const VendorAllowance = (props: IVendorAllowanceProps) => {
  const { onInputChange, vendorAllowanceValue } = props;

  const { formatMessage } = useIntl();

  return (
    <Grid2 container size={12} display="flex" alignItems="center" spacing={2.5}>
      <Grid2 size={6}>
        <CustomInput
          placeholder={"0%"}
          label={formatMessage(
            { id: "pages.vesting.currentBox.calculationSlides.vendorAllowanceLeft" },
            { value: vendorAllowanceValue.left },
          )}
          onChange={event => onInputChange("left", event as ChangeEvent<HTMLInputElement>)}
        />
      </Grid2>
      <Grid2 size={6}>
        <CustomInput
          placeholder={"0%"}
          label={formatMessage(
            { id: "pages.vesting.currentBox.calculationSlides.vendorAllowanceRight" },
            { value: vendorAllowanceValue.right },
          )}
          onChange={event => onInputChange("right", event as ChangeEvent<HTMLInputElement>)}
        />
      </Grid2>
    </Grid2>
  );
};
