import { css } from "@emotion/react";

export const globalStyles = css`
  html,
  body,
  #root {
    background: #000618;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .MuiAutocomplete-paper {
    margin-top: 8px;
    border-radius: 10px !important;
    width: 100%;
  }

  .MuiAutocomplete-listbox {
    border-radius: 10px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #000000;
    padding-top: 10px !important;

    .MuiAutocomplete-option {
      padding-inline: 20px !important;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .MuiAutocomplete-option[aria-selected="true"],
    .MuiAutocomplete-option.Mui-focused {
      background-color: transparent !important;
    }
  }
`;
