import React from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";

import { RequiredStarIcon } from "../../../../../../shared";

interface IWithRequiredTitleProps {
  titleId: string;
  required?: boolean;
}

export const WithRequiredTitle = (props: IWithRequiredTitleProps) => {
  const { titleId, required = true } = props;
  return (
    <Typography component="h5">
      <FormattedMessage
        id={titleId}
        values={{
          span: chunks => (
            <span>
              {chunks}
              {required && <RequiredStarIcon width={12} height={12} />}
            </span>
          ),
        }}
      />
    </Typography>
  );
};
