import React, { FC } from "react";
import { SliderProps, Typography } from "@mui/material";

import { PrettoSlider } from "./styled";
import { StyledBlockRoot } from "../styled";

interface ISliderProps extends SliderProps {
  title: string;
  description?: string;
}

export const CustomSlider: FC<ISliderProps> = props => {
  const { title, description, ...restProps } = props;
  return (
    <StyledBlockRoot mb={0} gap={0}>
      <Typography variant="body_1" color="custom.white" mb={3}>
        {title}
      </Typography>
      <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" {...restProps} />
      <Typography variant="body2" component="span">
        {description}
      </Typography>
    </StyledBlockRoot>
  );
};
