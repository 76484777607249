import { Grid2, styled } from "@mui/material";

import { setButtonStyles } from "../../styles";

export const StyledSlidesBox = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),

  "& .CurrentToken-splitBtn": {
    ...setButtonStyles(theme),
  },
}));

export const StyledCalculationBox = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  ".Calculation-headerItem": {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    padding: theme.spacing(1, 0, 1, 5),

    [theme.breakpoints.down(520)]: {
      padding: theme.spacing(1, 0, 1, 2),
    },

    ".MuiTypography-caption_2": {
      ...theme.typography.caption_2,
      color: theme.palette.custom.white,
    },
  },

  ".Calculation-headerItem.index_0": {
    borderRight: `1px solid ${theme.palette.custom.stroke}`,
  },

  ".Calculation-values": {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.custom.stroke}`,

    [theme.breakpoints.down(520)]: {
      paddingLeft: theme.spacing(2),
    },

    ".MuiTypography-caption_1": {
      color: theme.palette.custom.white,
    },
  },

  ".Calculation-keys": {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.custom.stroke}`,
    borderRight: `1px solid ${theme.palette.custom.stroke}`,

    [theme.breakpoints.down(520)]: {
      paddingRight: theme.spacing(1),
    },

    ".MuiTypography-caption_1": {
      color: theme.palette.custom.gray,
    },
  },
}));
