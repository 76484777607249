import React, { SVGProps } from "react";

export const UnlockedIcon = (props: SVGProps<any>) => {
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.999 22.0002H6.99902C2.99902 22.0002 1.99902 21.0002 1.99902 17.0002V15.0002C1.99902 11.0002 2.99902 10.0002 6.99902 10.0002H16.999C20.999 10.0002 21.999 11.0002 21.999 15.0002V17.0002C21.999 21.0002 20.999 22.0002 16.999 22.0002Z"
        stroke={props.color || "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9.99976V7.99976C6 4.68976 7 1.99976 12 1.99976C16.5 1.99976 18 3.99976 18 6.99976"
        stroke={props.color || "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.999 18.5C13.3797 18.5 14.499 17.3807 14.499 16C14.499 14.6193 13.3797 13.5 11.999 13.5C10.6183 13.5 9.49902 14.6193 9.49902 16C9.49902 17.3807 10.6183 18.5 11.999 18.5Z"
        stroke={props.color || "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
