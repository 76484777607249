import { Link, LinkProps, styled } from "@mui/material";

export interface IStyledLinkProps {
  isActive?: boolean;
}
export const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== "isActive",
})<LinkProps & IStyledLinkProps>(({ theme, isActive }) => ({
  ...theme.typography.caption_2,
  textDecoration: "none",
  cursor: "pointer",
  color: isActive ? "#3656E0" : "#F5F5F5",
  "&:hover": {
    color: "#3656E0",
    "& #fill": {
      fill: "#4BD4FF",
    },
    "& #stroke": {
      stroke: "#4BD4FF",
    },
  },
})) as any;
