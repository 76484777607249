import { SxProps, Theme } from "@mui/material";

import { StylesScheme } from "../types";
import { sizeDecreaseCalc } from "../utils";
import { setFiltersStyles } from "./filters";
import { setButtonStyles } from "./button";

export { setFiltersStyles, setButtonStyles };

export const buttonStyles: SxProps = {
  color: "#000000",
  textAlign: "center",
  fontSize: "14px",
  fontWeight: 500,
  padding: "12px 8px 12px 8px",
  borderRadius: "8px",
  border: "1px solid rgba(79, 121, 134, 0.4)",
  textTransform: "none",

  "&:hover": {
    background: "rgba(79, 121, 134, 0.4)",
  },
};

export const descriptionStyles = (theme: Theme): SxProps => ({
  margin: 0,
  color: "#000000",
  fontSize: theme.spacing(1.75),
  fontWeight: 400,
  lineHeight: "140%" /* 19.6px */,
});

export const pageHeader = (): StylesScheme => ({
  fontSize: sizeDecreaseCalc(32, 18),
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "-0.12px",
  color: "#000000",
});
