import { Breadcrumbs, styled } from "@mui/material";

export const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  "& .MuiBreadcrumbs-ol": {
    ...theme.typography.caption_1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "& > :nth-of-type(1)": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    "& a, p": {
      textDecoration: "none",
    },
    "& a": {
      margin: 0,
      color: theme.palette.custom.bodyText,
      cursor: "pointer",
    },
    "& .MuiBreadcrumbs-li > p": {
      ...theme.typography.caption_1,
      color: theme.palette.custom.white,
      margin: 0,
    },

    "& .MuiBreadcrumbs-separator": {
      transform: "rotate(-90deg)",
    },
  },
}));
