import { Box, styled } from "@mui/material";

import { contentSideFlex } from "../styled";

export const StyledBoxWithTitleRoot = styled(Box)(({ theme }) => ({
  width: "100%",
  ...contentSideFlex,
  alignItems: "flex-start",
  gap: theme.spacing(1.25),
  borderRadius: theme.spacing(2),

  "& h4": {
    ...theme.typography.button_1,
    color: theme.palette.custom.white,
    margin: 0,
    width: "100%",
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.custom.stroke}`,
  },
}));
