import { Box, styled } from "@mui/material";

import { contentSideFlex } from "../../../../../../../shared";

export const StyledReleaseBox = styled(Box)(({ theme }) => ({
  ...contentSideFlex,
  alignItems: "flex-start",
  justifyContent: "flex-start",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },

  "& .MuiTypography-body_1": {
    color: theme.palette.custom.gray,
  },

  "& .MuiTypography-subtitle_3": {
    color: theme.palette.custom.white,
  },
})) as typeof Box;

export const StyledReleaseBoxSwitcher = styled(Box)(({ theme }) => ({
  ...contentSideFlex,
  flexDirection: "row",
  justifyContent: "flex-start",

  "& .MuiTypography-caption_1": {
    color: theme.palette.custom.white,
  },

  "& .MuiIconButton-root": {
    padding: 0,

    '&[data-side="right"]': {
      transform: "rotate(90deg)",
    },

    '&[data-side="left"]': {
      transform: "rotate(-90deg)",
    },

    "& path": {
      stroke: theme.palette.custom.main.default,
    },

    "&:hover": {
      background: "none",
    },
  },
})) as typeof Box;
