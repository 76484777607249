import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(Box)(({ theme }) => {
  return {
    position: "absolute",
    top: 16,
    right: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 2),
    borderRadius: 100,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.custom.card_2.background,
  };
});
