import { Card, CardActionArea, CardActions, Grid2, styled } from "@mui/material";

export const Root = styled(Card)(({ theme }) => ({
  position: "relative",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: theme.palette.custom.card_2.background,
  borderRadius: theme.spacing(2.5),
  textDecoration: "none",
  cursor: "pointer",

  "& .MuiCardActionArea-root": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),

    "& .MuiCardHeader-root": {
      padding: 0,
    },
  },
})) as typeof Card;

export const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(1),
  overflow: "hidden",
  position: "relative",
})) as typeof CardActionArea;

export const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  padding: theme.spacing(2.5),

  "& .MuiGrid2-root": {
    margin: 0,
  },

  "& .MuiTypography-subtitle_2": {
    ...theme.typography.subtitle_2,
    color: theme.palette.custom.white,
    marginBottom: theme.spacing(3),
  },
})) as typeof CardActions;

export const StyledCardActionsInfo = styled(Grid2)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& .CardTopInfoItem-title": {
    margin: 0,
    ...theme.typography.body_1,
    color: theme.palette.custom.gray,
  },

  "& .CardTopInfoItem-subtitle": {
    margin: 0,
    ...theme.typography.subtitle_1,
    color: theme.palette.custom.white,
  },
}));
