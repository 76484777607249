import { Contract, FixedNumber, JsonRpcSigner } from "ethers";
import { useReadContracts } from "wagmi";

import ReleasableAbi from "@framework/abis/json/ERC721Vesting/releasable.json";
import WithdrawnAmountsAbi from "@framework/abis/json/ERC721Vesting/getWithdrawnAmounts.json";

import { recursivelyDecodeResult } from "../utils";

export const getWithdrawnAmounts = async (tokenId: string, tokenAddress: string, signer: JsonRpcSigner) => {
  if (!tokenId || !tokenAddress) {
    return Promise.reject();
  }

  const contract = new Contract(tokenAddress, WithdrawnAmountsAbi, signer);
  const result = recursivelyDecodeResult(await contract.releasable(tokenId));
  return result[0].amount;
};

export const getReleasableAmounts = async (tokenId: string, tokenAddress: string, signer: JsonRpcSigner) => {
  if (!tokenId || !tokenAddress) {
    return Promise.reject();
  }

  const contract = new Contract(tokenAddress, ReleasableAbi, signer);
  const result = recursivelyDecodeResult(await contract.releasable(tokenId));
  return result[0].amount;
};

interface IVestingContractInfoData {
  amount: bigint;
  token: string;
  tokenId: bigint;
  tokenType: number;
}

const setVestingContractInfoData = (data: any, index: number) => {
  if (!data) return null;
  if (Array.isArray(data) && data[index]) {
    if (Array.isArray(data[index].result)) {
      return data[index].result[0] as IVestingContractInfoData;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const useVestingContractInfo = (tokenId: string, tokenAddress: string) => {
  const vestingContractInfo = useReadContracts({
    query: {
      refetchInterval: 100000,
    },
    contracts: [
      {
        address: tokenAddress as `0x${string}`,
        abi: ReleasableAbi,
        functionName: "releasable",
        args: [tokenId],
      },
      {
        address: tokenAddress as `0x${string}`,
        abi: WithdrawnAmountsAbi,
        functionName: "getWithdrawnAmounts",
        args: [tokenId],
      },
    ],
  });
  const releasableData: IVestingContractInfoData | null = setVestingContractInfoData(vestingContractInfo.data, 0);
  const withdrawnData: IVestingContractInfoData | null = setVestingContractInfoData(vestingContractInfo.data, 1);

  return {
    releasable: releasableData ? Number(FixedNumber.fromValue(releasableData.amount, 18)._value) : 0,
    withdrawn: withdrawnData ? Number(FixedNumber.fromValue(withdrawnData.amount, 18)._value) : 0,
  };
};
