import React from "react";
import { Box, Grid2, Skeleton, Typography } from "@mui/material";

export const VestingSkeleton = () => {
  return (
    <Box sx={{ p: 2, color: "#fff", maxWidth: "100%" }}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: "1.1rem", mb: 5 }}>
        <Skeleton animation="wave" width="150px" sx={{ bgcolor: "custom.gray" }} />
      </Typography>

      <Box sx={{ my: 2, mb: 4 }}>
        <Skeleton animation="wave" height={24} width={200} sx={{ bgcolor: "custom.gray", mb: 1 }} />
        <Skeleton animation="wave" height={1} sx={{ bgcolor: "custom.gray" }} />
      </Box>

      <Grid2 container spacing={1} alignItems="center" mb={10}>
        {/* Token Icon and Value */}
        <Grid2 size={{ md: 3, sm: 4, xs: 12 }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" alignItems="center" gap={1}>
              <Skeleton animation="wave" variant="circular" width={36} height={36} sx={{ bgcolor: "custom.gray" }} />
              <Skeleton animation="wave" width={162} height={48} sx={{ bgcolor: "custom.gray" }} />
            </Box>
            <Skeleton animation="wave" width={206} sx={{ fontSize: "0.8rem", bgcolor: "custom.gray" }} />
          </Box>
        </Grid2>

        <Grid2 size={{ md: 4, sm: 5, xs: 12 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={6}>
              <Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
                <Skeleton animation="wave" width="100px" sx={{ bgcolor: "custom.gray" }} />
              </Typography>
              <Skeleton width="50px" sx={{ bgcolor: "custom.gray" }} />
            </Grid2>
            <Grid2 size={6}>
              <Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
                <Skeleton animation="wave" width="80px" sx={{ bgcolor: "custom.gray" }} />
              </Typography>
              <Skeleton width="50px" sx={{ bgcolor: "custom.gray" }} />
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 size={{ md: 1, sm: 3, xs: 12 }} display="flex" justifyContent="flex-end">
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={180}
            height={40}
            sx={{ borderRadius: 16, bgcolor: "custom.gray" }}
          />
        </Grid2>
      </Grid2>

      <Box sx={{ my: 2, mb: 3 }}>
        <Skeleton animation="wave" height={24} width={200} sx={{ bgcolor: "custom.gray", mb: 1 }} />
        <Skeleton animation="wave" height={1} sx={{ bgcolor: "custom.gray" }} />
      </Box>

      <Typography variant="h6" gutterBottom sx={{ fontSize: "1.1rem", mb: 1, width: "100%" }}>
        <Skeleton animation="wave" height={58} sx={{ bgcolor: "custom.gray", borderRadius: 3 }} />
      </Typography>

      <Grid2 container spacing={2.5} mt={4}>
        <Grid2 size={{ md: 6, sm: 12, xs: 12 }}>
          <Skeleton animation="wave" width="120px" sx={{ bgcolor: "custom.gray" }} />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={36}
            sx={{ borderRadius: 2, bgcolor: "custom.gray" }}
          />
          <Box width="100%" display="flex" justifyContent="center">
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={180}
              height={40}
              sx={{ borderRadius: 18, bgcolor: "custom.gray", mt: 2 }}
            />
          </Box>
        </Grid2>

        <Grid2 container size={{ md: 4, sm: 12, xs: 12 }}>
          <Grid2 container spacing={1} size={4} display="flex" flexDirection="column">
            {[...Array(3)].map((_, index) => (
              <Grid2 size={12} display="flex" alignItems="center" gap={1.5} key={index}>
                <Skeleton animation="wave" width={95} sx={{ bgcolor: "custom.gray" }} />
                <Skeleton animation="wave" width={60} sx={{ bgcolor: "custom.gray" }} />
              </Grid2>
            ))}
          </Grid2>
          <Grid2 container spacing={1} size={4} flexDirection="column">
            {[...Array(2)].map((_, index) => (
              <Grid2 size={12} display="flex" alignItems="center" gap={1.5} key={index}>
                <Skeleton animation="wave" width={95} sx={{ bgcolor: "custom.gray" }} />
                <Skeleton animation="wave" width={60} sx={{ bgcolor: "custom.gray" }} />
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};
