import { InputBase, styled } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  marginLeft: "0px !important",

  "label + &": {
    marginTop: theme.spacing(3),
  },

  "& .MuiInputBase-input": {
    ...theme.typography.body_1,
    color: theme.palette.custom.gray,
    borderRadius: theme.spacing(1.25),
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid",
    borderColor: "#cdcfd3",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
  },
}));
