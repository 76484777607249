import { Dialog, styled } from "@mui/material";

import { buttonStyles } from "../../styles";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  paddingInline: theme.spacing(2),

  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    rowGap: theme.spacing(2),
    margin: 0,
    padding: theme.spacing(3.75, 2, 3.75, 3.75),
    backdropFilter: "none",
    minWidth: "320px",
    background: theme.palette.custom.card_2.background,
    color: theme.palette.custom.white,
    borderRadius: theme.spacing(2.5),
  },

  "& p": {
    color: theme.palette.custom.bodyText,
    ...theme.typography.body_1,

    "& span": {
      color: theme.palette.custom.white,
    },
  },

  "& h4": {
    color: theme.palette.custom.white,
    ...theme.typography.subtitle_2,
  },

  "& .MuiTypography-h6, .MuiIconButton-root, .MuiDialogContent-root": {
    padding: 0,
  },

  "& .MuiTypography-h6": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...theme.typography.subtitle_2,
  },

  "& .MuiDialogContent-root": {
    width: "100%",
  },

  "& .MuiButton-root": {
    ...buttonStyles,
    width: "100%",
    padding: theme.spacing(1, 3),

    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    "& .MuiButton-startIcon svg": {
      fontSize: theme.spacing(3),
      marginRight: 2,
      marginLeft: -4,
    },
  },
}));
