import React, { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid2, Typography } from "@mui/material";

import { IVestingBox } from "@framework/types";

import { VestingBox } from "../../../../../../../shared";
import { Root } from "./styled";

interface ITimelineDatesProps {
  vestingBox: IVestingBox;
  startDate?: string | null;
}

export const TimelineDates: FC<ITimelineDatesProps> = props => {
  const { vestingBox, startDate } = props;

  const { formatMessage } = useIntl();

  const vesting = useMemo(() => {
    return new VestingBox(vestingBox, vestingBox.template!.price!);
  }, [vestingBox]);

  const { tge, ido, end, cliffEnds, duration, unlockPeriod } = vesting.getBoxInfo(startDate, true);

  const timelineDataLeft = Object.entries({ ido, tge, unlockPeriod }).map(([key, value]) => {
    if (key === "unlockPeriod") {
      return {
        id: `pages.vesting.currentBox.${key}`,
        value: `${formatMessage({ id: "pages.vesting.currentBox.unlockPeriodSubtext" }, { value })}`,
      };
    }

    return {
      id: `pages.vesting.currentBox.${key}`,
      value,
    };
  });

  const timelineDataRight = Object.entries({ cliffEnds, end, duration }).map(([key, value]) => {
    if (key === "cliffEnds" && !vestingBox.cliff) return null;

    return {
      id: `pages.vesting.currentBox.${key}`,
      value,
    };
  });

  const timelineDataLeftKeys = timelineDataLeft.map(d => d.id);
  const timelineDataLeftValues = timelineDataLeft.map(d => d.value);

  const timelineDataRightKeys = timelineDataRight.filter(d => d !== null).map(k => k.id);
  const timelineDataRightValues = timelineDataRight.filter(d => d !== null).map(k => k.value);

  return (
    <Root container spacing={2}>
      <Grid2 display="flex" gap={1.5}>
        <Grid2>
          {timelineDataLeftKeys.map(key => {
            return (
              <Grid2 key={key} justifyContent="flex-start">
                <FormattedMessage id={key} tagName="span" />
              </Grid2>
            );
          })}
        </Grid2>
        <Grid2>
          {timelineDataLeftValues.map((value, index) => {
            return (
              <Grid2 key={`${value}_${index}`} justifyContent="flex-start">
                <Typography variant="caption_1" component="span">
                  {value}
                </Typography>
              </Grid2>
            );
          })}
        </Grid2>
      </Grid2>

      <Grid2 display="flex" gap={1.5}>
        <Grid2>
          {timelineDataRightKeys.map(key => {
            return (
              <Grid2 key={key} justifyContent="flex-start">
                <FormattedMessage id={key} tagName="span" />
              </Grid2>
            );
          })}
        </Grid2>
        <Grid2>
          {timelineDataRightValues.map((value, index) => {
            return (
              <Grid2 key={`${value}_${index}`} justifyContent="flex-start">
                <Typography variant="caption_1" component="span">
                  {value}
                </Typography>
              </Grid2>
            );
          })}
        </Grid2>
      </Grid2>
    </Root>
  );
};
