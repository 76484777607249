import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

import { PATHS } from "../../../../../../shared";
import { SimpleButton } from "../../../../../../components/buttons/simple";

export const ApplyButton = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <SimpleButton component={Link} to={PATHS.VestingApplication} buttonVariant={isMd ? "link" : "primary"}>
      {formatMessage({ id: "pages.vesting.boxes.applyForVesting" })}
    </SimpleButton>
  );
};
