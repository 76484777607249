import { FC, useMemo } from "react";
import { useIntl } from "react-intl";

import { ListActionVariant } from "@framework/styled";

import {
  AssetsDashboardTabs,
  BoxesAssetVariant,
  BoxWithTitle,
  ContentMode,
  CustomAccordion,
  Plot,
  SplitCalculation,
  StyledCurrentRoot,
  useVestingContractInfo,
  Vesting,
} from "../../../shared";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import { VestingBoxReleaseButton } from "../../../components/buttons/release";
import { BoxContent } from "./content";
import { RulesDescription } from "./rules-description";
import { StyledAccordionTitle, StyledContentWrapper } from "./styled";
import { OfferManagement } from "./offer-management";
import { ISelected } from "./useFetchToken";
import { createOfferDescription, splitRulesDescription } from "./schemas";

interface ICurrentBoxProps {
  selected: ISelected;
  variant?: BoxesAssetVariant;
}

export const Box: FC<ICurrentBoxProps> = props => {
  const { selected, variant = AssetsDashboardTabs.boxes } = props;
  const { template, tokenId } = selected;

  const { releasable, withdrawn } = useVestingContractInfo(tokenId, template.contract!.address);

  const { formatMessage } = useIntl();

  const imageUrl = template.box.content!.components[0].template!.imageUrl || "";

  const vesting = useMemo(() => {
    return new Vesting(template.box, template.price!, releasable, withdrawn);
  }, [releasable, withdrawn]);

  return (
    <StyledCurrentRoot>
      <Breadcrumbs
        paths={[`assets-dashboard.${variant}`, "tokens.current"]}
        data={[{}, { title: template.box.title }]}
      />

      <StyledContentWrapper>
        <BoxContent
          imageUrl={imageUrl}
          vesting={vesting}
          contentTokenAddress={selected.template.box.content?.components[0].contract!.address}
        >
          <VestingBoxReleaseButton
            className="CurrentToken-releaseBtn"
            token={selected}
            variant={ListActionVariant.button}
          />
        </BoxContent>

        <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.offerManagementTitle" })}>
          <CustomAccordion
            summaryStyles={{ py: 2, px: 3.75, mt: 3 }}
            title={
              <StyledAccordionTitle>
                {formatMessage({ id: "pages.tokens.current.offerRulesTitle" })}
              </StyledAccordionTitle>
            }
            description={<RulesDescription data={createOfferDescription} />}
          />
          <OfferManagement
            priceAsset={template.price!}
            contentAsset={template.box.content!}
            template={template}
            boxId={template.box.id}
            tokenId={tokenId}
            vesting={vesting}
          />
        </BoxWithTitle>

        <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.splitManagementTitle" })}>
          <CustomAccordion
            summaryStyles={{ py: 2, px: 3.75, mt: 3 }}
            title={
              <StyledAccordionTitle>
                {formatMessage({ id: "pages.tokens.current.splitRulesTitle" })}
              </StyledAccordionTitle>
            }
            description={<RulesDescription data={splitRulesDescription} />}
          />
          <SplitCalculation
            mode={ContentMode.ASSET}
            box={template.box}
            token={selected}
            releasablePercentage={vesting.getReleasablePercentageAmount()}
            withdrawnPercentage={vesting.getWithdrawnPercentageAmount()}
          />
        </BoxWithTitle>

        <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.tokenDynamicsTitle" })}>
          <Plot
            vestingBox={template.box}
            price={template.price!}
            startTimestamp={new Date(template.createdAt)}
            height={400}
            startDirection="column"
            infoPosition="top"
          />
        </BoxWithTitle>
      </StyledContentWrapper>
    </StyledCurrentRoot>
  );
};
