import { IAsset, IVestingBox } from "@framework/types";
import { formatItem } from "@framework/exchange";

import { formatDateFromSpecialValue, getPeriodFromSecondsInDays, secondFromUnixConverter } from "./date";
import { AMOUNT_FIX_VALUE, TOKEN_DISPLAYING_IN_CARD_FIX_VALUE, TOKEN_PERCENTAGE_FIX_VALUE } from "../constants";

export type TBoxContent = {
  content: IAsset;
  price: IAsset;
  cliff: number;
  afterCliffBasisPoints: number;
  duration: number;
  period: number;
};

export class VestingBoxAmounts {
  protected price: IAsset;
  protected content: IAsset;

  constructor(price: IAsset, content: IAsset) {
    this.price = price;
    this.content = content;
  }

  public getPriceTicker() {
    const priceWithTicker = formatItem(this.price);
    return priceWithTicker.split(" ")[0];
  }

  public getPriceAmount() {
    const priceWithTicker = formatItem(this.price);
    return Number(priceWithTicker.split(" ")[1]);
  }

  public getContentTicker() {
    const contentWithTicker = formatItem(this.content);
    return contentWithTicker.split(" ")[0];
  }

  public getContentAmount(withdrawn?: number) {
    const contentWithTicker = formatItem(this.content);
    const contentAmount = Number(contentWithTicker.split(" ")[1]);
    return withdrawn ? contentAmount - withdrawn : contentAmount;
  }

  public getContentDisplaying() {
    return this.getContentAmount().toFixed(TOKEN_DISPLAYING_IN_CARD_FIX_VALUE);
  }
}

export class VestingBox extends VestingBoxAmounts {
  private boxContent: TBoxContent;

  constructor(box: IVestingBox, price: IAsset) {
    super(price, box.content!);
    this.boxContent = {
      price,
      content: box.content!,
      cliff: box.cliff,
      afterCliffBasisPoints: box.afterCliffBasisPoints,
      duration: box.duration,
      period: box.period,
    };
  }

  public getContentTokenPrice(withdrawn = 0) {
    const priceAmount = this.getPriceAmount();
    const contentAmount = this.getContentAmount(withdrawn);

    return priceAmount / contentAmount;
  }

  public getContentTokenPriceWithTicker(withdrawn = 0, fixedValue = TOKEN_DISPLAYING_IN_CARD_FIX_VALUE) {
    const priceTicker = this.getPriceTicker();
    const contentTokenPrice = this.getContentTokenPrice(withdrawn);

    return `${priceTicker} ${contentTokenPrice.toFixed(fixedValue)}`;
  }

  public getBoxPriceWithTicker(fixedValue?: number) {
    const priceTicker = this.getPriceTicker();
    const priceAmount = this.getPriceAmount();
    return `${priceTicker} ${priceAmount.toFixed(fixedValue || AMOUNT_FIX_VALUE)}`;
  }

  public getTokensCountWithTicker(withdrawnAmount?: number, fixedValue = TOKEN_DISPLAYING_IN_CARD_FIX_VALUE) {
    const contentTicker = this.getContentTicker();
    const contentAmount = this.getContentAmount();
    const resultContentAmount = withdrawnAmount ? contentAmount - withdrawnAmount : contentAmount;
    return `${contentTicker} ${resultContentAmount.toFixed(fixedValue)}`;
  }

  public getBoxInfo(startDate?: string | null, withTime = false) {
    const startTimestamp = startDate || new Date().toISOString();
    const startTimestampInSeconds = secondFromUnixConverter(new Date(startTimestamp));
    const start = Number(startTimestampInSeconds) + this.boxContent.cliff;
    const end = Number(startTimestampInSeconds) + this.boxContent.duration + this.boxContent.cliff;
    const cliffEnd = Number(startTimestampInSeconds) + this.boxContent.cliff;
    const duration = getPeriodFromSecondsInDays(this.boxContent.duration + this.boxContent.cliff);
    const unlockPeriod = getPeriodFromSecondsInDays(this.boxContent.period);

    return {
      duration,
      tge: formatDateFromSpecialValue(start, withTime),
      ido: formatDateFromSpecialValue(startTimestampInSeconds, withTime),
      cliffEnds: formatDateFromSpecialValue(cliffEnd, withTime),
      end: formatDateFromSpecialValue(end, withTime),
      unlockPeriod,
    };
  }

  public getVestingData() {
    const tokenPrice = this.getContentTokenPriceWithTicker();
    const boxPrice = this.getBoxPriceWithTicker();
    const tokensCount = this.getTokensCountWithTicker();
    const boxInfo = this.getBoxInfo();

    return {
      tokenPrice,
      boxPrice,
      tokensCount,
      ...boxInfo,
    };
  }
}

export class Vesting extends VestingBox {
  protected releasableAmount: number;
  protected withdrawnAmount: number;

  constructor(box: IVestingBox, price: IAsset, releasableAmount: number, withdrawnAmount: number) {
    super(box, price);
    this.releasableAmount = releasableAmount;
    this.withdrawnAmount = withdrawnAmount;
  }

  public getReleasablePercentageAmount() {
    const tokensCount = this.getContentAmount();
    return tokensCount - this.withdrawnAmount
      ? (this.releasableAmount * 100) / (tokensCount - this.withdrawnAmount)
      : 0;
  }

  public getReleasablePercentageDisplaying() {
    const releasableAmount = this.getReleasablePercentageAmount();
    return releasableAmount.toFixed(AMOUNT_FIX_VALUE);
  }

  public getWithdrawnPercentageAmount() {
    const tokensCount = this.getContentAmount();
    return (this.withdrawnAmount * 100) / tokensCount;
  }

  public getWithdrawnPercentageDisplaying() {
    const withdrawnAmount = this.getWithdrawnPercentageAmount();
    return withdrawnAmount.toFixed(AMOUNT_FIX_VALUE);
  }

  public getRemainingTokensAmount() {
    const tokensCount = this.getContentAmount();
    return tokensCount - this.withdrawnAmount;
  }

  public getRemainingTokensDisplaying() {
    return this.getRemainingTokensAmount().toFixed(TOKEN_DISPLAYING_IN_CARD_FIX_VALUE);
  }

  public getRemainingTokensPercentageAmount() {
    const tokensCount = this.getContentAmount();
    return ((tokensCount - this.withdrawnAmount) * 100) / tokensCount;
  }

  public getRemainingTokensPercentageDisplaying() {
    const remainingTokensPercentage = this.getRemainingTokensPercentageAmount();
    return remainingTokensPercentage.toFixed(TOKEN_PERCENTAGE_FIX_VALUE);
  }

  public getReleasableAmount() {
    return this.releasableAmount;
  }

  public getReleasableDisplaying() {
    return this.releasableAmount.toFixed(TOKEN_PERCENTAGE_FIX_VALUE);
  }

  public getWithdrawnAmount() {
    return this.withdrawnAmount;
  }

  public getWithdrawnDisplaying() {
    return this.withdrawnAmount.toFixed(TOKEN_PERCENTAGE_FIX_VALUE);
  }

  public getTvl() {
    const contentAmount = this.getContentAmount();
    return contentAmount - this.withdrawnAmount;
  }
}
