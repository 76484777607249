import { VestingBoxCategory } from "@framework/types";

import { IVestingOfferResponse } from "../types";

export const getBackgroundImg = (category: VestingBoxCategory) => {
  switch (category) {
    case VestingBoxCategory.PROMISING:
      return "/files/new/bg_img_4.png";
    case VestingBoxCategory.SUCCESSFUL:
      return "/files/new/bg_img_5.png";
    case VestingBoxCategory.TRENDING:
      return "/files/new/bg_img_1.png";
    case VestingBoxCategory.MEME:
      return "/files/new/bg_img_3.png";
    default:
      return "/files/new/bg_img_1.png";
  }
};

export const isOfferGuard = (obj: any): obj is IVestingOfferResponse => {
  return "box" in obj && "owner" in obj && "templateId" in obj && "tokenId" in obj;
};

export const setTokenTitle = (title: string, tokenId: string) => {
  return `${title} #${tokenId}`;
};
