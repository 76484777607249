import React, { FC } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

import { formatUnitsR } from "@framework/exchange";
import { IOfferInOverviewDto } from "@framework/types";

import {
  StyledGridTable,
  StyledGridTableHeader,
  StyledTableRow,
  TOKEN_DISPLAYING_IN_CARD_FIX_VALUE,
} from "../../../../../shared";
import { offersOverviewTableHeaderSchema } from "../schemas";
import { setOverviewHeaderStyles } from "../styled";

interface IOffersOverviewCurrenciesProps {
  offers: Array<IOfferInOverviewDto>;
}

const amountFormatter = (amount: string) => {
  return Number(formatUnitsR(BigInt(amount), "18")).toFixed(TOKEN_DISPLAYING_IN_CARD_FIX_VALUE);
};

export const OffersOverviewCurrencies: FC<IOffersOverviewCurrenciesProps> = props => {
  const { offers } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledGridTable isInner hasBorder={false} mt={!isMd ? 4 : 3}>
      {!isMd && (
        <StyledGridTableHeader
          data={offersOverviewTableHeaderSchema}
          sx={setOverviewHeaderStyles(theme)}
          isMd={isMd}
          spacing={1}
        />
      )}
      {offers.map(offer => {
        const displayingRow = [
          offer.symbol,
          offer.totalOffers,
          amountFormatter(offer.remainingToSell),
          amountFormatter(offer.potentialRevenue),
          amountFormatter(offer.soldAmount),
          amountFormatter(offer.revenue),
        ];
        return (
          <StyledTableRow
            isInner
            key={offer.symbol}
            data={displayingRow}
            headerData={offersOverviewTableHeaderSchema}
            isMd={isMd}
            spacing={1}
          />
        );
      })}
    </StyledGridTable>
  );
};
