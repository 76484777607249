import { createContext, FC, PropsWithChildren, useContext, useState } from "react";

import type { IApplyVestingGeneral } from "@framework/types";

export interface IStepperContext {
  activeStep: number;
  firstStepData: IApplyVestingGeneral;
  handleNext: (step: StepperActiveSteps) => void;
  setFirstStepData: (data: IApplyVestingGeneral) => void;
}

export enum StepperActiveSteps {
  FIRST,
  SECOND,
  THIRD,
}

const StepperContext = createContext<IStepperContext>({
  activeStep: 0,
  firstStepData: {} as IApplyVestingGeneral,
  handleNext: () => {
    return;
  },
  setFirstStepData: () => {
    return;
  },
});

export const StepperProvider: FC<PropsWithChildren> = props => {
  const { children } = props;
  const [activeStep, setActiveStep] = useState(StepperActiveSteps.FIRST);
  const [firstStepData, setFirstStepData] = useState<IApplyVestingGeneral>({} as IApplyVestingGeneral);

  const handleNext = (step: StepperActiveSteps) => {
    setActiveStep(step);
  };

  return (
    <StepperContext.Provider
      value={{
        activeStep,
        firstStepData,
        handleNext,
        setFirstStepData,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

export const useStepperContext = () => useContext<IStepperContext>(StepperContext);
