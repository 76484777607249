import React, { SVGProps } from "react";

export const RequiredStarIcon = (props: SVGProps<any>) => {
  return (
    <svg
      width={props.width || "6"}
      height={props.height || "6"}
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10353_4974)">
        <path
          d="M2.99831 0.5L3.77431 2.182L5.61381 2.40025L4.25381 3.65775L4.61481 5.47475L2.99831 4.57L1.38181 5.475L1.74281 3.658L0.382812 2.4L2.22256 2.18175L2.99831 0.5Z"
          fill="#3F65FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_10353_4974">
          <rect width="6" height="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
