import { Box, IconButton, SwipeableDrawer, styled } from "@mui/material";

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "70%",
    borderRadius: "14px 0px 0px 14px",
    background: theme.palette.custom.card_2.background,
    borderLeft: "1px solid #4467FF",
  },
}));

export const StyledMenuWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "100%",
  height: "100vh",
  padding: theme.spacing(0.9, 2.1, 4, 0),
}));

export const StyledMenuHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: theme.spacing(0.9),
}));

export const StyledContentBox = styled(Box)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

export const StyledMenuBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
});

export const StyledIconButton = styled(IconButton)(() => ({
  color: "#FFFFFF",
  padding: 0,
}));
