import React, { ChangeEvent, FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IVestingBox } from "@framework/types";
import { ListActionVariant } from "@framework/styled";

import { SplitTokenButton } from "../../../components/buttons/split";
import { ISelected } from "../../../pages/exchange/current-asset-box/useFetchToken";
import { ContentMode } from "../../types";
import { StyledBlockRoot } from "../styled";
import { CustomSlider } from "../slider";
import { StyledSlidesBox } from "./styled";
import { CalculationSlides, VendorAllowanceSide } from "./types";
import { splitCalculation } from "./utils";
import { calculationSlidesInitialValue, splitCalculationLayout } from "./schemas";
import { CustomAccordion } from "../accordion";
import { VendorAllowance } from "./VendorAllowance";
import { SplitResult } from "./SplitResult";

interface ISplitCalculationProps {
  box: IVestingBox;
  token?: ISelected;
  mode?: ContentMode;
  releasablePercentage?: number;
  withdrawnPercentage?: number;
}

export const SplitCalculation: FC<ISplitCalculationProps> = props => {
  const { mode = ContentMode.BOX, box, token, withdrawnPercentage, releasablePercentage } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [calculationSlides, setCalculationSlides] = useState<CalculationSlides>(
    () => calculationSlidesInitialValue[mode],
  );

  const [vendorAllowanceValue, setVendorAllowanceValue] = useState<Record<VendorAllowanceSide, string>>({
    left: "0",
    right: "0",
  });

  const { formatMessage } = useIntl();

  const onSliderChange = (event: any, value: number | number[]) => {
    const sliderName = event.target.name;

    if (!Array.isArray(value)) {
      setCalculationSlides(prev => ({ ...prev, [sliderName]: value }));
    }
  };

  const onInputChange = (side: VendorAllowanceSide, event: ChangeEvent<HTMLInputElement>) => {
    setVendorAllowanceValue(prev => ({ ...prev, [side]: event.target.value }));
  };
  const preparedCalculationConfig = {
    splitPercentage: calculationSlides.splitPercentage,
    withdrawnPercentage:
      (withdrawnPercentage || 0) + (releasablePercentage || 0) + (calculationSlides.withdrawnPercentage || 0),
  };

  const { leftBoxCalculation, rightBoxCalculation, isNothingForSplit } = useMemo(() => {
    const vestingBox = token ? token.template.box : box;
    const price = token ? token.template.price! : box.template!.price!;
    return splitCalculation(
      preparedCalculationConfig,
      { left: Number(vendorAllowanceValue.left), right: Number(vendorAllowanceValue.right) },
      vestingBox,
      price,
    );
  }, [preparedCalculationConfig]);

  return (
    <StyledBlockRoot>
      <Grid2
        sx={{ width: "100%", marginTop: 2 }}
        container
        size={12}
        spacing={2.5}
        direction={splitCalculationLayout[mode].direction}
        alignItems={"center"}
      >
        <StyledSlidesBox size={splitCalculationLayout[mode].slidesSize}>
          {Object.entries(calculationSlides).map(([key, value]) => {
            return (
              <CustomSlider
                key={key}
                name={key}
                value={value}
                max={99}
                min={1}
                onChange={onSliderChange}
                title={formatMessage({ id: `pages.vesting.currentBox.calculationSlides.${key}` }, { value })}
              />
            );
          })}
          {isMd && (
            <SplitResult
              mode={mode}
              leftBoxCalculation={leftBoxCalculation}
              rightBoxCalculation={rightBoxCalculation}
            />
          )}
          <CustomAccordion
            summaryStyles={{ py: 2, px: 3.75, mt: 3 }}
            title={
              <Typography variant="subtitle_3">
                {formatMessage({ id: "pages.vesting.currentBox.calculationSlides.vendorAllowanceBase" })}
              </Typography>
            }
            description={<VendorAllowance vendorAllowanceValue={vendorAllowanceValue} onInputChange={onInputChange} />}
          />
          {token && mode === ContentMode.ASSET && (
            <SplitTokenButton
              splitPercentage={calculationSlides.splitPercentage}
              className={"CurrentToken-splitBtn"}
              token={token}
              variant={ListActionVariant.button}
              disabled={isNothingForSplit}
            />
          )}
        </StyledSlidesBox>
        {!isMd && (
          <SplitResult mode={mode} leftBoxCalculation={leftBoxCalculation} rightBoxCalculation={rightBoxCalculation} />
        )}
      </Grid2>
    </StyledBlockRoot>
  );
};
