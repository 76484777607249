import { Grid2, styled } from "@mui/material";

export const StyledBoxInfoItem = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1.5),

  p: {
    margin: 0,
    ...theme.typography.caption_1,
    color: theme.palette.custom.gray,
  },

  h6: {
    margin: 0,
    ...theme.typography.body_1,
    color: theme.palette.custom.gray,
  },

  ".BoxInfo-infoItem": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    color: theme.palette.custom.white,
    ...theme.typography.caption_1,
  },

  ".BoxInfo-infoFooterItem": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    color: theme.palette.custom.white,
    ...theme.typography.subtitle_1,
  },
}));
