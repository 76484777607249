import { Grid2, styled } from "@mui/material";

export const Root = styled(Grid2)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: theme.spacing(7.5),

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },

  "& h4": {
    width: "fit-content",
    color: "#000000",
    fontSize: theme.spacing(3),
    fontWeight: 500,
    lineHeight: "140%",
    margin: 0,
    borderBottom: "1px solid #4BD4FF",
  },

  "& span, .MuiTypography-caption_1": {
    margin: 0,
    color: theme.palette.custom.gray,
    ...theme.typography.caption_1,
  },

  "& .MuiTypography-caption_1": {
    color: theme.palette.custom.white,
  },

  "& .DateItem-text": {},
}));

export const StyledDatesItem = styled(Grid2)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),

  "& span, .MuiTypography-caption_1": {
    margin: 0,
    color: theme.palette.custom.gray,
    ...theme.typography.caption_1,
  },

  "& .MuiTypography-caption_1": {
    color: theme.palette.custom.white,
  },

  "& .DateItem-text": {},
}));
