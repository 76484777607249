import { StylesScheme } from "../../../shared";

export const fadedAnimation = (delay: string): StylesScheme => ({
  opacity: 0,
  transform: "translateY(20px)",
  animation: `fadeInUp 0.5s ${delay} forwards`,
  "@keyframes fadeInUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
});

export const flexStyles: StylesScheme = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
