import { SxProps, Theme } from "@mui/material";

export const setFiltersStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  searchInputWrapperStyles: {
    display: "flex",
    alignItems: "center",
    my: 1,
    borderRadius: theme.spacing(1.25),
    gap: theme.spacing(1),
    padding: theme.spacing(1.25, 2.5),
  },
  searchInputStyles: {
    flexGrow: 1,
    color: theme.palette.custom.gray,
    ...theme.typography.body_1,

    "& .MuiInputBase-input": {
      py: 0,
      px: 0,
    },
  },
  searchInputCollapseStyles: {
    "& .MuiCollapse-wrapper": {
      background: theme.palette.custom.card_2.background,
      padding: theme.spacing(2.5),
      borderRadius: theme.spacing(1.25),
      border: `1px solid ${theme.palette.custom.main.default}`,
    },
  },
  searchInputChipsStyles: {
    "& .SearchChips-chosenChip, .SearchChips-clearFiltersChip": {
      gap: theme.spacing(1.5),
      borderRadius: theme.spacing(1.25),
      color: theme.palette.custom.white,
      ...theme.typography.caption_3,
      padding: theme.spacing(0.5, 1),

      "& .MuiChip-label": {
        padding: 0,
      },

      "& .MuiSvgIcon-root": {
        marginRight: 0,
        color: theme.palette.custom.white,

        ":hover": {
          color: theme.palette.custom.white,
        },
      },
    },

    "& .SearchChips-chosenChip": {
      background: theme.palette.custom.main.default,
    },

    "& .SearchChips-clearFiltersChip": {
      background: "transparent",
    },
  },
  entityInputStyles: {
    background: theme.palette.custom.white,
    borderRadius: theme.spacing(1.25),

    "& .MuiOutlinedInput-root": {
      borderRadius: theme.spacing(1.25),
      padding: theme.spacing(1.25, 1.25, 1.25, 2.5),
      color: theme.palette.custom.black,
      ...theme.typography.body_1,

      "&:hover .MuiOutlinedInput-notchedOutline": {
        boxShadow: "none",
      },

      "& .MuiOutlinedInput-input": {
        padding: 0,

        "&:placeholder": {
          color: theme.palette.custom.black,
        },
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      padding: 0,
    },
  },
  ethInputStyles: {
    width: "100%",
    flex: 1,
    padding: theme.spacing(1.25, 2.5),
    borderRadius: theme.spacing(1.25),
    border: "none",
    color: theme.palette.custom.gray,
    ...theme.typography.body_1,
  },

  swipeableDrawerStyles: {
    "& .MuiPaper-root": {
      padding: theme.spacing(2),
      width: "90%",
      borderRadius: "0px 14px 14px 0px",
      borderRight: "1px solid #4467FF",
      background: theme.palette.custom.card_2.background,

      "& .SearchFilters-drawerHeader": {
        gap: theme.spacing(2),
        "& .MuiTypography-body1": {
          flexBasis: "80%",
          textAlign: "center",
          ...theme.typography.subtitle_2,
        },

        "& .MuiBox-root": {
          height: 24,
        },
      },

      "& .MuiGrid2-container": {
        gap: "20px",
      },

      "& .MuiTypography-root": {
        color: theme.palette.custom.white,
        ...theme.typography.caption_1,
      },
    },
  },
});
