import React, { FC, useMemo, useState } from "react";
import { Grid2, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import { IToken, TVestingTemplate } from "@framework/types";

import { VestingBoxCategoryBadge } from "../../../components/common/badge";
import { StyledCardMedia } from "../styled";
import { PATHS } from "../../constants";
import { getBackgroundImg, setTokenTitle, Vesting, VestingBox } from "../../utils";
import { useVestingContractInfo } from "../../hooks";
import { BoxesAssetVariant } from "../../types";
import { Root, StyledCardActionArea, StyledCardActions, StyledCardActionsInfo } from "./styled";

interface IBoxCard {
  template: TVestingTemplate;
  token: IToken;
  variant: BoxesAssetVariant;
}

export const TokenCard: FC<IBoxCard> = React.memo(props => {
  const { template, token, variant } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const vestingBox = useMemo(() => {
    return new VestingBox(template.box, template.price!);
  }, []);

  const { tge } = vestingBox.getBoxInfo(template.createdAt);

  const { releasable, withdrawn } = useVestingContractInfo(token.tokenId, token.template!.contract!.address);

  const vesting = useMemo(() => {
    return new Vesting(template.box, template.price!, releasable, withdrawn);
  }, [releasable, withdrawn]);

  const backgroundImg = template.box.backgroundImg || getBackgroundImg(template.box.vestingBoxCat);

  return (
    <Root
      component={Link}
      to={`${PATHS.Asset.replace(":tokenId", `${token?.id}`).replace(":variant", `${variant}`)}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledCardActionArea
        sx={{
          transform: isHovered ? "scale(1.04)" : "scale(1.0)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <VestingBoxCategoryBadge category={template.box.vestingBoxVariant} />
        <StyledCardMedia className="BoxCard-media" component="img" image={backgroundImg} />
      </StyledCardActionArea>
      <StyledCardActions>
        <Typography variant="subtitle_2" component="h5">
          {setTokenTitle(template.title, token.tokenId)}
        </Typography>
        <StyledCardActionsInfo container size={12}>
          <Grid2>
            <p className="CardTopInfoItem-title">{formatMessage({ id: "pages.vesting.currentBox.tge" })}</p>
            <p className="CardTopInfoItem-subtitle">{tge}</p>
          </Grid2>
          <Grid2>
            <p className="CardTopInfoItem-title">{formatMessage({ id: "pages.tokens.releasable" })}</p>
            <p className="CardTopInfoItem-subtitle">{`${vesting.getReleasablePercentageDisplaying()}%`}</p>
          </Grid2>
        </StyledCardActionsInfo>
      </StyledCardActions>
    </Root>
  );
});
