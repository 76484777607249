import React from "react";
import { Grid2, IconButton, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import { StyledAvatar } from "@framework/styled";

import { StyledTokenAddressBox, StyledTokenTotalCountBox } from "./styled";
import { CopyIcon } from "../../assets/icons/Copy";
import { CustomTooltip } from "../tooltip";
import { InfoSvg } from "../../assets";

interface ITokenTotalCountProps {
  imageUrl: string;
  contentTokenTotalCount: string;
  contentTokenAddress?: string;
  containerSize?: number;
}

export const TokenTotalCount = (props: ITokenTotalCountProps) => {
  const { imageUrl, contentTokenTotalCount, contentTokenAddress, containerSize } = props;

  const { formatMessage } = useIntl();

  const formattedAddress = contentTokenAddress
    ? `${contentTokenAddress.slice(0, 4)}...${contentTokenAddress.slice(-4)}`
    : "";

  const copyContractAddressHandler = () => {
    if (!contentTokenAddress) {
      return;
    }
    const userAgent = navigator.userAgent;

    const showCopyAlert = () => {
      return;
    };

    if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
      navigator.clipboard
        .writeText(contentTokenAddress)
        .then(showCopyAlert)
        .catch(error => {
          console.warn(error);
        });
    } else if (/Android/i.test(userAgent)) {
      const ta = document.createElement("textarea");
      ta.value = contentTokenAddress;
      ta.style.position = "fixed";
      ta.style.opacity = "0";
      ta.style.left = "-9999px";
      document.body.appendChild(ta);
      ta.select();

      try {
        const success = document.execCommand("copy");
        if (success) {
          showCopyAlert();
        }
      } catch (error: any) {
        console.warn(error);
      } finally {
        ta.remove();
      }
    }
  };

  return (
    <Grid2 flexDirection="column" size={containerSize}>
      <StyledTokenTotalCountBox>
        <StyledAvatar src={imageUrl} width={36} height={36} />
        <Typography variant={"title_3"} component="h5">
          {contentTokenTotalCount}
        </Typography>
      </StyledTokenTotalCountBox>
      {formattedAddress && (
        <StyledTokenAddressBox display="flex" justifyContent="center">
          <Typography display="flex" alignItems="center" variant={"body_1"} component="h5" gap={1.5}>
            {formattedAddress}
            <IconButton aria-label="copy" onClick={copyContractAddressHandler}>
              <CopyIcon />
            </IconButton>
            <CustomTooltip title={formatMessage({ id: "pages.tokens.current.copyInfo" })}>
              <InfoSvg color={"#676767"} />
            </CustomTooltip>
          </Typography>
        </StyledTokenAddressBox>
      )}
    </Grid2>
  );
};
