import React from "react";
import { Grid2, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router";

import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { ProgressOverlay } from "@framework/components";
import { IVestingBox } from "@framework/types";
import { StyledPagination } from "@framework/styled";
import { IPaginationResult } from "@dzambalaorg/types-collection";

import {
  BoxCard,
  isOfferGuard,
  LoadMoreButton,
  StyledListRoot,
  TabMenu,
  VestingBoxesTabs,
  useFetchQuery,
  PATHS,
  renderEmpty,
  renderError,
  renderLoader,
  BoxCardSkeleton,
} from "../../../../../shared";
import { boxesHeaderStyles } from "./styled";
import { ApplyButton } from "./apply-button";
import { BoxesFilters } from "./filters";

export const VestingBoxes = () => {
  const { tab } = useParams<{ tab: VestingBoxesTabs }>();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const { data, isLoading, error, isError, take, skip, onChangePage } = useFetchQuery<IPaginationResult<IVestingBox>>(
    `vesting/${tab}`,
  );

  return (
    <StyledListRoot>
      <PageHeader sx={boxesHeaderStyles(theme)} message="pages.vesting.boxes.title">
        <ApplyButton />
      </PageHeader>

      <TabMenu tabs={VestingBoxesTabs} basicLocale="pages.vesting.boxes.tabs" basicPath={PATHS.VestingBoxesBase} />

      <BoxesFilters tab={tab} />

      <ProgressOverlay
        isLoading={!data && isLoading}
        isError={!data && isError}
        isEmpty={data?.rows?.length === 0}
        renderEmpty={renderEmpty}
        renderLoader={renderLoader({ skeleton: <BoxCardSkeleton /> })}
        renderError={renderError(error)}
      >
        <Grid2 container spacing={2}>
          {!!data &&
            data.rows.map(row => (
              <Grid2 spacing={3} size={{ lg: 4, md: 6, sm: 6, xs: 12 }} key={row.id}>
                <BoxCard
                  vestingBox={isOfferGuard(row) ? row.box : row}
                  tab={tab!}
                  offerId={isOfferGuard(row) ? row.id : undefined}
                  tokenId={isOfferGuard(row) ? row.tokenId : undefined}
                  ownerAddress={isOfferGuard(row) ? row.owner : undefined}
                />
              </Grid2>
            ))}
        </Grid2>
      </ProgressOverlay>

      {!isMd && !!data?.count && data?.count > take && (
        <StyledPagination
          shape="rounded"
          page={skip / take + 1}
          count={Math.ceil(data.count / take)}
          onChange={onChangePage}
        />
      )}
      {isMd && data?.rows.length !== data?.count && <LoadMoreButton loadMoreHandler={() => {}} />}
    </StyledListRoot>
  );
};
