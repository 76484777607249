import { Grid2, styled } from "@mui/material";

export const StyledCalculationBoxRoot = styled(Grid2)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  padding: theme.spacing(1.5),

  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const StyledCalculationItemBox = styled(Grid2)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(1.5),

  [theme.breakpoints.down(400)]: {
    width: "100%",
  },
}));

export const StyledCalculationItem = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),

  "& p, span": {
    margin: 0,
    color: theme.palette.custom.gray,
    ...theme.typography.caption_1,
  },

  "& span": {
    color: theme.palette.custom.white,
  },
}));
