import { Box, styled } from "@mui/material";

import { fadedAnimation, flexStyles } from "../styles";
import { sizeDecreaseCalc } from "../../../../shared";

export const Root = styled(Box)(({ theme }) => ({
  ...flexStyles,
  width: "100%",
  marginTop: theme.spacing(15),

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(7),
  },

  "& h1": {
    ...fadedAnimation(""),
    color: "#FFFFFF",
    fontFamily: "Montserrat, sans-serif",
    fontSize: sizeDecreaseCalc(50, 36),
    fontWeight: 900,
    lineHeight: "120%",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
}));
