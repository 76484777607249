import React from "react";
import { Navigate } from "react-router";

import { StyledCurrentRoot, VestingSkeleton } from "../../../shared";
import { useFetchToken } from "./useFetchToken";
import { Box } from "./box";

export const CurrentAssetBox = () => {
  const { selected, isLoading, isError, variant } = useFetchToken();

  if (isLoading) {
    return (
      <StyledCurrentRoot isLoading={isLoading}>
        <VestingSkeleton />
      </StyledCurrentRoot>
    );
  }

  if (!selected) {
    return isError ? <Navigate to="/error/message" /> : null;
  }

  return <Box selected={selected} variant={variant} />;
};
