import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";

import { IVestingBox } from "@framework/types";
import { useApi } from "@dzambalaorg/provider-api";

import { IVestingOfferResponse, PATHS, VestingBoxesTabs } from "../../../../../shared";

export const useFetchBox = () => {
  const { boxId, tab } = useParams<{ boxId: string; tab: VestingBoxesTabs }>();
  const api = useApi();

  return useQuery({
    queryKey: ["currentTradingBox", [tab, boxId]],
    queryFn: (): Promise<IVestingBox | IVestingOfferResponse> => {
      return api.fetchJson({
        url: `${PATHS.VestingBoxesBase}/${tab}/${boxId}`,
      });
    },
  });
};
