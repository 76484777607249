import { ChangeEvent, useLayoutEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useApi } from "@dzambalaorg/provider-api";
import { ISearchDto } from "@dzambalaorg/types-collection";
import { defaultItemsPerPage } from "@dzambalaorg/constants";

type TInitialValues = Omit<Partial<ISearchDto>, "query"> & Record<string, any>;
const defaultValues = {
  take: defaultItemsPerPage,
  skip: 0,
};

export const useFetchQuery = <S extends Record<string, any>>(
  queryKey: string,
  _initialData?: Record<string, any>,
  _initialSearch?: TInitialValues,
  _excludedSearch: Array<keyof TInitialValues> = [],
) => {
  const initialValues: Record<string, any> = { ...defaultValues, ..._initialSearch };
  const excludedSearch: Array<keyof TInitialValues> = ["tab", ..._excludedSearch];
  const initialData = { ..._initialData };

  const api = useApi();

  const [search, setSearch] = useSearchParams();

  const take: number = Number(search.get("take")) || initialValues.take;
  const skip: number = Number(search.get("skip")) || initialValues.skip;

  useLayoutEffect(() => {
    if (Array.from(search.keys()).length === 0) {
      setSearch(initialValues);
    }
  }, []);

  const filteredFetchData = useMemo(() => {
    const fetchData: Array<[string, any]> = Object.entries(initialData);

    search.forEach((value, key) => {
      if (!excludedSearch.includes(key)) {
        fetchData.push([key, value]);
      }
    });

    return fetchData.reduce((acc: Record<string, any>, [key, value]) => {
      if (!acc[key]) {
        acc[key] = value;
      } else {
        if (!Array.isArray(acc[key])) {
          acc[key] = [acc[key]];
        }
        acc[key].push(value);
      }
      return acc;
    }, {});
  }, [initialData, search]);

  const queryData = useQuery({
    queryKey: [queryKey, Object.fromEntries(search.entries())],
    queryFn: (): Promise<S> => {
      return api.fetchJson({
        url: `/${queryKey}`,
        data: filteredFetchData,
      });
    },
  });

  const onChangePage = (_e: ChangeEvent<unknown>, page: number) => {
    setSearch(prev => ({
      ...Object.fromEntries(prev.entries()),
      skip: String((page - 1) * take),
    }));
  };

  // const loadMoreHandler = () => {
  //   setSearch(prev => ({
  //     ...Object.fromEntries(prev.entries()),
  //     skip: String((page - 1) * take),
  //   }));
  // };

  return {
    ...queryData,
    take,
    skip,
    onChangePage,
  };
};
