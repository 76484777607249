import React from "react";
import { Grid2, Skeleton, Typography } from "@mui/material";

export const OffersSkeleton = () => {
  return (
    <React.Fragment>
      <Grid2
        mb={4.25}
        container
        sx={{ width: "100%", padding: 3.75, backgroundColor: "custom.card_2.background", borderRadius: "20px" }}
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Grid2 mb={3}>
          <Skeleton width={190} sx={{ backgroundColor: "custom.gray" }} />
          <Skeleton width={270} sx={{ backgroundColor: "custom.gray" }} />
        </Grid2>

        <Grid2 mb={3}>
          <Skeleton width={180} sx={{ backgroundColor: "custom.gray" }} />
          <Skeleton variant="rectangular" height={8} sx={{ backgroundColor: "custom.gray", borderRadius: 1 }} />
        </Grid2>

        <Grid2 mb={2} container spacing={2}>
          {[...Array(6)].map((_, index) => (
            <Grid2 size={{ xs: 6, sm: 4, md: 2 }} key={index}>
              <Typography variant="body2" sx={{ fontSize: "0.9rem", mb: 0.5 }}>
                <Skeleton width="80px" sx={{ backgroundColor: "custom.gray" }} />
              </Typography>
              <Skeleton width="60px" sx={{ backgroundColor: "custom.gray" }} />
            </Grid2>
          ))}
        </Grid2>
      </Grid2>

      <Grid2
        mb={4.25}
        container
        sx={{ width: "100%", padding: 3.75, backgroundColor: "custom.card_2.background", borderRadius: "20px" }}
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Skeleton width={150} sx={{ backgroundColor: "custom.gray" }} />
        <Grid2 mt={3} container spacing={2}>
          {[...Array(6)].map((_, index) => (
            <Grid2 size={{ xs: 6, sm: 4, md: 2 }} key={index}>
              <Typography variant="body2" sx={{ fontSize: "0.9rem", mb: 0.5 }}>
                <Skeleton width="80px" sx={{ backgroundColor: "custom.gray" }} />
              </Typography>
              <Skeleton width="60px" sx={{ backgroundColor: "custom.gray" }} />
            </Grid2>
          ))}
        </Grid2>
      </Grid2>
    </React.Fragment>
  );
};
