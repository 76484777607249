import { Box, Grid2, Skeleton, styled } from "@mui/material";

const StyledSkeletonCardActionArea = styled("div")({
  position: "relative",
});

const StyledSkeletonCardActions = styled("div")({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

export const AssetBoxCardSkeleton = () => {
  return (
    <Grid2>
      <StyledSkeletonCardActionArea>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={80}
          height={24}
          sx={{
            borderRadius: "100px",
            position: "absolute",
            top: 16,
            right: 16,
            backgroundColor: "#2B2C3B",
            opacity: 0.5,
          }}
        />

        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ borderRadius: "20px", backgroundColor: "#676767", opacity: 0.5 }}
        />
      </StyledSkeletonCardActionArea>

      <StyledSkeletonCardActions>
        <Skeleton
          animation="wave"
          variant="text"
          width="70%"
          height={24}
          sx={{ backgroundColor: "#676767", opacity: 0.5 }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Skeleton
            animation="wave"
            variant="text"
            width="90%"
            height={20}
            sx={{ backgroundColor: "#676767", opacity: 0.5 }}
          />
        </Box>
      </StyledSkeletonCardActions>
    </Grid2>
  );
};
