import { AppBar, Box, Link, LinkProps, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledLink = styled(Link)<LinkProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.common.white,
  textDecoration: "none",
  fontWeight: 500,
  fontSize: 36,
  "&:hover": {
    textDecoration: "none",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 22,
  },
})) as typeof Link;

export const StyledGrow = styled(Box)({ flexGrow: 1 });

export const StyledToolbar = styled(Toolbar)(() => ({
  background: "transparent",
  padding: 0,

  "& .MuiBadge-badge": {
    background: "none",
  },

  "& .iekbcc0": {
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignSelf: "center",
  width: "100%",
  background: "transparent",
  boxShadow: "none",
  padding: theme.spacing(2.5, 7, 2.5, 7),

  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(1, 3, 1, 3),
  },
}));
