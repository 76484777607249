import React, { ChangeEvent, useMemo, useState } from "react";
import { Grid2, useMediaQuery, useTheme } from "@mui/material";
import { useIntl } from "react-intl";

import { ListActionVariant } from "@framework/styled";

import { CustomInput, WithBorderInfoBox } from "../../../../shared";
import { MakeOfferButton } from "../../../../components/buttons/offer";
import { gridItemsSizes, StyledRoot } from "./styled";
import { IOfferManagementProps } from "./types";
import { calculateOfferInfo } from "./utils";

export const OfferManagement = React.memo((props: IOfferManagementProps) => {
  const { vesting, template, boxId, tokenId, priceAsset, contentAsset } = props;
  const [vendorAllowanceValue, setVendorAllowanceValue] = useState("0");

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVendorAllowanceValue(event.target.value);
  };

  const infoData = useMemo(() => {
    return calculateOfferInfo(vesting, Number(vendorAllowanceValue));
  }, [vendorAllowanceValue, vesting]);

  const tokensCount = vesting.getContentAmount();

  const price = {
    ...priceAsset,
    components: priceAsset.components.map(c => ({
      ...c,
      amount: (Number(infoData.boxPrice.split(" ")[1]) * 1e18).toString(),
    })),
  };

  const content = {
    ...contentAsset,
    components: contentAsset.components.map(c => ({
      ...c,
      amount: (tokensCount * 1e18).toString(),
    })),
  };

  return (
    <StyledRoot container spacing={2}>
      <Grid2 className="OfferManagement-offerBox" size={gridItemsSizes}>
        <CustomInput
          label={formatMessage(
            { id: "pages.vesting.currentBox.calculationSlides.vendorAllowance" },
            { value: vendorAllowanceValue || 0 },
          )}
          placeholder={"0%"}
          onChange={onInputChange}
        />
        {!isMd && (
          <MakeOfferButton
            className="OfferManagement-offerBtn"
            variant={ListActionVariant.button}
            template={template}
            offer={{ boxId, tokenId, price, content, templateId: template.id, benefit: Number(vendorAllowanceValue) }}
          />
        )}
      </Grid2>
      <Grid2 size={gridItemsSizes} className="OfferManagement-offerBox">
        <WithBorderInfoBox data={infoData} />
        {isMd && (
          <MakeOfferButton
            className="OfferManagement-offerBtn"
            variant={ListActionVariant.button}
            template={template}
            offer={{ boxId, tokenId, price, content, templateId: template.id, benefit: Number(vendorAllowanceValue) }}
          />
        )}
      </Grid2>
    </StyledRoot>
  );
});
