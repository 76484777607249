import { object, string } from "yup";

import { emailValidationSchema, titleValidationSchema } from "@dzambalaorg/yup-rules";

export const contactDetailsValidationSchema = object().shape({
  fullName: titleValidationSchema,
  projectRole: string().required("form.validations.valueMissing"),
  projectEmailAddress: emailValidationSchema,
  telegramId: string().required("form.validations.valueMissing"),
});

export const teamValidationSchema = object().shape({
  size: string().required("form.validations.valueMissing"),
  information: string().required("form.validations.valueMissing"),
  experience: string(),
});

export const validationSchema = object().shape({
  contactDetails: contactDetailsValidationSchema,
  whoRefereed: string().required("form.validations.valueMissing"),
  keyQuestionA: string().required("form.validations.valueMissing"),
  keyQuestionB: string().required("form.validations.valueMissing"),
  keyQuestionC: string().required("form.validations.valueMissing"),
  tokenSymbol: string().required("form.validations.valueMissing"),
  tokenCirculation: string().required("form.validations.valueMissing"),
  tgeDeadlines: string().required("form.validations.valueMissing"),
  projectFocusOn: string().required("form.validations.valueMissing"),
  highPotential: string(),
  painPoints: string(),
  painSolutions: string(),
  productAudience: string(),
  projectBenefits: string().required("form.validations.valueMissing"),
  team: teamValidationSchema,
  tokenUtility: string().required("form.validations.valueMissing"),
  tokenDistribution: string().required("form.validations.valueMissing"),
  productLaunched: string().required("form.validations.valueMissing"),
  numberOfUsers: string(),
  tvl: string(),
  totalVolume: string(),
  nftSales: string(),
  communitySize: string(),
  communityDemography: string(),
  communitySupport: string(),
  innovations: string(),
  streamsRevenue: string(),
  isTeamWorking: string(),
  partners: string().required("form.validations.valueMissing"),
  mainCompetitors: string().required("form.validations.valueMissing"),
  mainInnovations: string(),
  isProjectOpen: string().required("form.validations.valueMissing"),
  extendingVesting: string().required("form.validations.valueMissing"),
  willingToLowerPrice: string().required("form.validations.valueMissing"),
  antiPhishingCode: string()
    .min(1, "form.validations.rangeUnderflow")
    .max(8, "form.validations.rangeOverflow")
    .required("form.validations.valueMissing"),
});
