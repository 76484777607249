import { PATHS } from "./router";

export const INFINITY_DISPLAYING = "∞";
export const AMOUNT_FIX_VALUE = 2;
export const TOKEN_PERCENTAGE_FIX_VALUE = 3;
export const TOKEN_DISPLAYING_IN_CARD_FIX_VALUE = 4;
export const TOKEN_DISPLAYING_FIX_VALUE = 5;

export const DESKTOP_ITEMS_COUNT = 25;
export const MOBILE_ITEMS_COUNT = 50;

export const REDIRECT_PATH = `${PATHS.VestingBoxesBase}/boxes`;
