import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import { PATHS, VestingBoxesTabs } from "../../../../shared";
import { SimpleButton } from "../../../../components/buttons/simple";
import { StyledHeaderBox } from "./styled";

export const MainPageHeader = () => {
  const { formatMessage } = useIntl();
  return (
    <StyledHeaderBox>
      <Box className="MainPageHeader-TitleBox">
        <FormattedMessage id="pages.main.title1" tagName="h1" />
        <FormattedMessage id="pages.main.title2" tagName="h1" />
      </Box>
      <Box className="MainPage-subtitle">
        <FormattedMessage id="pages.main.subtitle" tagName="p" />
      </Box>
      <SimpleButton component={Link} to={`${PATHS.VestingBoxesBase}/${VestingBoxesTabs.boxes}`}>
        {formatMessage({ id: "pages.main.getStartedBtn" })}
      </SimpleButton>
    </StyledHeaderBox>
  );
};
