import { Box, styled } from "@mui/material";

import { sizeDecreaseCalc, sizeIncreaseCalc } from "../../../../shared";
import { fadedAnimation, flexStyles } from "../styles";

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  ...flexStyles,
  boxSizing: "border-box",
  width: "100%",
  minHeight: "100vh",
  paddingBottom: theme.spacing(4),
  background: "url('/files/new/thelamb_main_header_background.png') no-repeat",
  backgroundPosition: `center center`,
  backgroundSize: "cover",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    minHeight: "75vh",
    paddingInline: theme.spacing(2),
  },

  "&::before": {
    content: "''",
    display: "block",
    position: "absolute",
    bottom: "-140px",
    left: "0",
    width: "300vw",
    height: "150px",
    transform: "translateZ(0)",
    background: "linear-gradient(to top, rgba(255, 255, 255, 0), #05122d)",
    filter: "blur(5px)",
    zIndex: 1,
  },

  "&::after": {
    content: "''",
    display: "block",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "300vw",
    height: "50px",
    transform: "translateZ(0)",
    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), #05122d)",
    filter: "blur(20px)",
    zIndex: 1,
  },

  "& .MainPageHeader-TitleBox": {
    "& h1": {
      ...fadedAnimation(""),
      color: "#FFFFFF",
      fontFamily: "Montserrat, sans-serif",
      fontSize: sizeDecreaseCalc(60, 36),
      fontWeight: 900,
      lineHeight: "120%",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      textAlign: "center",
    },
  },

  "& .MainPage-subtitle": {
    ...fadedAnimation("0.2s"),
  },

  "& p": {
    fontFamily: "Inter, sans-serif",
    fontSize: sizeDecreaseCalc(28, 18),
    fontWeight: 400,
    lineHeight: "150%",
    color: "#898CA9",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(5),
    textAlign: "center",
  },

  "& img": {
    width: sizeIncreaseCalc(50, 70, theme.breakpoints.values.lg, theme.breakpoints.values.md + 1, "%"),
    height: "25%",
    objectFit: "cover",
  },

  "& .MuiButtonBase-root": {
    width: sizeDecreaseCalc(253, 140),
    height: sizeDecreaseCalc(64, 40),
    paddingInline: sizeDecreaseCalc(32, 8),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    background: "#3F65FF",
    border: "none",
    borderRadius: theme.spacing(3.75),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    fontFamily: "Inter, sans-serif",
    fontSize: sizeDecreaseCalc(24, 16),
    fontWeight: 600,
    lineHeight: "100%",

    "&:hover": {
      backgroundColor: "#3656e0",
      color: "#FFFFFF",
    },

    opacity: 0,
    transform: "translateY(20px)",
    animation: "fadeInUp 0.5s 0.4s forwards",
    "@keyframes fadeInUp": {
      "0%": {
        opacity: 0,
        transform: "translateY(20px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
}));
