import React from "react";
import { Grid2, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import { splitCalculationLayout } from "./schemas";
import { StyledCalculationBox } from "./styled";
import { CalculationData, ContentMode } from "../../types";

interface ISplitResultProps {
  mode: ContentMode;
  leftBoxCalculation: CalculationData;
  rightBoxCalculation: CalculationData;
}

export const SplitResult = (props: ISplitResultProps) => {
  const { mode, rightBoxCalculation, leftBoxCalculation } = props;

  const { formatMessage } = useIntl();
  return (
    <StyledCalculationBox size={splitCalculationLayout[mode].calculationSize}>
      <Grid2 container size={12}>
        {[
          null,
          <Typography component="p" variant="caption_2" key={"left"}>
            {formatMessage({ id: "pages.vesting.currentBox.calculationSlides.vendorAllowanceLeftBase" })}
          </Typography>,
          <Typography component="p" variant="caption_2" key={"right"}>
            {formatMessage({ id: "pages.vesting.currentBox.calculationSlides.vendorAllowanceRightBase" })}
          </Typography>,
        ].map((c, i) => (
          <Grid2 key={i} className={`Calculation-headerItem index_${i}`} size={4}>
            {c}
          </Grid2>
        ))}
      </Grid2>
      <Grid2 container size={12}>
        <Grid2 className="Calculation-keys" size={4}>
          {Object.keys(leftBoxCalculation).map(k => (
            <Typography component="p" variant="caption_1" key={k}>
              {formatMessage({ id: `pages.vesting.currentBox.calculationItems.${k}` })}
            </Typography>
          ))}
        </Grid2>
        <Grid2 className="Calculation-values" size={4}>
          {Object.values(leftBoxCalculation).map(v => (
            <Typography component="p" variant="caption_1" key={v}>
              {v}
            </Typography>
          ))}
        </Grid2>
        <Grid2 className="Calculation-values" size={4}>
          {Object.values(rightBoxCalculation).map(v => (
            <Typography component="p" variant="caption_1" key={v}>
              {v}
            </Typography>
          ))}
        </Grid2>
      </Grid2>
    </StyledCalculationBox>
  );
};
