import React from "react";
import { useParams } from "react-router";

import { PageHeader } from "@dzambalaorg/mui-page-layout";

import { AssetsDashboardTabs, PATHS, StyledListRoot, TabMenu } from "../../../shared";
import { BoxesAssets } from "./boxes-assets";
import { OffersAssets } from "./offers-assets";
import { AssetsInfo } from "./assets-info";

export const AssetsDashboard = () => {
  const { tab } = useParams<{ tab: AssetsDashboardTabs }>();

  const renderContent: Record<AssetsDashboardTabs, React.JSX.Element> = {
    [AssetsDashboardTabs.boxes]: <BoxesAssets variant={AssetsDashboardTabs.boxes} />,
    [AssetsDashboardTabs.offers]: <OffersAssets variant={AssetsDashboardTabs.offers} />,
    [AssetsDashboardTabs.assetsInfo]: <AssetsInfo />,
  };

  return (
    <StyledListRoot>
      <PageHeader message="pages.tokens.title" sx={{ marginBottom: 2 }} />

      <TabMenu tabs={AssetsDashboardTabs} basicLocale="pages.tokens.tabs" basicPath={PATHS.DashboardBase} />
      <React.Fragment key={tab}>{tab && renderContent[tab]}</React.Fragment>
    </StyledListRoot>
  );
};
