import { Box, styled } from "@mui/material";

import { buttonStyles, setButtonStyles } from "../../../shared";

export const StyledAgreementDataBox = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(3),

  "& h4": {
    color: theme.palette.custom.white,
    ...theme.typography.subtitle_2,
  },

  "& p": {
    color: theme.palette.custom.bodyText,
    ...theme.typography.body_1,
  },
}));

export const StyledButtonsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItem: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(1.5),
  marginTop: theme.spacing(3),
  paddingRight: theme.spacing(3),

  "& .MuiButton-root": {
    ...buttonStyles,
    textTransform: "none",
  },

  "& .AgreementDialog-AgreeBtn": {
    ...setButtonStyles(theme),
  },

  "& .AgreementDialog-Cancel": {
    ...setButtonStyles(theme, { default: "transparent", hover: "transparent", disabled: "transparent" }),
  },
}));
