import type { RouteObject } from "react-router-dom";

import { Protected } from "@dzambalaorg/common-pages";

import { PATHS, REDIRECT_PATH } from "../../shared";
import { AssetsDashboard } from "./assets-dashboard";
import { CurrentAssetBox } from "./current-asset-box";

export const exchangeRoutes: Array<RouteObject> = [
  {
    path: PATHS.DashboardBase,
    element: <Protected navigateUrl={REDIRECT_PATH} />,
    children: [{ path: PATHS.Dashboard, element: <AssetsDashboard /> }],
  },
  {
    path: PATHS.Asset,
    element: (
      <Protected>
        <CurrentAssetBox />
      </Protected>
    ),
  },
];
