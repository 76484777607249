import { FormattedMessage } from "react-intl";

import { aboutBlockData } from "./schemas";
import { Root, StyledCard, StyledCardBox } from "./styled";
import { Box } from "@mui/material";

export const MainPageCards = () => {
  return (
    <Root id="aboutUs">
      <StyledCardBox container mt={4} spacing={2}>
        {aboutBlockData.map(({ title, subtitle, icon }, index) => (
          <StyledCard
            sx={{ justifyContent: subtitle ? "flex-start" : "center" }}
            key={title}
            $index={index}
            size={{ lg: 4, md: 4, xs: 12 }}
          >
            <Box className="MainPageCards-CardHeader">
              <Box className="CardHeader-icon-bg">{icon}</Box>
              <FormattedMessage id={title} tagName="h4" />
            </Box>
            {subtitle && <FormattedMessage id={subtitle} tagName="p" />}
          </StyledCard>
        ))}
      </StyledCardBox>
    </Root>
  );
};
