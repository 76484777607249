import React from "react";
import { Grid2, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { StyledAvatar } from "@framework/styled";

export const renderLoader = (props: { skeleton: React.JSX.Element }) => () => (
  <Grid2 container spacing={2}>
    {[...Array(6)].map(i => (
      <Grid2 spacing={3} size={{ lg: 4, md: 6, sm: 6, xs: 12 }} key={i}>
        {props.skeleton}
      </Grid2>
    ))}
  </Grid2>
);

export const renderEmpty = () => {
  const { formatMessage } = useIntl();
  return (
    <Grid2 container size={12} justifyContent="center">
      <Grid2 flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
        <StyledAvatar src="/files/new/nothing_to_display.png" width={200} height={200} />
        <Typography variant="subtitle_1" color="custom.white">
          {formatMessage({ id: "messages.empty-list" })}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export const renderError = (error: Error | null, img?: { src?: string; w: number; h: number }) => () => {
  return (
    <Grid2 container size={12} justifyContent="center">
      <Grid2 display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
        <StyledAvatar src={img?.src || "/files/new/page_error.png"} width={img?.w || 200} height={img?.h || 200} />
        <Typography
          sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", gap: 1 }}
          variant="subtitle_2"
          color="custom.white"
          component="p"
        >
          <FormattedMessage
            id="errors.smtWentWrong"
            values={{
              errorText: error?.message,
              span: chunks => {
                if (!error) {
                  return null;
                }
                return (
                  <Typography variant="body_1" color="custom.gray">
                    {chunks}
                  </Typography>
                );
              },
            }}
          />
        </Typography>
      </Grid2>
    </Grid2>
  );
};
