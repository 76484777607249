import { useEffect } from "react";

import { collectionActions, collectionSelector, ICollectionState } from "@dzambalaorg/provider-collection";
import { useAppDispatch, useAppSelector } from "@dzambalaorg/redux";
import { useDidMountEffect } from "@dzambalaorg/react-hooks";

export const useReFetch = (fetch: () => Promise<any>) => {
  const dispatch = useAppDispatch();
  const { needReFetch } = useAppSelector<ICollectionState>(collectionSelector);
  const { setNeedReFetch } = collectionActions;

  useEffect(() => {
    void fetch();
  }, []);

  useDidMountEffect(() => {
    if (needReFetch) {
      void fetch().finally(() => {
        dispatch(setNeedReFetch(false));
      });
    }
  }, [needReFetch]);
};
