import { Grid2, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledSkeletonImage = styled(Skeleton)({
  borderRadius: "8px",
  marginBottom: "16px",
});

const StyledSkeletonButton = styled(Skeleton)({
  borderRadius: "24px",
  backgroundColor: "#2B2C3B",
  opacity: 0.5,
});

const StyledSkeleton = styled(Skeleton)({
  backgroundColor: "#2B2C3B",
  opacity: 0.5,
});

export const CurrentBoxSkeleton = () => {
  return (
    <Grid2 size={12}>
      <StyledSkeletonImage
        animation="wave"
        variant="rectangular"
        width="100%"
        height={300}
        sx={{
          width: "100%",
          borderRadius: "20px",
          backgroundColor: "#2B2C3B",
          opacity: 0.5,
        }}
      />

      <Grid2
        sx={{
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <StyledSkeleton animation="wave" variant="text" width="60%" height={48} />
        <StyledSkeletonButton animation="wave" variant="rectangular" width={180} height={40} />
      </Grid2>

      <Grid2 container size={12} spacing={2}>
        <Grid2 size={{ lg: 6, md: 12, xs: 12 }} sx={{ marginBottom: "24px" }}>
          <StyledSkeleton variant="text" height={32} sx={{ marginBottom: "12px" }} />
          <Box sx={{ display: "flex", gap: 2, marginBottom: "12px" }}>
            <StyledSkeleton variant="text" width="20%" height={40} />
            <StyledSkeleton variant="text" width="20%" height={40} />
            <StyledSkeleton variant="text" width="20%" height={40} />
          </Box>
          <StyledSkeleton variant="text" width="40%" height={20} />
          <StyledSkeleton variant="text" width="90%" height={20} />
          <StyledSkeleton variant="text" width="80%" height={20} />
          <StyledSkeleton variant="text" width="70%" height={20} />
        </Grid2>

        <Grid2 size={{ lg: 6, md: 12, xs: 12 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
            <Box sx={{ flex: 1 }}>
              <StyledSkeleton variant="text" height={32} sx={{ marginBottom: "12px" }} />
              <StyledSkeleton variant="text" width="90%" height={20} />
              <StyledSkeleton variant="text" width="80%" height={20} />
              <StyledSkeleton variant="text" width="85%" height={20} />
              <StyledSkeleton variant="text" width="70%" height={20} />
              <StyledSkeleton variant="text" width="60%" height={20} />
              <Box sx={{ marginTop: "16px" }}>
                <StyledSkeleton variant="text" width="40%" height={20} />
                <StyledSkeleton
                  variant="rectangular"
                  width="80%"
                  height={8}
                  sx={{ marginTop: "8px", borderRadius: "4px" }}
                />
                <StyledSkeleton variant="text" width="40%" height={20} sx={{ marginTop: "16px" }} />
                <StyledSkeleton
                  variant="rectangular"
                  width="80%"
                  height={8}
                  sx={{ marginTop: "8px", borderRadius: "4px" }}
                />
              </Box>
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
