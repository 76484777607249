import { styled, Tab, Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  position: "relative",

  "&::after": {
    content: "''",
    width: "100%",
    height: "2px",
    position: "absolute",
    left: 0,
    bottom: 0,
    backgroundColor: theme.palette.custom.card_2.background,
    zIndex: 1,
  },

  "& .Mui-selected": {
    color: theme.palette.custom.white,
    "& span": {
      ...theme.typography.subtitle_2,
    },
  },

  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.custom.main.default,
    zIndex: 2,
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: "46px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(1.5),
  padding: theme.spacing(0, 2, 0, 2),

  "& span": {
    textTransform: "none",
    color: theme.palette.custom.white,
    ...theme.typography.subtitle_3,
  },
}));
