import { Grid2, styled } from "@mui/material";

export const StyledTokenVestingInfoBox = styled(Grid2)(({ theme }) => ({
  "& .MuiTypography-body_1": {
    ...theme.typography.body_1,
    color: theme.palette.custom.gray,
  },

  "& .MuiTypography-subtitle_2": {
    ...theme.typography.subtitle_2,
    color: theme.palette.custom.white,
  },
}));
