import { UseFormReturn } from "react-hook-form";

import { EmailType, IApplyVesting } from "@framework/types";
import { useApi } from "@dzambalaorg/provider-api";

import { StepperActiveSteps, useStepperContext } from "../context";
import { ISecondStepForm } from "../types";

export const useSecondStepForm = () => {
  const { firstStepData, handleNext } = useStepperContext();
  const api = useApi();

  return async (values: ISecondStepForm, form: UseFormReturn<any>) => {
    const preparedSubmitData: IApplyVesting = {
      general: firstStepData,
      contactDetails: values.contactDetails,
      whoRefereed: values.whoRefereed,
      questions: {
        keyQuestionA: values.keyQuestionA,
        keyQuestionB: values.keyQuestionB,
        keyQuestionC: values.keyQuestionC,
      },
      token: {
        tokenSymbol: values.tokenSymbol,
        tokenCirculation: values.tokenCirculation,
        tgeDeadlines: values.tgeDeadlines,
        tokenUtility: values.tokenUtility,
        tokenDistribution: values.tokenDistribution,
      },
      project: {
        projectFocusOn: values.projectFocusOn,
        highPotential: values.highPotential,
        painPoints: values.painPoints,
        painSolutions: values.painSolutions,
        productAudience: values.productAudience,
        projectBenefits: values.projectBenefits,
        team: values.team,
      },
      product: {
        productLaunched: values.productLaunched,
        numberOfUsers: values.numberOfUsers,
        tvl: values.tvl,
        totalVolume: values.totalVolume,
        nftSales: values.nftSales,
      },
      community: {
        communitySize: values.communitySize,
        communityDemography: values.communityDemography,
        communitySupport: values.communitySupport,
      },
      business: {
        innovations: values.innovations,
        streamsRevenue: values.streamsRevenue,
        isTeamWorking: values.isTeamWorking,
        partners: values.partners,
        mainCompetitors: values.mainCompetitors,
        mainInnovations: values.mainInnovations,
        isProjectOpen: values.isProjectOpen,
        extendingVesting: values.extendingVesting,
        willingToLowerPrice: values.willingToLowerPrice,
      },
      antiPhishingCode: values.antiPhishingCode,
    };

    await api
      .fetchJson({
        url: `/emails/${EmailType.APPLY_VESTING}`,
        method: "POST",
        data: preparedSubmitData,
      })
      .then(() => {
        form.reset();
        handleNext(StepperActiveSteps.THIRD);
        return null;
      });
  };
};
