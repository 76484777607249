import React from "react";
import { Button, DialogContent, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { RefetchOptions, useMutation } from "@tanstack/react-query";

import { ApiError, useApi } from "@dzambalaorg/provider-api";

import { StyledDialog } from "../../../../../shared";
import { useOffersListStore } from "../store";
import { CloseButton } from "../../../../../components/common/close";
import { StyledDeleteOfferButtonsBox } from "../styled";

interface IDeleteOfferModalProps {
  refetch: (options?: RefetchOptions) => any;
  overviewRefetch: (options?: RefetchOptions) => any;
}

export const DeleteOfferModal = (props: IDeleteOfferModalProps) => {
  const { refetch, overviewRefetch } = props;

  const isDeleteDialogOpen = useOffersListStore(state => state.isDeleteDialogOpen);
  const offer = useOffersListStore(state => state.offer);
  const closeDeleteOfferDialog = useOffersListStore(state => state.closeDeleteOfferDialog);

  const api = useApi();

  const { mutate, isPending } = useMutation({
    mutationFn: (offerId: number) => {
      return api.fetchJson({ url: `/vesting/offers/${offerId}`, method: "DELETE" });
    },
    onSuccess: () => {
      closeDeleteOfferDialog();
      refetch();
      overviewRefetch();
    },
    onError: (e: ApiError) => {
      console.warn(e);
      return;
    },
  });

  if (!isDeleteDialogOpen || !offer) {
    return null;
  }

  return (
    <StyledDialog onClose={closeDeleteOfferDialog} open={isDeleteDialogOpen}>
      <DialogTitle>
        <FormattedMessage id={"dialogs.confirmation"} />
        <CloseButton onClick={closeDeleteOfferDialog} />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage
          tagName="p"
          id={"dialogs.removeOffer"}
          values={{ span: chunks => <span>{chunks}</span>, title: offer.box.template!.title }}
        />
        <StyledDeleteOfferButtonsBox>
          <Button
            className="DeleteOffer-AgreeBtn"
            disabled={isPending}
            data-agree={true}
            onClick={() => mutate(offer.id)}
          >
            <FormattedMessage tagName={"span"} id="dialogs.removeOfferAgreement" />
          </Button>
          <Button className="DeleteOffer-Cancel" disabled={isPending} onClick={closeDeleteOfferDialog}>
            <FormattedMessage tagName={"span"} id="form.buttons.cancel" />
          </Button>
        </StyledDeleteOfferButtonsBox>
      </DialogContent>
    </StyledDialog>
  );
};
