import { Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.custom.white,
  ...theme.typography.caption_2,
  padding: theme.spacing(1, 2),

  "&:hover": {
    background: theme.palette.custom.card_1.background,
  },
})) as typeof MenuItem;

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-elevation": {
    boxShadow: "none",
    backgroundColor: theme.palette.custom.card_2.background,
    borderRadius: theme.spacing(1.25),
  },

  "& .MuiList-root": {
    background: theme.palette.custom.card_2.background,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
})) as typeof Menu;
