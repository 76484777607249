import { Box, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& h2": {
    ...theme.typography.title_3,
    color: theme.palette.custom.white,
    textAlign: "center",
  },

  "& h4": {
    margin: 0,
    ...theme.typography.subtitle_3,
    color: theme.palette.custom.gray,
    textAlign: "center",
  },

  "& .MuiGrid-container": {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
}));
