import { object, string } from "yup";

import { urlValidationSchema } from "@dzambalaorg/yup-rules";

export const validationSchema = object().shape({
  projectName: string().required("form.validations.valueMissing"),
  projectLink: urlValidationSchema,
  fullTokenName: string().required("form.validations.valueMissing"),
  tokenTicker: string().required("form.validations.valueMissing"),
});
