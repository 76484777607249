import { Grid2, styled } from "@mui/material";

export const StyledTokenTotalCountBox = styled(Grid2)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),

  "& .MuiTypography-title_3": {
    ...theme.typography.title_3,
    color: theme.palette.custom.white,
  },
}));

export const StyledTokenAddressBox = styled(Grid2)(({ theme }) => ({
  "& .MuiIconButton-root": {
    padding: 0,
  },

  "& .MuiTypography-body_1": {
    ...theme.typography.body_1,
    color: theme.palette.custom.white,
    textAlign: "center",
  },
}));
