import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IAssetInfo } from "@framework/types";

import { AMOUNT_FIX_VALUE, LockedIcon, UnlockedIcon } from "../../../../../shared";
import {
  StyledAccordionBoxesList,
  StyledAccordionLockBox,
  StyledAccordionTitleBox,
  StyledAccordionWithdrawnBox,
  WithdrawalLinearProgress,
} from "../styled";
import { prepareAmountValue } from "../utils";

export const AccordionSummaryTitle = (props: { asset: IAssetInfo }) => {
  const { asset } = props;
  return (
    <StyledAccordionTitleBox>
      <Typography variant="button" component="h2">
        {asset.title}
      </Typography>
      <Box component="span">{`(${asset.symbol})`}</Box>
    </StyledAccordionTitleBox>
  );
};

export const AccordionContent = (props: { asset: IAssetInfo }) => {
  const { asset } = props;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const totalLockedTokensValue = prepareAmountValue(asset.amount);
  const totalUnlockedTokensValue = prepareAmountValue(asset.releasableAmount);
  const withdrawalPercentage = asset.amount ? (asset.withdrawnAmount * 100) / asset.amount : 100;

  return (
    <Grid2 size={12}>
      <Grid2 size={12}>
        <StyledAccordionWithdrawnBox>
          <FormattedMessage id="pages.tokens.assets.withdrawn.title" tagName="h4" />
          <Typography component="span">{`(${withdrawalPercentage.toFixed(AMOUNT_FIX_VALUE)}%)`}</Typography>
        </StyledAccordionWithdrawnBox>
        <WithdrawalLinearProgress value={withdrawalPercentage} variant="determinate" />
      </Grid2>

      <Grid2 container mt={3} size={12} spacing={!isSm ? 1 : 3}>
        <StyledAccordionLockBox size={{ lg: 6, md: 6, sm: 12 }}>
          <Box className="LockBox-header">
            <LockedIcon width={20} height={20} />
            <FormattedMessage id="pages.tokens.assets.locked.title" tagName="h4" />
          </Box>
          <FormattedMessage
            id="pages.tokens.assets.locked.tokensValueDescription"
            tagName="span"
            values={{ strong: chunks => <strong>{chunks}</strong>, value: totalLockedTokensValue }}
          />
        </StyledAccordionLockBox>
        <StyledAccordionLockBox size={{ lg: 6, md: 6, sm: 12 }}>
          <Box className="LockBox-header">
            <UnlockedIcon width={20} height={20} />
            <FormattedMessage id="pages.tokens.assets.unlocked.title" tagName="h4" />
          </Box>
          <FormattedMessage
            id="pages.tokens.assets.unlocked.tokensValueDescription"
            tagName="span"
            values={{ strong: chunks => <strong>{chunks}</strong>, value: totalUnlockedTokensValue }}
          />
        </StyledAccordionLockBox>
      </Grid2>

      <StyledAccordionBoxesList container mt={3} size={12} flexDirection="column">
        <FormattedMessage id="pages.tokens.assets.locked.tokensTitle" tagName="h5" />
        <Box className="BoxesList-boxes">
          {Object.entries(asset.boxes).map(([id, box]) => (
            <Box
              component={Link}
              to={`/asset/${id}/boxes`}
              className="boxTag"
              key={id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography component="p">{`${box.title} #${box.tokenId}`}</Typography>
              <Box className="lockInfo">
                <Box className="lockInfo-Item">
                  <LockedIcon color={"#676767"} width={16} height={16} />
                  <Typography component="span">{prepareAmountValue(box.lockedValue)}</Typography>
                </Box>
                <Box className="lockInfo-Item">
                  <UnlockedIcon color={"#676767"} width={16} height={16} />
                  <Typography component="span">{prepareAmountValue(box.unlockedValue)}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </StyledAccordionBoxesList>
    </Grid2>
  );
};
