import { Box, Container, ContainerProps, styled, SxProps, Theme } from "@mui/material";

import { MenuVariant } from "../../../shared";

interface IStyledContainer {
  $menuVariant: MenuVariant;
}

export const Root = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  position: "relative",
  background: theme.palette.custom.background.default,
  zIndex: 100,

  "&::after": {
    content: "''",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100vw",
    height: "60%",
    background: theme.palette.custom.effects.background_shine,
    zIndex: -1,
    filter: "blur(200px)",
  },
}));

const setStylesByMenuVariant = (theme: Theme): Record<MenuVariant, SxProps> => ({
  [MenuVariant.LANDING]: {
    "&.MuiContainer-root": {
      maxWidth: 2200,
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  [MenuVariant.INNER]: {
    maxWidth: "90%",
    padding: theme.spacing(0, 0, 10, 0),

    [theme.breakpoints.down("xl")]: {
      maxWidth: "90%",
      padding: theme.spacing(0, 0, 10, 0),
    },
  },
});

export const StyledContainer = styled(Container, {
  shouldForwardProp: prop => prop !== "menuVariant",
})<ContainerProps & IStyledContainer>(({ theme, $menuVariant }) => ({
  ...setStylesByMenuVariant(theme)[$menuVariant],
}));
