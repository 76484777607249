import { Slider, styled } from "@mui/material";

export const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.custom.main.default,
  height: 4,
  padding: "8px 0px",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.custom.white,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    background: "unset",
    padding: theme.spacing(0.5, 1),
    width: 24,
    height: 20,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.custom.main.default,
    ...theme.typography.caption_3,
  },
}));
