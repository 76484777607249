import { Box, BoxProps, styled } from "@mui/material";

import { Direction } from "./types";

interface IRoot {
  direction: Direction;
}

export const Root = styled(Box, { shouldForwardProp: prop => prop !== "direction" })<BoxProps & IRoot>(
  ({ theme, direction }) => ({
    width: "100%",
    display: "flex",
    flexDirection: direction,
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(2),

    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },

    "& h4": {
      margin: 0,
      paddingLeft: theme.spacing(2),
      color: "#000000",
      fontSize: theme.spacing(2.5),
      fontWeight: 500,
      lineHeight: "140%",
    },

    "& h5": {
      margin: 0,
      paddingLeft: theme.spacing(2),
      fontSize: theme.spacing(2.5),
      fontWeight: 500,
      lineHeight: "140%",
    },

    "& p": {
      margin: 0,
      color: theme.palette.custom.white,
      ...theme.typography.body_1,
    },

    "& .recharts-surface": {
      width: "50%",

      "& .recharts-cartesian-axis": {
        "& .recharts-text, .recharts-label": {
          ...theme.typography.caption_3,
          fill: theme.palette.custom.gray,
        },
      },
    },

    "& .recharts-layer": {
      fill: "linear-gradient(to right, #4B01E0, #8E2DE2)",
      "& .recharts-curve": {
        // stroke: "#57D5FD !important",
      },

      "& .recharts-active-dot": {
        "& path": {
          // stroke: "#71D7F8",
        },
      },
    },

    "& .recharts-brush": {
      "& rect": {
        stroke: "none",
      },

      "& :first-of-type": {
        rx: 10,
        ry: 10,
      },
      "& .recharts-brush-slide": {
        fillOpacity: 1,
        fill: theme.palette.custom.main.default,
      },
      "& .recharts-brush-traveller": {
        "& rect": {
          rx: 3,
          ry: 3,
          width: 20,
          height: 20,
          fill: theme.palette.custom.white,
        },
        "& line": {
          stroke: "none",
          fill: "none",
        },
      },
      "& .recharts-brush-texts": {
        "& .recharts-text": {
          fill: theme.palette.custom.white,
        },
      },
    },

    "& .recharts-wrapper": {
      " .recharts-tooltip-wrapper > .recharts-default-tooltip": {
        backgroundColor: `${theme.palette.custom.bodyText} !important`,
        padding: `${theme.spacing(1.5, 2.5)} !important`,
        borderRadius: `${theme.spacing(1.25)} !important`,
        border: "none !important",

        "& .recharts-tooltip-label": {
          ...theme.typography.caption_2,
          marginBottom: `${theme.spacing(1.5)} !important`,
        },

        "& .recharts-tooltip-item-list": {
          ...theme.typography.caption_1,
          color: theme.palette.custom.white,
        },
      },
    },
  }),
);

export const StyledPlotBox = styled(Box, { shouldForwardProp: prop => prop !== "direction" })<BoxProps & IRoot>(
  ({ theme, direction }) => ({
    width: direction === "column" ? "100%" : "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(2),

    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  }),
);
