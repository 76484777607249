import React, { ElementType, FC, ReactNode } from "react";
import { Grid2, Box, useTheme, useMediaQuery } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { StyledBoxInfoItem } from "./styled";
import { BoxIcon, PurchasedIcon, WalletIcon, CalendarIcon } from "../../../assets";
import { AMOUNT_FIX_VALUE } from "../../../constants";
import { StyledAvatar } from "../styled";

interface IBoxInfoProps {
  duration: string;
  boxPrice: string;
  limit: string;
  purchased: string;
}

interface IBoxFooterInfoProps {
  tvl: number;
  releasable: string | number;
  tokenImg: string;
}

interface IBoxInfoItemProps {
  id: string;
  tagName: ElementType<any, keyof React.JSX.IntrinsicElements>;
  value: string | number;
  className: string;
  icon?: ReactNode;
}

const BoxInfoItem = (props: IBoxInfoItemProps) => (
  <Grid2>
    <FormattedMessage tagName={props.tagName} id={props.id} />
    <Box className={`BoxInfo-${props.className}`}>
      {props.icon}
      {props.value}
    </Box>
  </Grid2>
);

export const BoxInfo: FC<IBoxInfoProps> = props => {
  const { limit, purchased, boxPrice, duration } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(780));

  return (
    <Grid2 container spacing={!isMd ? 2 : 0} size={12} justifyContent="space-between">
      <StyledBoxInfoItem container size={!isMd ? 6 : 5}>
        <BoxInfoItem className="infoItem" id="pages.vesting.boxes.limit" tagName="p" value={limit} icon={<BoxIcon />} />
        <BoxInfoItem
          className="infoItem"
          id="pages.vesting.boxes.boxPrice"
          tagName="p"
          value={boxPrice}
          icon={<WalletIcon />}
        />
      </StyledBoxInfoItem>
      <StyledBoxInfoItem container size={!isMd ? 6 : 5}>
        <BoxInfoItem
          className="infoItem"
          id="pages.vesting.boxes.purchased"
          tagName="p"
          value={purchased}
          icon={<PurchasedIcon />}
        />
        <BoxInfoItem
          className="infoItem"
          id="pages.vesting.boxes.duration"
          tagName="p"
          value={duration}
          icon={<CalendarIcon />}
        />
      </StyledBoxInfoItem>
    </Grid2>
  );
};

export const BoxFooterInfo: FC<IBoxFooterInfoProps> = props => {
  const { tvl, releasable, tokenImg } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(780));

  return (
    <Grid2 container spacing={!isMd ? 2 : 0} size={12} justifyContent="space-between">
      <StyledBoxInfoItem size={!isMd ? 6 : 5}>
        <BoxInfoItem
          className="infoFooterItem"
          id="pages.vesting.boxes.tvl"
          tagName="h6"
          value={tvl.toFixed(AMOUNT_FIX_VALUE)}
          icon={
            <StyledAvatar component="img" className="BoxCard-avatar" src={tokenImg} alt={"#"} width={24} height={24} />
          }
        />
      </StyledBoxInfoItem>
      <StyledBoxInfoItem size={!isMd ? 6 : 5}>
        <BoxInfoItem
          className="infoFooterItem"
          id="pages.vesting.boxes.tgeUnlock"
          tagName="h6"
          value={`${releasable}%`}
        />
      </StyledBoxInfoItem>
    </Grid2>
  );
};
