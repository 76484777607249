import { Box, styled } from "@mui/material";

export const StyledListRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  position: "relative",

  "& h2": {
    ...theme.typography.title_3,
    color: theme.palette.custom.white,

    [theme.breakpoints.down("sm")]: {
      ...theme.typography.subtitle_2,
    },
  },

  "& .MuiPaginationItem-root": {
    background: "rgba(0, 0, 0, 0.25)",
    color: "rgba(0, 0, 0, 0.87)",
  },

  "& .BoxesFilters-grid": {
    "& input": {
      "&:focus-visible": {
        outline: "0px",
        boxShadow: "none",
      },
    },

    "& span": {
      ...theme.typography.caption_1,
      color: theme.palette.custom.white,
    },
  },
}));
