import React, { SVGProps } from "react";
import { IntlFormatters, useIntl } from "react-intl";

import { AssetsDashboardTabs, PATHS, VestingBoxesTabs } from "../../../shared";

export interface IMenuItem {
  id: number;
  title: string;
  paths?: Array<string>;
  link?: string;
  children?: Array<IMenuItem>;
  external?: boolean;
  Svg?: (props: SVGProps<SVGSVGElement>) => React.JSX.Element;
}

const setDesktopMenuItems = (formatMessage: IntlFormatters["formatMessage"]): Array<IMenuItem> => [
  {
    id: 1,
    link: "https://docs.google.com/document/d/13yqCHpj8cEQPSixcCyfb2hXQTtz79Npyd3S60WOS7Kc/edit?tab=t.0",
    external: true,
    title: formatMessage({ id: "components.header.menu.inner.roadmap" }),
  },
  {
    id: 2,
    link: `${PATHS.Main}#aboutUs`,
    title: formatMessage({ id: "components.header.menu.inner.aboutUs" }),
  },
  {
    id: 3,
    link: `${PATHS.Main}#faq`,
    title: formatMessage({ id: "components.header.menu.inner.faq" }),
  },
  {
    id: 4,
    link: `${PATHS.VestingBoxesBase}/${VestingBoxesTabs.boxes}`,
    title: formatMessage({ id: "components.header.menu.inner.trading" }),
    paths: [
      `${PATHS.VestingBoxesBase}/${VestingBoxesTabs.boxes}`,
      `${PATHS.VestingBoxesBase}/${VestingBoxesTabs.offers}`,
    ],
  },
];

const setMobileMenuItems = (formatMessage: IntlFormatters["formatMessage"]): Array<IMenuItem> => [
  {
    id: 5,
    link: `${PATHS.DashboardBase}/${AssetsDashboardTabs.boxes}`,
    title: formatMessage({ id: "pages.tokens.title" }),
  },
];

export const useMenuItems = (): Record<string, Array<IMenuItem>> => {
  const { formatMessage } = useIntl();

  return { desktop: setDesktopMenuItems(formatMessage), mobile: setMobileMenuItems(formatMessage) };
};
