import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";

import { IVestingBox } from "@framework/types";

import { Plot, StyledBlockRoot } from "../../../../../../shared";

interface IPlotBlockProps {
  vestingBox: IVestingBox;
}

export const PlotBlock: FC<IPlotBlockProps> = props => {
  const { vestingBox } = props;
  return (
    <StyledBlockRoot>
      <Box className="BlockRoot-headerBox">
        <FormattedMessage id={`enums.shape.${vestingBox.shape}`} tagName="h4" values={{ title: vestingBox.title }} />
      </Box>
      <Plot
        vestingBox={vestingBox}
        price={vestingBox.template!.price!}
        startTimestamp={new Date()}
        startDirection="column"
      />
    </StyledBlockRoot>
  );
};
