import { Navigate } from "react-router";

import { StyledCurrentRoot, CurrentBoxSkeleton } from "../../../../../shared";
import { useFetchBox } from "./useFetchBox";
import { CurrentBox } from "./box";

export const CurrentVestingBox = () => {
  const { data: selected, isLoading, isError } = useFetchBox();

  if (isLoading) {
    return (
      <StyledCurrentRoot>
        <CurrentBoxSkeleton />
      </StyledCurrentRoot>
    );
  }

  if (!selected) {
    return isError ? <Navigate to="/error/message" /> : null;
  }

  return <CurrentBox selected={selected} />;
};
