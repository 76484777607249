import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { BrowserProvider } from "ethers";

import { useApiCall } from "@dzambalaorg/react-hooks";
import { IAssetInfo } from "@framework/types";

import { getReleasableAmounts } from "../../../../shared";

export const useFetchAssetsInfo = () => {
  const [assetsInfo, setAssetsInfo] = useState<Array<IAssetInfo>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const web3Context = useAccount();

  const { fn: fetchByQueryFn } = useApiCall<Array<IAssetInfo>>(
    api => {
      return api.fetchJson({
        url: "/vesting/boxes/assetsInfo",
      });
    },
    { success: false, error: false },
  );

  useEffect(() => {
    const fetchAssetsInfo = async () => {
      const response = (await fetchByQueryFn()) as Array<IAssetInfo>;
      const responseMap = new Map();
      const rawProvider = await web3Context.connector!.getProvider();
      const provider = new BrowserProvider(rawProvider as any);
      const signer = await provider.getSigner();

      for (const asset of response) {
        responseMap.set(asset.symbol, asset);

        const currentAsset = responseMap.get(asset.symbol);
        for (const token of asset.tokens) {
          const { tokenId, tokenBaseId, contract } = token;

          const releasableAmountValue = await getReleasableAmounts(tokenId, contract, signer);
          const formattedReleasableAmountValue = Number(releasableAmountValue);

          currentAsset.releasableAmount = currentAsset.releasableAmount + formattedReleasableAmountValue;
          currentAsset.amount = currentAsset.amount - formattedReleasableAmountValue;
          currentAsset.boxes[tokenBaseId].lockedValue =
            currentAsset.boxes[tokenBaseId].lockedValue - formattedReleasableAmountValue;

          currentAsset.boxes[tokenBaseId].unlockedValue = formattedReleasableAmountValue;
        }
      }
      setAssetsInfo(Array.from(responseMap.values()));
    };

    void fetchAssetsInfo().finally(() => setIsLoading(false));
  }, []);

  return { assetsInfo, isLoading };
};
