import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid2 } from "@mui/material";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { FormWrapper } from "@dzambalaorg/mui-form";
import { TextArea } from "@dzambalaorg/mui-inputs-core";
import { TextInput } from "@framework/components";

import { InputBox, RadioIcon, WithRequiredTitle } from "../components";
import { Root, StyledFieldBox } from "./styled";
import { questionOptions } from "./constants";
import { validationSchema } from "./validation";
import { useSecondStepForm } from "./useSecondStep";
import { RadioGroupInput } from "../../../../../../components/inputs";
import { RequiredStarIcon } from "../../../../../../shared";
import { useApplyVestingStore } from "../store";
import { ISecondStepForm } from "../types";

export const SecondStepForm = () => {
  const handleConfirm = useSecondStepForm();

  const secondStep = useApplyVestingStore(state => state.secondStep);
  const setSecondStep = useApplyVestingStore(state => state.setSecondStep);

  const onFormStateChange = async (form: UseFormReturn<FieldValues>) => {
    const values = form.getValues() as ISecondStepForm;
    setSecondStep(values);
    return Promise.resolve();
  };

  return (
    <Root>
      <FormWrapper
        sx={{ minWidth: "320px", display: "flex", flexDirection: "column", gap: 3 }}
        initialValues={secondStep}
        onSubmit={handleConfirm}
        onFormStateChange={onFormStateChange}
        validationSchema={validationSchema}
        submit={"applyVesting"}
      >
        {/* Contact details */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.contactDetailsTitle" />
          <Grid2 container size={12} spacing={2}>
            <InputBox
              size={{ lg: 6, md: 12 }}
              required
              label={<FormattedMessage id="pages.vesting.application.contactDetailsSubtitle_1" tagName="p" />}
            >
              <TextInput multiline showLabel={false} name="contactDetails.fullName" required variant="outlined" />
            </InputBox>
            <InputBox
              size={{ lg: 6, md: 12 }}
              required
              label={<FormattedMessage id="pages.vesting.application.contactDetailsSubtitle_2" tagName="p" />}
            >
              <TextInput multiline showLabel={false} name="contactDetails.projectRole" required variant="outlined" />
            </InputBox>
            <InputBox
              size={{ lg: 6, md: 12 }}
              required
              label={<FormattedMessage id="pages.vesting.application.contactDetailsSubtitle_3" tagName="p" />}
            >
              <TextInput
                multiline
                showLabel={false}
                name="contactDetails.projectEmailAddress"
                required
                variant="outlined"
              />
            </InputBox>
            <InputBox
              size={{ lg: 6, md: 12 }}
              required
              label={<FormattedMessage id="pages.vesting.application.contactDetailsSubtitle_4" tagName="p" />}
            >
              <TextInput multiline showLabel={false} name="contactDetails.telegramId" required variant="outlined" />
            </InputBox>
          </Grid2>
        </StyledFieldBox>

        {/* Referral */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.whoRefereedTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.whoRefereedSubtitle" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="whoRefereed" required variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Key Question A */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.keyQuestionATitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.keyQuestionASubtitle" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="keyQuestionA" required variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Key Question B */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.keyQuestionBTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.keyQuestionBSubtitle" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="keyQuestionB" required variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Key Question C */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.keyQuestionCTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.keyQuestionCSubtitle" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="keyQuestionC" required variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Token symbol */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.tokenSymbolTitle" />
          <InputBox width="100%" label={null}>
            <TextInput multiline showLabel={false} name="tokenSymbol" required variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Token circulation */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.tokenCirculationTitle" />
          <RadioGroupInput name="tokenCirculation" radii={questionOptions} control={RadioIcon} />
        </StyledFieldBox>

        {/* TGE launch */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.tgeDeadlinesTitle" />
          <TextInput multiline showLabel={false} name="tgeDeadlines" required variant="outlined" />
        </StyledFieldBox>

        {/* Project focus on */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.projectFocusOnTitle" />
          <TextInput multiline showLabel={false} name="projectFocusOn" required variant="outlined" />
        </StyledFieldBox>

        {/* High potential */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.highPotentialTitle" />
          <TextInput multiline showLabel={false} name="highPotential" variant="outlined" />
        </StyledFieldBox>

        {/* Pain points */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.painPointsTitle" />
          <TextInput multiline showLabel={false} name="painPoints" required variant="outlined" />
        </StyledFieldBox>

        {/* Pain solutions */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.painSolutionsTitle" />
          <TextInput multiline showLabel={false} name="painSolutions" required variant="outlined" />
        </StyledFieldBox>

        {/* Target audience */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.productAudienceTitle" />
          <TextInput multiline showLabel={false} name="productAudience" required variant="outlined" />
        </StyledFieldBox>

        {/* Project Benefits */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.projectBenefitsTitle" />
          <TextInput multiline showLabel={false} name="projectBenefits" required variant="outlined" />
        </StyledFieldBox>

        {/* Team */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.teamTitle" />
          <Grid2 container size={12} spacing={2}>
            <InputBox
              size={{ lg: 12, md: 12 }}
              required
              label={<FormattedMessage id="pages.vesting.application.teamSubtitle_a" tagName="p" />}
            >
              <TextInput multiline showLabel={false} name="team.size" required variant="outlined" />
            </InputBox>
            <InputBox
              size={{ lg: 12, md: 12 }}
              label={
                <FormattedMessage
                  id="pages.vesting.application.teamSubtitle_b"
                  values={{
                    span: chunks => (
                      <span>
                        {chunks}
                        <RequiredStarIcon width={6} height={6} />
                      </span>
                    ),
                  }}
                />
              }
            >
              <TextArea multiline showLabel={false} name="team.information" required variant="outlined" />
            </InputBox>
            <InputBox
              size={{ lg: 12, md: 12 }}
              label={<FormattedMessage id="pages.vesting.application.teamSubtitle_c" tagName="p" />}
            >
              <TextInput multiline showLabel={false} name="team.experience" required variant="outlined" />
            </InputBox>
          </Grid2>
        </StyledFieldBox>

        {/* Tokenomics */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.tokenUtilityTitle" />
          <TextInput multiline showLabel={false} name="tokenUtility" required variant="outlined" />
        </StyledFieldBox>

        {/* Token Distribution */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.tokenDistributionTitle" />
          <TextInput multiline showLabel={false} name="tokenDistribution" required variant="outlined" />
        </StyledFieldBox>

        {/* Project launched */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.productLaunchedTitle" />
          <TextInput multiline showLabel={false} name="productLaunched" required variant="outlined" />
        </StyledFieldBox>

        {/* Number of users */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.numberOfUsersTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="numberOfUsers" required variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* TVL */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.tvlTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="tvl" variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Total volume */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.totalVolumeTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="totalVolume" variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* NFT Sales */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.nftSalesTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="nftSales" variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Community size */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.communitySizeTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="communitySize" required variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Community demography */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.communityDemographyTitle" />
          <InputBox
            width="100%"
            label={<FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />}
          >
            <TextInput multiline showLabel={false} name="communityDemography" required variant="outlined" />
          </InputBox>
        </StyledFieldBox>

        {/* Community support */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.communitySupportTitle" />
          <TextInput multiline showLabel={false} name="communitySupport" required variant="outlined" />
        </StyledFieldBox>

        {/* Innovations */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.innovationsTitle" />
          <TextInput multiline showLabel={false} name="innovations" required variant="outlined" />
        </StyledFieldBox>

        {/* Streams Revenue */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.streamsRevenueTitle" />
          <TextInput multiline showLabel={false} name="streamsRevenue" required variant="outlined" />
        </StyledFieldBox>

        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.isTeamWorkingTitle" />
          <RadioGroupInput name="isTeamWorking" radii={questionOptions} control={RadioIcon} />
        </StyledFieldBox>

        {/* Partners */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.partnersTitle" />
          <TextInput multiline showLabel={false} name="partners" required variant="outlined" />
        </StyledFieldBox>

        {/* Main Competitors */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.mainCompetitorsTitle" />
          <TextInput multiline showLabel={false} name="mainCompetitors" required variant="outlined" />
        </StyledFieldBox>

        {/* Main Innovations */}
        <StyledFieldBox>
          <WithRequiredTitle required={false} titleId="pages.vesting.application.mainInnovationsTitle" />
          <TextInput multiline showLabel={false} name="mainInnovations" required variant="outlined" />
        </StyledFieldBox>

        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.isProjectOpenTitle" />
          <RadioGroupInput name="isProjectOpen" radii={questionOptions} control={RadioIcon} />
        </StyledFieldBox>

        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.extendingVestingTitle" />
          <RadioGroupInput name="extendingVesting" radii={questionOptions} control={RadioIcon} />
        </StyledFieldBox>

        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.willingToLowerPriceTitle" />
          <RadioGroupInput name="willingToLowerPrice" radii={questionOptions} control={RadioIcon} />
        </StyledFieldBox>

        {/*<StyledFieldBox>*/}
        {/*  <WithRequiredTitle titleId="pages.vesting.application.legalTokenDocsTitle" />*/}
        {/*  <FileInput*/}
        {/*    cloudUploadIcon={*/}
        {/*      <StyledAvatar sx={{ cursor: "pointer" }} src="/files/new/cloud_upload.png" width={96} height={60} />*/}
        {/*    }*/}
        {/*    name="legalTokenDocs"*/}
        {/*  />*/}
        {/*</StyledFieldBox>*/}

        {/*<StyledFieldBox>*/}
        {/*  <WithRequiredTitle titleId="pages.vesting.application.constitutionalDocsTitle" />*/}
        {/*  <FileInput*/}
        {/*    cloudUploadIcon={*/}
        {/*      <StyledAvatar sx={{ cursor: "pointer" }} src="/files/new/cloud_upload.png" width={96} height={60} />*/}
        {/*    }*/}
        {/*    name="constitutionalDocs"*/}
        {/*  />*/}
        {/*</StyledFieldBox>*/}

        {/* AntiPhishing Code */}
        <StyledFieldBox>
          <WithRequiredTitle titleId="pages.vesting.application.antiPhishingCodeTitle" />
          <TextInput multiline showLabel={false} name="antiPhishingCode" required variant="outlined" />
        </StyledFieldBox>

        {/*<StyledFieldBox>*/}
        {/*  <WithRequiredTitle required={false} titleId="pages.vesting.application.confirmationDocsTitle" />*/}
        {/*  <FileInput*/}
        {/*    cloudUploadIcon={*/}
        {/*      <StyledAvatar sx={{ cursor: "pointer" }} src="/files/new/cloud_upload.png" width={96} height={60} />*/}
        {/*    }*/}
        {/*    name="confirmationDocs"*/}
        {/*  />*/}
        {/*</StyledFieldBox>*/}
      </FormWrapper>
    </Root>
  );
};
