import React, { SVGProps } from "react";

export const LockedIcon = (props: SVGProps<any>) => {
  return (
    <svg
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.33311V6.66644C5 3.90811 5.83333 1.66644 10 1.66644C14.1667 1.66644 15 3.90811 15 6.66644V8.33311"
        stroke={props.color || "white"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z"
        stroke={props.color || "white"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.166 18.3336H5.83268C2.49935 18.3336 1.66602 17.5002 1.66602 14.1669V12.5002C1.66602 9.16689 2.49935 8.33356 5.83268 8.33356H14.166C17.4993 8.33356 18.3327 9.16689 18.3327 12.5002V14.1669C18.3327 17.5002 17.4993 18.3336 14.166 18.3336Z"
        stroke={props.color || "white"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
