import React, { ChangeEvent } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router";

import { StyledTab, StyledTabs } from "./styled";

export type TabMenuProps<T extends Record<string, string>> = {
  tabs: T;
  basicLocale: string;
  basicPath: string;
  saveSearch?: boolean;
};

export const TabMenu = <T extends Record<string, string>>(props: TabMenuProps<T>) => {
  const { tabs, basicLocale, saveSearch = false } = props;

  const navigate = useNavigate();
  const params = useParams<{ tab: T[keyof T] }>();
  const location = useLocation();

  const tab = params.tab || Object.values(tabs)[0];

  const { formatMessage } = useIntl();

  const handleChange = (_event: ChangeEvent<any>, newValue: T[keyof T]) => {
    if (newValue === tab) {
      return;
    }
    const search = saveSearch ? location.search : "";
    navigate(`${props.basicPath}/${newValue}${search}`, { replace: true });
  };

  return (
    <StyledTabs variant="scrollable" value={tab} indicatorColor="primary" textColor="primary" onChange={handleChange}>
      {Object.values(tabs).map(tabItem => (
        <StyledTab
          disableRipple
          iconPosition="start"
          key={tabItem}
          label={<span>{formatMessage({ id: `${basicLocale}.${tabItem}` })}</span>}
          value={tabItem}
        />
      ))}
    </StyledTabs>
  );
};
