import React from "react";
import { FormattedMessage } from "react-intl";

import { Root } from "./styled";

export const ThirdStep = () => {
  return (
    <Root>
      <img src={"/files/new/success.png"} alt={"#"} width={200} height={200} />
      <FormattedMessage id="pages.vesting.application.successfullySubmitted" />
    </Root>
  );
};
