import React, { FC, useMemo } from "react";

import { ListAction, ListActionVariant } from "@framework/styled";

import {
  formatDateFromSpecialValue,
  IVestingOfferResponse,
  StyledTableRow,
  TOKEN_PERCENTAGE_FIX_VALUE,
  TrashIcon,
  useVestingContractInfo,
  Vesting,
} from "../../../../../shared";
import { offersTableHeaderSchema } from "../schemas";

interface IOfferAssetProps {
  offer: IVestingOfferResponse;
  handleDelete: (item: IVestingOfferResponse) => () => void;
  isMd?: boolean;
}

export const OfferAsset: FC<IOfferAssetProps> = props => {
  const { offer, handleDelete, isMd } = props;
  const { box, tokenId, createdAt } = offer;

  const { releasable, withdrawn } = useVestingContractInfo(tokenId || "", box.template!.contract!.address);

  const vesting = useMemo(() => {
    return new Vesting(box, box.template!.price!, releasable, withdrawn);
  }, [releasable, withdrawn]);

  const displayingBoxPrice = vesting.getBoxPriceWithTicker();
  const displayingTokensCount = vesting.getTokensCountWithTicker(withdrawn);
  const displayingReleasable = releasable.toFixed(TOKEN_PERCENTAGE_FIX_VALUE);
  const displayingReleasablePercentage = vesting.getReleasablePercentageDisplaying();
  const displayingDate = formatDateFromSpecialValue(createdAt, true);

  const displayingData = [
    box.template!.title,
    displayingBoxPrice,
    displayingTokensCount,
    `${displayingReleasable} (${displayingReleasablePercentage}%)`,
    displayingDate,
    <ListAction
      key={"close_offer"}
      onClick={handleDelete(offer)}
      message="pages.tokens.closeOffer"
      className={"CloseOffer-btn"}
      dataTestId="CloseOfferButton"
      icon={TrashIcon}
      variant={!isMd ? ListActionVariant.iconButton : ListActionVariant.button}
    />,
  ];

  return <StyledTableRow hasLastColumnIcons data={displayingData} headerData={offersTableHeaderSchema} isMd={isMd} />;
};
