import { Box, BoxProps, Grid2, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  gap: theme.spacing(5),
  marginTop: theme.spacing(12.5),
  position: "relative",

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },

  "&::before": {
    content: "''",
    height: "500px",
    width: "350vw",
    position: "absolute",
    top: "10%",
    left: "50%",
    transform: "translateX(-50%) translateY(10%) translateZ(0)",
    background: "#0b1e42",
    filter: "blur(150px)",
  },
}));

export const StyledCardBox = styled(Grid2)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "80%",
  maxWidth: "1040px",
}));

interface IStyledCard {
  $index: number;
  $hasBorder?: boolean;
}

export const StyledCard = styled(Grid2, {
  shouldForwardProp: prop => prop !== "$index" && prop !== "$hasBorder",
})<BoxProps & IStyledCard>(({ theme, $index, $hasBorder }) => ({
  minHeight: "210px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(3),
  backgroundColor: "rgba(137, 137, 137, 0.1)",
  boxShadow: $hasBorder ? "0px 16px 30px 0px rgba(66, 102, 193, 0.6)" : "none",
  backdropFilter: "blur(150px)",
  position: "relative",
  zIndex: 2,

  borderRadius: theme.spacing(3.75),
  borderStyle: "solid",
  borderWidth: $hasBorder ? "2px 0px 2px 0px" : "1px",
  borderColor: $hasBorder ? "rgba(68, 103, 255, 1)" : "rgba(255, 255, 255, 0.15)",

  textDecoration: "none",
  padding: theme.spacing(4, 3, 4, 4),

  "& .MainPageCards-CardHeader": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),

    "& .CardHeader-icon-bg": {
      position: "relative",
      width: "50px",
      height: "50px",
      borderRadius: theme.spacing(1.9),
      backgroundColor: "#3F65FF",

      "&::after": {
        content: '""',
        display: "block",
        width: "50px",
        height: "50px",
      },

      "& .CardHeader-icon": {
        position: "absolute",
        top: "15px",
        left: "50%",
        transform: `translate(-50%, -50%)`,
      },
    },

    "& h4": {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 800,
      lineHeight: "130%",
      fontSize: theme.spacing(2.5),
      margin: 0,
      color: "#FFFFFF",
    },
  },

  "& p": {
    margin: 0,
    fontFamily: "Inter, sans-serif",
    fontWeight: 400,
    lineHeight: "130%",
    fontSize: theme.spacing(2),
    color: "#898CA9",
  },

  opacity: 0,
  transform: "translateY(20px)",
  animation: `fadeInUp 0.5s ${0.1 * $index + 1}s forwards`,
  "@keyframes fadeInUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
}));
