import { FC } from "react";
import { BrowserProvider, Contract } from "ethers";

import { useMetamask, Web3ContextType } from "@dzambalaorg/react-hooks-eth";
import { ListAction, ListActionVariant } from "@framework/styled";
import type { IToken } from "@framework/types";

import VestingReleaseABI from "@framework/abis/json/ERC721Vesting/release.json";

export interface IVestingBoxReleaseButtonProps {
  token: IToken;
  className?: string;
  disabled?: boolean;
  variant?: ListActionVariant;
}

export const VestingBoxReleaseButton: FC<IVestingBoxReleaseButtonProps> = props => {
  const { token, className, disabled, variant } = props;

  const metaRelease = useMetamask(async (web3Context: Web3ContextType) => {
    const rawProvider = await web3Context.connector!.getProvider();
    const provider = new BrowserProvider(rawProvider as any);
    const signer = await provider.getSigner();
    const { address } = token.template?.contract || {};

    if (!address) {
      throw new Error("unsupported token type");
    }

    const contract = new Contract(address, VestingReleaseABI, signer);
    return contract.release(token.tokenId) as Promise<void>;
  });

  const handleClick = () => {
    return metaRelease();
  };

  return (
    <ListAction
      onClick={handleClick}
      message="form.tips.release"
      className={className}
      dataTestId="VestingReleaseButton"
      disabled={disabled || !token}
      variant={variant}
    />
  );
};
