import React, { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { Box } from "@mui/material";

import { CloseSvg } from "../../assets/svg/close/Close";

interface IClearIconProps {
  form: UseFormReturn<any>;
  name: string;
}

export const ClearIcon: FC<IClearIconProps> = props => {
  const { form, name } = props;

  return (
    <Box
      className="DateInput-CloseIcon"
      onClick={e => {
        e.stopPropagation();
        form.setValue(name, null);
      }}
    >
      <CloseSvg />
    </Box>
  );
};
