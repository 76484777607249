import { CardMedia, CardMediaProps, styled } from "@mui/material";

import { sizeDecreaseCalc } from "../../utils";

export interface IStyledCardMediaProps extends CardMediaProps {
  height?: number;
}

export const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: prop => prop !== "height",
})<IStyledCardMediaProps>(({ theme }) => ({
  height: sizeDecreaseCalc(333, 200),
  objectFit: "cover",
  borderRadius: theme.spacing(1.25),
}));
