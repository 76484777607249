import { FC } from "react";

import { StyledIconButton } from "./styled";
import { CloseIcon } from "../../../shared";

export interface ICloseButtonProps {
  onClick: () => void;
  color?: string;
}

export const CloseButton: FC<ICloseButtonProps> = props => {
  const { onClick, color } = props;

  return (
    <StyledIconButton aria-label="close" onClick={onClick}>
      <CloseIcon color={color} />
    </StyledIconButton>
  );
};
